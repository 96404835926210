<template>
  <div>
    <div class="row table-tabs no-gutters">
      <div class="col">
        <tws-map-table-tab
          :text="$t('map.service-mode')"
          class="mr-3"
          :active="showServiceStateField"
          @click="showServiceStateField = true" />
        <tws-map-table-tab
          :text="$t('map.customer-reports')"
          :active="!showServiceStateField"
          @click="showServiceStateField = false" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>{{ showServiceStateField ? $t('map.telia-service-mode') : $t('map.customer-reported-errors') }}</b>

        <table class="map-table map-table--mobile">
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index">
              <td class="county">
                <span
                  class="clickable"
                  @click="$emit('select', getCountyCodeByName(item.county))">
                  {{ item.county }}
                </span>
              </td>
              <td
                v-show="showServiceStateField"
                class="sla"
                :class="[ getStatusCodeByName(item.slaText) === 0 ? 'sla-normal' : 'sla-higher' ]">
                <tws-map-status-icon
                  color-palette="orange"
                  :status="getStatusCodeByName(item.slaText)" />
                {{ item.slaText }}
              </td>
              <td
                v-show="!showServiceStateField"
                class="sla">
                {{ item.faults }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MapStatusIcon from '@/components/map/map-status-icon'
import MapTableTab from '@/components/map/map-table-tab'
import { MapServiceStateCountiesTableMixin } from '@/mixins/map-service-state.mixin'

export default {
  components: {
    'tws-map-status-icon': MapStatusIcon,
    'tws-map-table-tab': MapTableTab
  },
  mixins: [MapServiceStateCountiesTableMixin],
  data () {
    return {
      showServiceStateField: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table';

.table-tabs {
  margin: 25px 0;
}

.map-table--mobile {
  /** adjust the width of last column to a minimum, but fit content **/
  td:not(:first-of-type) {
    width: 1%;
    white-space: nowrap;
  }

  .sla-normal {
    color: $dark-grey;
  }

  .sla-higher {
    font-weight: bold;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    color: $core-purple;
  }
}

</style>
