import UserService from '@/services/user.service.js'
import { ACCOUNT_STATUS } from '@/constants/account-status.js'

export const SortOptions = {
  USERNAME_ASC: 'username-asc',
  USERNAME_DESC: 'username-desc',
  LAST_NAME_ASC: 'last-name-asc',
  LAST_NAME_DESC: 'last-name-desc',
  VALID_UNTIL_DESC: 'valid-until-desc'
}

const filterUsersByStatus = (users, filters) => {
  if (filters.length === 0) {
    return users
  }

  return users.filter(user => filters.includes(user.accountStatus))
}

const filterUsersBySearch = (users, search) => {
  if (!search) {
    return users
  }

  return users.filter(user => {
    const username = user.username ? user.username.toLowerCase() : ''
    const firstName = user.firstName ? user.firstName.toLowerCase() : ''
    const lastName = user.lastName ? user.lastName.toLowerCase() : ''
    const companyName = user.company ? user.company.name.toLowerCase() : ''
    const email = user.email ? user.email.toLowerCase() : ''
    const phone = user.phone ? user.phone.toLowerCase() : ''
    const mobilePhone = user.mobilePhone ? user.mobilePhone.toLowerCase() : ''

    const containsUsername = username.includes(search)
    const containsFullName = (firstName + ' ' + lastName).includes(search)
    const containsCompany = companyName.includes(search)
    const containsEmail = email.includes(search)
    const containsPhone = phone.includes(search)
    const containsMobilePhone = mobilePhone.includes(search)

    return containsUsername || containsFullName || containsCompany || containsEmail || containsPhone || containsMobilePhone
  })
}

const filterUsersByCompany = (users, code) => {
  if (!code) {
    return users
  }

  return users.filter(user => {
    return user.company ? user.company.code === code : false
  })
}

const filterUsersByAdditionalFilters = (users, filters) => {
  if (filters.length === 0) {
    return users
  }

  return users.filter(user => {
    if (!user.roles) {
      return false
    }

    const activeRoles = Object.keys(user.roles).filter(role => user.roles[role] === true)

    for (let i = 0; i < filters.length; i++) {
      if (!activeRoles.includes(filters[i])) {
        return false
      }
    }

    return true
  })
}

export const UserAdministrationModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    exception: false,
    users: [],
    filteredUsers: [],

    searchFilter: '',
    statusFilters: [],
    additionalFilters: [],
    companyCodeFilter: '',

    sortBy: SortOptions.LAST_NAME_ASC,
    ascendingSort: true,

    currentPage: 1
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setUsers (state, users) {
      state.users = users
    },
    setFilteredUsers (state, users) {
      state.filteredUsers = users
    },
    setSearchFilter (state, filter) {
      state.searchFilter = filter
    },
    setStatusFilters (state, filters) {
      state.statusFilters = filters
    },
    setAdditionalFilters (state, filters) {
      state.additionalFilters = filters
    },
    setSortBy (state, sortBy) {
      state.sortBy = sortBy
    },
    setAscendingSort (state, ascending) {
      state.ascendingSort = ascending
    },
    setCurrentPage (state, page) {
      state.currentPage = page
    },
    setCompanyCodeFilter (state, filter) {
      state.companyCodeFilter = filter
    },
    updateFilteredUsers (state) {
      let users = filterUsersByCompany(state.users, state.companyCodeFilter)
      users = filterUsersByStatus(users, state.statusFilters)
      users = filterUsersBySearch(users, state.searchFilter.toLowerCase())
      users = filterUsersByAdditionalFilters(users, state.additionalFilters)

      state.filteredUsers = users.slice(0)
    },
    sortUsers (state) {
      state.users = state.users.sort((a, b) => {
        if (state.sortBy === SortOptions.USERNAME_ASC) {
          if (a.username && !b.username) {
            return 1
          } else if (!a.username && b.username) {
            return -1
          } else if (!a.username && !b.username) {
            return 0
          }
          return a.username.localeCompare(b.username)

        } else if (state.sortBy === SortOptions.LAST_NAME_ASC) {
          if (a.lastName && !b.lastName) {
            return 1
          } else if (!a.lastName && b.lastName) {
            return -1
          } else if (!a.lastName && !b.lastName) {
            return 0
          }
          return a.lastName.localeCompare(b.lastName)

        } else if (state.sortBy === SortOptions.USERNAME_DESC) {
          if (a.username && !b.username) {
            return -1
          } else if (!a.username && b.username) {
            return 1
          } else if (!a.username && !b.username) {
            return 0
          }
          return b.username.localeCompare(a.username)

        } else if (state.sortBy === SortOptions.LAST_NAME_DESC) {
          if (a.lastName && !b.lastName) {
            return -1
          } else if (!a.lastName && b.lastName) {
            return 1
          } else if (!a.lastName && !b.lastName) {
            return 0
          }
          return b.lastName.localeCompare(a.lastName)

        } else if (state.sortBy === SortOptions.VALID_UNTIL_DESC) {
          if (a.accountExpirationDate && !b.accountExpirationDate) {
            return -1
          } else if (!a.accountExpirationDate && b.accountExpirationDate) {
            return 1
          } else if (!a.accountExpirationDate && !b.accountExpirationDate) {
            return 0
          }
          return b.accountExpirationDate.localeCompare(a.accountExpirationDate)

        }

        return 0
      })
    }
  },
  getters: {
    isLoading (state) {
      return state.loading
    },
    isLoaded (state) {
      return state.loaded
    },
    isException (state) {
      return state.exception
    },
    getSearchFilter (state) {
      return state.searchFilter
    },
    getFilteredUsers (state) {
      return state.filteredUsers
    },
    getStatusFilters (state) {
      return state.statusFilters
    },
    getSortBy (state) {
      return state.sortBy
    },
    isAscendingSort (state) {
      return state.ascendingSort
    },
    getCurrentPage (state) {
      return state.currentPage
    },
    getCompanyCodeFilter (state) {
      return state.companyCodeFilter
    },
    getAdditionalFilters (state) {
      return state.additionalFilters
    },
    getCounts (state) {
      const users = state.users

      const inactive = users.filter(user => user.accountStatus === ACCOUNT_STATUS.INACTIVE).length
      const expired = users.filter(user => user.accountStatus === ACCOUNT_STATUS.PASSWORD_EXPIRED).length
      const locked = users.filter(user => user.accountStatus === ACCOUNT_STATUS.LOCKED).length

      return {
        total: users.length,
        inactive: inactive,
        expired: expired,
        locked: locked
      }
    }
  },
  actions: {
    fetchUsers ({ commit, rootGetters }) {
      commit('setLoading', true)

      return UserService
        .findUsers(rootGetters['eamCookies/isSuperUser'] ? {} : {
          companyCode: rootGetters['eamCookies/getCompanyCode']
        })
        .then(users => {
          commit('setUsers', users)
          commit('sortUsers')
          commit('updateFilteredUsers')
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    changeSearchFilter ({ commit }, filter) {
      commit('setSearchFilter', filter)
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    },
    changeStatusFilters ({ commit }, filters) {
      commit('setStatusFilters', filters)
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    },
    changeAdditionalFilters ({ commit }, filters) {
      commit('setAdditionalFilters', filters)
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    },
    changeSortBy ({ commit }, sortBy) {
      commit('setSortBy', sortBy)
      commit('setAscendingSort', sortBy.includes('asc'))
      commit('sortUsers')
      commit('updateFilteredUsers')
    },
    changeCurrentPage ({ commit }, page) {
      commit('setCurrentPage', page)
    },
    changeCompanyCodeFilter ({ commit }, filter) {
      commit('setCompanyCodeFilter', filter)
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    },
    resetFilters ({ commit }, status) {
      commit('setSearchFilter', '')
      commit('setStatusFilters', status === 'TOTAL' ? [] : [status])
      commit('setAdditionalFilters', [])
      commit('setCompanyCodeFilter', '')
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    },
    resetAdditionalFilters ({ commit }) {
      commit('setAdditionalFilters', [])
      commit('setStatusFilters', [])
      commit('updateFilteredUsers')
      commit('setCurrentPage', 1)
    }
  }
}
