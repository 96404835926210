<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="true"
    :loading="false"
    :breadcrumb-items="breadcrumbItems"
    :banner="banner">
    <template #breadcrumb>
      <InfraTabs :items="tabs" />
    </template>
    <template #content>
      <div class="container">
        <tws-map-generic-page-alerts
          :loading="loading"
          :exception="exception" />

        <div
          v-if="!loading && !exception"
          class="row">
          <div class="col-lg-6 col-md-12">
            <h1 class="heading-m heading-m--black">
              {{ $t('map.reports-copper') }}
            </h1>
            <tws-map-cable-faults-counties-table
              :content="content"
              :items="cableFaults"
              @select="loadCountyInfo" />

            <tws-rich-text
              class="map-text-information"
              :html="mapTextInfo" />
          </div>

          <div class="col-lg-6 col-md-12">
            <div
              v-if="!selectedCounty"
              class="row">
              <div class="offset-lg-2 col-md-10 col-sm-12">
                <tws-map
                  :items="mapItems"
                  color-palette="orange-alt"
                  map-type="cable-faults"
                  @select="loadCountyInfo" />

                <h4>
                  {{ $t('map.inflows') }}
                </h4>

                <tws-map-legend
                  color-palette="orange-alt"
                  :items="mapLegend" />
              </div>
            </div>

            <div
              v-else
              class="row">
              <div class="col">
                <tws-map-cable-faults-county-modal
                  :text="content[selectedCounty.countyCode]"
                  :county-name="selectedCounty.name"
                  @close="selectedCounty = null" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>
<script>
import {
  mapActions, mapState
} from 'vuex'

import Map from '@/components/map/map'
import MapGenericPageAlerts from '@/components/map/map-generic-page-alerts'
import MapCableFaultsCountiesTable from '@/components/map/map-cable-faults/map-cable-faults-counties-table'
import MapCableFaultsCountyModal from '@/components/map/map-cable-faults/map-cable-faults-county-modal'
import MapLegend from '@/components/map/map-legend'
import i18n from '@/i18n'
import { networkInfoTabs } from '@/utils/network-info.utils'
import { InfraTabs } from 'tws-vue-components'
import CommonPageView from '@/views/CommonPageView'
import contentMock from '@/views/network-information/copper-service-faults/content-mock.json'
import { isLocaleEnglish } from '@/utils/locale.utils'
import { BREADCRUMBS_COPPER_SERVICE_FAULTS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    InfraTabs,
    CommonPageView,
    'tws-map': Map,
    'tws-map-cable-faults-counties-table': MapCableFaultsCountiesTable,
    'tws-map-cable-faults-county-modal': MapCableFaultsCountyModal,
    'tws-map-generic-page-alerts': MapGenericPageAlerts,
    'tws-map-legend': MapLegend
  },
  data () {
    return {
      content: contentMock,
      breadcrumbItems: BREADCRUMBS_COPPER_SERVICE_FAULTS,
      banner: {
        heading: i18n.t('network-information.tabs.copper-faults'),
        description: i18n.t('network-information.copper-faults.description')
      },
      tabs: networkInfoTabs,
      selectedCounty: null,
      mapLegend: [
        {
          status: 2,
          text: this.$t('map.legend.escalated')
        },
        {
          status: 1,
          text: this.$t('map.legend.elevated')
        },
        {
          status: 0,
          text: this.$t('map.legend.normal-short')
        }
      ]
    }
  },
  computed: {
    ...mapState('map/cableFaults', [
      'loading',
      'exception',
      'cableFaults',
      'mapItems'
    ]),
    mapTextInfo () {
      return isLocaleEnglish() ? this.content.mapTextInformation_en : this.content.mapTextInformation
    }
  },
  mounted () {
    this.fetchCableFaults()
  },
  methods: {
    loadCountyInfo (selectedCountyCode) {
      this.selectedCounty = this.cableFaults.find(c => {
        return c.countyCode === selectedCountyCode
      })
    },
    ...mapActions({
      fetchCableFaults: 'map/cableFaults/fetchCableFaults'
    })
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
}

.heading-m {
  margin-bottom: 40px;
}

h4 {
  margin-bottom: 10px;
}

.map-text-information {
  margin-top: 30px;
}
</style>
