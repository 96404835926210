<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <dashboard-banner />
    </InfraBreadcrumbContainer>

    <dashboard-container
      :net-information-page="netInformationPage"
      :news-view-page="newsViewPage"
      :document-links-heading="documentLinksHeading" />
  </div>
</template>

<script>
import DashboardBanner from './dashboard/dashboard-banner.vue'
import DashboardContainer from './dashboard/dashboard-container.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMB_DASHBOARD } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    InfraBreadcrumbContainer,
    DashboardBanner,
    DashboardContainer
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMB_DASHBOARD,
      netInformationPage: 'network-information',
      newsViewPage: '',
      documentLinksHeading: this.$t('dashboard.documentLinksHeading')
    }
  }
}
</script>
