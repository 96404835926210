import ContentfulWrapper from '@/services/contentful/contentful.wrapper'
import i18n from '@/i18n'

const ADDRESS_SEARCH_ENTRY_ID = '5AeFuYJ2rBBuX6THUimV69'
const ADDRESS_SEARCH_CONTENT_DEPTH = 3

const fetchContent = async () => {
  const content = await ContentfulWrapper.fetchEntry(ADDRESS_SEARCH_ENTRY_ID, {
    locale: i18n.locale,
    include: ADDRESS_SEARCH_CONTENT_DEPTH
  })

  return content.fields
}

const extractProductDescriptions = input => {
  return {
    wholesale_fiber: {
      title: input.wholesaleFiber.fields.title,
      tooltip: input.wholesaleFiber.fields.content
    },
    fiber_2009: {
      title: input.fiber2009.fields.title,
      tooltip: input.fiber2009.fields.content
    },
    fiber_single: {
      title: input.fiberSingle.fields.title,
      tooltip: input.fiberSingle.fields.content
    },
    copper_access: {
      title: input.copperAccess.fields.title,
      tooltip: input.copperAccess.fields.content
    },
    fiber_area: {
      title: input.fiberArea.fields.title,
      tooltip: input.fiberArea.fields.content
    },
    ip_stream_enhanced: {
      title: input.ipStreamEnhanced.fields.title,
      tooltip: input.ipStreamEnhanced.fields.content
    },
    phone_subscription: {
      title: input.phoneSubscription.fields.title,
      tooltip: input.phoneSubscription.fields.content
    },
    ethernet_sweden: {
      title: input.ethernetSweden.fields.title,
      tooltip: input.ethernetSweden.fields.content
    },
    ethernet_nordic: {
      title: input.ethernetNordic.fields.title,
      tooltip: input.ethernetNordic.fields.content
    },
    capacity_basic: {
      title: input.capacityBasic.fields.title,
      tooltip: input.capacityBasic.fields.content
    },
    wholesale_capacity: {
      title: input.wholesaleCapacity.fields.title,
      tooltip: input.wholesaleCapacity.fields.content
    }
  }
}

export default {
  namespaced: true,
  state: {
    loading: false,
    exception: false,
    productDescriptions: null,
    mapSearchUrl: null,
    mainTitle: null,
    mainDescription: null,
    errorMessages: {
      'too.many.addresses': 'För många adresser hittades.',
      'address.not.found': 'Adresserna saknas.',
      'too.many.addresses.a': 'För många adresser hittades på sida A.',
      'address.not.found.a': 'Adressen på sida A saknas.',
      'too.many.addresses.b': 'För många adresser hittades på sida B.',
      'address.not.found.b': 'Adressen på sida B saknas.',
      'too.many.addresses.both': 'För många adresser hittades.',
      'address.not.found.both': 'Adresserna saknas.',
      'contact.customer.support': 'Vänligen kontrollera adressen och prova igen. Om felet kvarstår kontakta Kundservice för adressförfrågan.',
      'node.not.found': 'Nod saknas.',
      'nodes.not.found': 'Noder saknas.'
    }
  },
  mutations: {
    exception: (state, exception) => state.exception = exception,
    loading: (state, loading) => state.loading = loading,
    productDescriptions: (state, productDescriptions) => state.productDescriptions = productDescriptions,
    mapSearchUrl: (state, mapSearchUrl) => state.mapSearchUrl = mapSearchUrl,
    mainTitle: (state, mainTitle) => state.mainTitle = mainTitle,
    mainDescription: (state, mainDescription) => state.mainDescription = mainDescription
  },
  getters: {
    loaded: state => state.loaded,
    exception: state => state.exception,
    loading: state => state.loading,
    productDescriptions: state => state.productDescriptions,
    errorMessages: state => state.errorMessages,
    mapSearchUrl: state => state.mapSearchUrl,
    mainTitle: state => state.mainTitle,
    mainDescription: state => state.mainDescription
  },
  actions: {
    async fetchContent ({ commit }) {
      commit('exception', true)
      commit('loading', true)

      try {
        const content = await fetchContent()

        commit('productDescriptions', extractProductDescriptions(content.productLabels.fields))
        commit('mapSearchUrl', content.mapSearchUrl)
        commit('mainTitle', content.name)
        commit('mainDescription', content.description)

      } catch {
        commit('exception', true)
      } finally {
        commit('loading', false)
      }
    }
  }
}
