<template>
  <div class="user-administration-banner">
    <div class="row">
      <div class="col-12">
        <h1 class="user-administration-banner__heading heading-l--black title-500">
          {{ $t('user-administration.heading') }}
        </h1>
      </div>
    </div>

    <div class="row user-administration-banner__filters">
      <div class="col-md-6 col-sm-12">
        <tws-dropdown-group
          v-if="isSuperUser"
          id="company-filter"
          v-model="companyCodeModel"
          class="user-administration-banner__company"
          :label="$t('common.business')">
          <option value="">
            {{ $t('common.all') }}
          </option>

          <option
            v-for="company in companies"
            :key="company.code"
            :value="company.code">
            {{ company.name }}
          </option>
        </tws-dropdown-group>
      </div>
    </div>

    <div class="row user-administration-banner__filters">
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <tws-action-card
          :text="userCounts.total"
          :title="$t('user-administration.total-users')"
          @click="onStatusClick('TOTAL')" />
      </div>

      <div class="col-lg-3 col-sm-6 col-xs-12">
        <tws-action-card
          :text="userCounts.inactive"
          :title="$t('user-administration.inactive')"
          @click="onStatusClick('INACTIVE')" />
      </div>

      <div class="col-lg-3 col-sm-6 col-xs-12">
        <tws-action-card
          :text="userCounts.expired"
          :title="$t('user-administration.expired')"
          @click="onStatusClick('PASSWORD EXPIRED')" />
      </div>

      <div class="col-lg-3 col-sm-6 col-xs-12">
        <tws-action-card
          :text="userCounts.locked"
          :title="$t('user-administration.locked')"
          @click="onStatusClick('LOCKED')" />
      </div>
    </div>

    <div class="row user-administration-banner__actions">
      <div class="col-xl-3 col-sm-6 col-xs-12">
        <tws-action-button @click="onAddUserClick">
          <TeliaCoreIcon
            name="add_thin"
            size="3rem" />
          <span>{{ $t('user-administration.create-user') }}</span>
        </tws-action-button>
      </div>

      <div class="col-xl-3 col-sm-6 col-xs-12">
        <tws-action-button @click="toggleHelpDialog">
          <TeliaCoreIcon
            name="guiding_light_thin"
            size="3rem" />
          <span>{{ $t('common.help') }}</span>
        </tws-action-button>
      </div>
    </div>

    <information-dialog
      v-show="showHelpDialog"
      :header="helpDialogHeader"
      @close="toggleHelpDialog">
      <template #body>
        <tws-rich-text :html="helpDialogMessage" />
      </template>
    </information-dialog>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  TwsActionButton,
  TwsActionCard,
  TwsDropdownGroup
} from 'tws-vue-components'

import InformationDialog from '@/components/common/information-dialog.vue'

export default {
  components: {
    TwsActionButton,
    TwsActionCard,
    TwsDropdownGroup,
    InformationDialog
  },
  props: {
    helpDialogHeader: {
      type: String,
      required: true
    },
    helpDialogMessage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showHelpDialog: false
    }
  },
  computed: {
    ...mapGetters({
      isSuperUser: 'eamCookies/isSuperUser',

      companies: 'lookups/companies/getCompanies',

      userCounts: 'userAdministration/getCounts',
      companyCodeFilter: 'userAdministration/getCompanyCodeFilter'
    }),
    companyCodeModel: {
      get () {
        return this.companyCodeFilter
      },
      set (value) {
        this.changeCompanyCodeFilter(value)
      }
    }
  },
  mounted () {
    if (this.isSuperUser) {
      this.fetchCompanies()
    }
  },
  methods: {
    ...mapActions({
      changeCompanyCodeFilter: 'userAdministration/changeCompanyCodeFilter',
      resetFilters: 'userAdministration/resetFilters',

      fetchCompanies: 'lookups/companies/fetchCompanies'
    }),
    onStatusClick (status) {
      this.resetFilters(status)
    },
    onAddUserClick () {
      this.$router.push({
        name: 'user-creation',
        params: this.$route.params
      })
    },
    toggleHelpDialog () {
      this.showHelpDialog = !this.showHelpDialog
    }
  }
}
</script>

<style lang="scss" scoped>
.user-administration-banner {
  &__heading {
    margin-bottom: 20px;
  }

  &__company {
    margin-bottom: 10px;

    .form-control {
      margin-bottom: 0;
    }
  }

  &__filters,
  &__actions {
    margin-left: -0.5px;
    margin-right: -0.5px;

    [class*="col-"] {
      padding-left: 0.5px;
      padding-right: 0.5px;
    }
  }

  &__actions {
    margin-top: 10px;
  }
}

@media (max-width: 64em) {
  .user-administration-banner {
    &__filters {
      [class*="col-"]:nth-child(n+3) {
        margin-top: 1px;
      }
    }
  }
}

@media (max-width: 44em) {
  .user-administration-banner {
    &__filters,
    &__actions {
      [class*="col-"]:not(:first-child) {
        margin-top: 1px;
      }
    }
  }
}
</style>
