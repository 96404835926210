<template>
  <div class="container company-administration-list">
    <div
      v-if="redirectMessage || loading || exception"
      class="row alerts-row">
      <div class="col-12">
        <InfraPageAlert
          v-if="redirectMessage"
          type="success">
          {{ redirectMessage }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="loading"
          type="info">
          {{ $t('company-administration.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="exception"
          type="danger">
          {{ $t('company-administration.loading-failed') }}
        </InfraPageAlert>
      </div>
    </div>

    <template v-if="companies.length > 0">
      <div class="row">
        <div class="col-4 company-search-field">
          <TeliaCoreIcon
            class="search-icon"
            name="search_thin"
            size="2.7rem" />

          <input
            id="filter"
            v-model="filterProxy"
            type="text"
            class="input-text"
            :placeholder="$t('company-administration.search-placeholder')">
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <tws-paginated-table
            v-show="filteredCompanies.length > 0"
            :items="filteredCompanies"
            :current-page="currentPage"
            :extra-page-count="3"
            :item-count-on-page="10"
            aria-label="company-search-list"
            @onPageChange="currentPageChanged"
            @onRowClick="onCompanyClick">
            <template #thead>
              <tr>
                <th>
                  {{ $t('common.company.code') }}
                </th>
                <th>
                  {{ $t('common.company.name') }}
                </th>
                <th>
                  {{ $t('common.company.number') }}
                </th>
                <th>
                  {{ $t('common.company.id') }}
                </th>
                <th>
                  {{ $t('common.company.tscid') }}
                </th>
              </tr>
            </template>

            <template #tr="{ item }">
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.orgNr }}</td>
              <td>{{ item.spId }}</td>
              <td>{{ item.tscid }}</td>
            </template>
          </tws-paginated-table>

          <div
            v-show="filteredCompanies.length === 0"
            class="empty-result-block">
            <p>
              {{ $t('company-administration.no-results') }}
            </p>

            <ul>
              <li>
                {{ $t('company-administration.check-keywords') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import URIUtils from '@/utils/uri-utils'

import {
  InfraPageAlert,
  TwsPaginatedTable
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsPaginatedTable
  },
  data () {
    return {
      breadcrumbItems: [],
      redirectAction: {
        action: this.$route.query.action,
        company: this.$route.query.company
      }
    }
  },
  computed: {
    ...mapState('companyAdministration', [
      'loading',
      'exception',
      'companies',
      'filteredCompanies',
      'currentPage',
      'filter'
    ]),
    filterProxy: {
      get () {
        return this.filter
      },
      set (value) {
        this.filterChanged(value)
      }
    },
    redirectMessage () {
      if ('creation' === this.redirectAction.action) {
        return this.$t('company-administration.messages.success', [URIUtils.decodeURIParam(this.redirectAction.company)])
      }
      return null
    }
  },
  mounted () {
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('companyAdministration', [
      'fetchCompanies',
      'filterChanged',
      'currentPageChanged'
    ]),
    onCompanyClick (company) {
      this.$router.push(`${this.pathLocale}${process.env.VUE_APP_COMPANY_DETAILS_PATH}${company.code}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  margin-top: 45px;
  margin-bottom: 45px;
}

.search-icon {
  position: absolute;
  margin-left: 15px;
  margin-top: 10px;
  z-index: 1;
}

.company-search-field {
  margin-bottom: 50px;

  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $light-grey;
    padding-left: 60px;
  }

  i {
    position: absolute;
    top: 17px;
    left: 18px;
  }
}

.company-administration-list::v-deep tbody tr {
  cursor: pointer;
}

.empty-result-block {
  border: 1px solid;
  padding: 20px;
  margin: 0 200px;

  ul {
    padding-left: 20px;
  }

  p, ul {
    margin-bottom: 0;
  }
}

.alerts-row {
  margin-bottom: 45px;
}
</style>
