<template>
  <div>
    <InfraBreadcrumbContainer
      :grey-background="true"
      :items="breadcrumbItems">
      <InfraGenericBannerAlt
        v-if="mainTitle"
        :heading="mainTitle" />
      <div class="row">
        <div class="col-lg-7 col-md-8 col-sm-8">
          <InfraContentfulRichTextContainer
            v-if="mainDescription"
            :content="mainDescription" />
        </div>
      </div>
    </InfraBreadcrumbContainer>

    <div class="container">
      <AddressSearch
        @select-address="fetchProducts"
        @select-address-address="fetchFiberProductsAddressAddress"
        @select-address-node="fetchFiberProductsAddressNode"
        @select-node-node="fetchFiberProductsNodeNode" />
      <ProductsDisplay v-if="!addressSearchLoading" />
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import {
  InfraBreadcrumbContainer,
  InfraGenericBannerAlt,
  InfraContentfulRichTextContainer
} from 'tws-vue-components'
import AddressSearch from '@/apps/address-search/components/AddressSearch'
import ProductsDisplay from '@/apps/address-search/components/ProductsDisplay'
import { SEARCH_MODE } from '@/apps/address-search/utils/constants.utils'
import { BREADCRUMBS_ADDRESS_SEARCH } from '@/constants/breadcrumbs-constants'

export default {
  name: 'Main',
  components: {
    AddressSearch,
    ProductsDisplay,
    InfraBreadcrumbContainer,
    InfraGenericBannerAlt,
    InfraContentfulRichTextContainer
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_ADDRESS_SEARCH
    }
  },
  computed: {
    ...mapGetters('AddressSearch/AddressSearch', {
      searchMode: 'searchMode',
      addressSearchLoading: 'loading'
    }),
    ...mapGetters('AddressSearch/Content', [
      'loading',
      'mainTitle',
      'mainDescription'
    ])
  },
  watch: {
    searchMode (next) {
      if (next === SEARCH_MODE.ADDRESS) {
        this.resetProducts()
      } else {
        this.resetAdvancedProducts()
      }
    }
  },
  mounted () {
    this.$store.dispatch('AddressSearch/Content/fetchContent')
  },
  methods: {
    ...mapActions('AddressSearch/Products', {
      fetchProducts: 'fetchProducts',
      resetProducts: 'resetState'
    }),
    ...mapActions('AddressSearch/Products/advancedProducts', {
      fetchFiberProductsAddressAddress: 'fetchFiberProductsAddressAddress',
      fetchFiberProductsAddressNode: 'fetchFiberProductsAddressNode',
      fetchFiberProductsNodeNode: 'fetchFiberProductsNodeNode',
      resetAdvancedProducts: 'resetState'
    })
  }
}
</script>
