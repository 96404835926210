<template>
  <form
    :action="authorizationUrl"
    class="form"
    method="POST"
    @submit="submit">
    <input
      :value="loginUrl"
      type="hidden"
      name="errorUrl">

    <input
      :value="returnUrl"
      type="hidden"
      name="returnUrl">

    <input
      :value="expiredPasswordUrl"
      type="hidden"
      name="expiredPasswordUrl">

    <slot />
  </form>
</template>

<script>

export default {
  props: {
    validator: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loginUrl: location.origin + process.env.VUE_APP_LOGIN_PATH,
      returnUrl: location.origin + sessionStorage.getItem('returnUri'),
      expiredPasswordUrl: location.origin + process.env.VUE_APP_RESET_PASSWORD_PATH,
      authorizationUrl: process.env.VUE_APP_AUTHORIZATION_URL
    }
  },
  methods: {
    submit (event) {
      this.validator.$touch()

      if (this.validator.$invalid) {
        event.preventDefault()
      } else {
        this.$emit('submitted')
      }
    }
  }
}
</script>
