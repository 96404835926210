import { createInstance } from './axios.api'
import { getSelectedLocale } from '@/utils/locale.utils'

export const api = createInstance(process.env.VUE_APP_SEARCH_API)

const SiteSearchService = {
  getSearchResults: (body) => {
    const finalizedBody = {
      ...body,
      locale: getSelectedLocale()
    }
    return api.post('/search', finalizedBody)
  }
}

export default SiteSearchService
