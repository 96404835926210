import axios from 'axios'

export const getProducts = async ({ streetName, streetNumber, entrance, city, postNumber, pointId }) => {
  const { data } = await axios.get(`${process.env.VUE_APP_PRODUCT_SEARCH_URL}/product/accesses`, {
    params: {
      streetName,
      streetNumber,
      entrance,
      city,
      postNumber,
      pointId
    }
  })

  return data
}

const getPointForNode = node => ({
  coverageArea: node.node,
  kc: node.kc
})

export const getFiberProducts = {
  addressAddress: async ([
    pointA,
    pointB
  ]) => {
    const { data } = await axios.post(
      `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/extended/search/address/address`,
      {
        pointA,
        pointB
      }
    )

    return data
  },
  addressStation: async ([
    pointA,
    pointB
  ]) => {
    const { data } = await axios.post(
      `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/extended/search/address/station`,
      {
        pointA,
        pointB: getPointForNode(pointB)
      }
    )

    return data
  },
  stationStation: async ([
    pointA,
    pointB
  ]) => {
    const { data } = await axios.post(
      `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/extended/search/station/station`,
      {
        pointA: getPointForNode(pointA),
        pointB: getPointForNode(pointB)
      }
    )

    return data
  }
}
