import i18n from '@/i18n'
import TeliaCoreIcon from '@telia/core-icons'
import svg4everybody from 'svg4everybody'
import Vue from 'vue'
import {
  mapActions, mapGetters
} from 'vuex'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'

import CookieUtils from '@/utils/cookie.utils.js'
import LocalisedLink from '@/components/common/localised-link.vue'
import './common-components.js'
import './main.scss'
import './icon-components'

import {
  commaSeparated, convertDateTime, fileName
} from '@/utils/filters.utils'
import LocaleMixin from './mixins/locale.mixin'

Vue.component('TeliaCoreIcon', TeliaCoreIcon)
Vue.component('LocalisedLink', LocalisedLink)
Vue.component('TwsPebbleBackgroundContainer', () => import(/* webpackChunkName: 'tws-pebble-background-container' */ './components/pebble-background-container/pebble-background-container.vue'))
Vue.component('TwsBreadcrumbContainer', () => import(/* webpackChunkName: 'tws-breadcrumb-container' */ './components/breadcrumb-container/breadcrumb-container.vue'))
//Vue.component('CookiePolicyLink', CookiePolicyLink)

Vue.filter('commaSeparated', commaSeparated)
Vue.filter('convertDateTime', convertDateTime)
Vue.filter('fileName', fileName)

Vue.config.productionTip = false
// Suppress the validateDOMNesting warning
// eslint-disable-next-line no-console
const originalConsoleError = console.error

Vue.mixin(LocaleMixin)

// eslint-disable-next-line no-console
console.error = (message) => {
  if (typeof message === 'string' && message.startsWith('Warning: validateDOMNesting')) {
    // Suppress the warning
    return
  }
  // Log other errors or warnings
  originalConsoleError(message)
}

new Vue({
  store,
  router,
  i18n,
  computed: {
    ...mapGetters('eamCookies', [
      'isSuperUser',
      'isAdmin'
    ])
  },
  created () {
    window.addEventListener('DOMContentLoaded', () => svg4everybody({ polyfill: true }))

    if (this.$route.name === '/login' && this.$route.query['CT_ORIG_URL'] !== undefined) {
      CookieUtils.removeCookies()
    }

    this.loadUser()

    if (this.isSuperUser || this.isAdmin) {
      this.loadCompany()
    }
  },
  methods: {
    ...mapActions('eamCookies', ['loadUser']),
    ...mapActions('MyDocuments', ['loadCompany'])
  },
  render: h => h(App)
}).$mount('#tws')
