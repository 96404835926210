import CookieUtils from '@/utils/cookie.utils'
import axios from 'axios'

export const createInstance = (url) => {
  const api = axios.create({
    baseURL: url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'json'
  })

  api.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (error.response && error.response.status === 401) {
        CookieUtils.removeCookies()
        window.dispatchEvent(new Event('showSessionTimedOutDialog'))
      }
      return Promise.reject(error)
    }
  )

  return api
}
