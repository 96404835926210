import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_USER_PASSWORD_SERVICE)

const ResetPasswordService = {
  validateResetPasswordKey: resetPasswordKey => {
    return api.get('/verify/' + resetPasswordKey)
  },
  changePassword: (resetPasswordKey, password) => {
    return api.post('/change', {
      newPassword: password,
      resetPasswordKey: resetPasswordKey
    })
  },
  changePasswordSecure: (currentPassword, newPassword) => {
    return api.post('/change/secure', {
      currentPassword: currentPassword,
      newPassword: newPassword
    })
  },
  resetPassword: (username, email) => {
    return api.post('/reset', {
      username: username,
      email: email
    })
  },
  loggedResetPassword: (username, email) => {
    return api.post('/login/reset', {
      username: username,
      email: email
    })
  }
}

export default ResetPasswordService
