import axios from 'axios'

export const queryAssets = async query => {
  const { data } = await axios.post(
    `${process.env.VUE_APP_C2B_CACHE_SERVICE}/v1/assets`,
    { query: query.toUpperCase() })

  return data
}

export const fetchAssetsByCategoryId = async id => {
  const { data } = await axios.post(
    `${process.env.VUE_APP_C2B_CACHE_SERVICE}/v1/assets`,
    { category: id })

  return data
}

export const fetchProductCategories = async () => {
  const { data } = await axios.post(
    `${process.env.VUE_APP_C2B_CACHE_SERVICE}/v1/categories`)

  return data
}
