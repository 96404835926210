import { getNewsPayload } from '@/utils/news.utils'
import { createInstance } from '@/services/axios.api'
import i18n from '@/i18n'

export const api = createInstance(process.env.VUE_APP_CONTENTFUL_GATEWAY)

const NewsService = {
  getNewsDetails: (newsId) => {
    return api.get('/news', {
      params: {
        id: newsId,
        locale: i18n.locale
      }
    })
  },
  getAllNews: (form) => {
    return api.post('/news/all', getNewsPayload(form, i18n.locale))
  },
  getPublicNews: (form) => {
    return api.post('/news/public', getNewsPayload(form, i18n.locale))
  }
}

export default NewsService
