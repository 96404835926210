import UserService from '@/services/user.service.js'

export const MassUserDeletionModule = {
  namespaced: true,
  modules: {
    search: {
      namespaced: true,
      state: {
        loading: false,
        loaded: false,
        exception: false,
        users: [],
        selectedUsers: []
      },
      mutations: {
        setLoading (state, loading) {
          state.loading = loading
        },
        setLoaded (state, loaded) {
          state.loaded = loaded
        },
        setException (state, exception) {
          state.exception = exception
        },
        setUsers (state, users) {
          state.users = users
        },
        setSelectedUsers (state, selectedUsers) {
          state.selectedUsers = selectedUsers
        }
      },
      getters: {
        isLoading (state) {
          return state.loading
        },
        isLoaded (state) {
          return state.loaded
        },
        isException (state) {
          return state.exception
        },
        getUsers (state) {
          return state.users
        },
        getSelectedUsers (state) {
          return state.selectedUsers
        }
      },
      actions: {
        fetchUsers ({ commit, dispatch }, data) {
          commit('setLoading', true)
          commit('setLoaded', false)
          commit('setException', false)
          commit('setUsers', [])
          commit('setSelectedUsers', [])

          dispatch('massUserDeletion/deletion/resetException', null, {
            root: true
          })

          return UserService
            .findUsers(data)
            .then(users => {
              commit('setUsers', users)
              commit('setLoaded', true)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setLoading', false)
            })
        },
        removeDeletedUsers ({ commit, getters }, usernames) {
          const users = getters['getUsers']

          usernames.forEach(username => {
            const index = users.map(user => user.username).indexOf(username)
            users.splice(index, 1)
          })

          commit('setUsers', users)
          commit('setSelectedUsers', [])
        },
        userSelected ({ commit }, users) {
          commit('setSelectedUsers', users)
        }
      }
    },
    deletion: {
      namespaced: true,
      state: {
        deleting: false,
        deletedUsernames: [],
        exception: false
      },
      mutations: {
        setDeleting (state, deleting) {
          state.deleting = deleting
        },
        setDeletedUsernames (state, deletedUsernames) {
          state.deletedUsernames = deletedUsernames
        },
        setException (state, exception) {
          state.exception = exception
        }
      },
      getters: {
        isDeleting (state) {
          return state.deleting
        },
        getDeletedUsernames (state) {
          return state.deletedUsernames
        },
        isException (state) {
          return state.exception
        }
      },
      actions: {
        deleteUsers ({ commit, rootGetters, dispatch }) {
          commit('setDeleting', true)
          commit('setDeletedUsernames', [])
          commit('setException', false)

          const selectedUsers = rootGetters['massUserDeletion/search/getSelectedUsers']
          const selectedUsernames = selectedUsers.map(user => user.username)

          return UserService
            .deleteUsersByUsername(selectedUsernames)
            .then(failedUsernames => {
              const deletedUsernames = selectedUsernames.filter(username => failedUsernames.indexOf(username) === -1)

              dispatch('massUserDeletion/search/removeDeletedUsers', deletedUsernames, {
                root: true
              })

              commit('setDeletedUsernames', deletedUsernames)

              setTimeout(() => {
                commit('setDeletedUsernames', [])
              }, 5000)
            })
            .catch(() => {
              commit('setException', true)
            })
            .finally(() => {
              commit('setDeleting', false)
            })
        },
        resetException ({ commit }) {
          commit('setException', false)
        }
      }
    }
  }
}
