<template>
  <div>
    <div class="container">
      <div
        v-show="transferring || transferred || exception"
        class="row">
        <div class="col">
          <InfraPageAlert
            v-show="transferring"
            type="info">
            {{ $t('mass-user-transfer.transferring') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-show="transferred && transferredCount === 0"
            type="danger">
            {{ $t('mass-user-transfer.transferring-failed') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-show="transferred && transferredCount > 0"
            type="success">
            {{ successMessage }}
          </InfraPageAlert>

          <tws-table
            v-if="transferred"
            :columns="columns"
            :items="getUsers"
            header-color="#ffffff" />
        </div>
      </div>

      <div
        v-show="!transferring"
        class="form-container">
        <div class="row">
          <div class="col">
            <tws-validated-text-area-group
              id="username-list"
              v-model="usernamesProxy"
              :validator="$v.usernamesProxy"
              :validation-messages="validationMessages.usernamesProxy"
              :label="$t('mass-user-transfer.usernames')" />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <tws-validated-dropdown-group
              id="from-company"
              v-model="fromCompany"
              :validator="$v.fromCompany"
              :validation-messages="validationMessages.fromCompany"
              :label="$t('mass-user-transfer.from-company')">
              <option value="">
                {{ $t('common.select') }}
              </option>
              <option
                v-for="company in companies"
                :key="company.code"
                :value="company.code">
                {{ company.name }}
              </option>
            </tws-validated-dropdown-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <tws-validated-dropdown-group
              id="to-company"
              v-model="toCompany"
              :validator="$v.toCompany"
              :validation-messages="validationMessages.toCompany"
              :label="$t('mass-user-transfer.to-company')">
              <option value="">
                {{ $t('common.select') }}
              </option>
              <option
                v-for="company in companies"
                :key="company.code"
                :value="company.code">
                {{ company.name }}
              </option>
            </tws-validated-dropdown-group>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <tws-button-primary
              @click="toggleDialog">
              {{ $t('mass-user-transfer.submit') }}
            </tws-button-primary>
          </div>
        </div>
      </div>

      <confirmation-dialog
        v-show="showDialog"
        :heading="$t('mass-user-transfer.dialog-heading')"
        :confirmation-button-text="$t('mass-user-transfer.submit')"
        :cancel-button-text="$t('common.cancel')"
        @confirm="onConfirm"
        @cancel="toggleDialog">
        <template #body>
          {{ dialogMessage }}
        </template>
      </confirmation-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  not,
  required,
  sameAs
} from 'vuelidate/lib/validators'

import {
  TwsButtonPrimary,
  InfraPageAlert,
  TwsTable,
  TwsValidatedDropdownGroup,
  TwsValidatedTextAreaGroup
} from 'tws-vue-components'

import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'

export default {
  components: {
    TwsButtonPrimary,
    InfraPageAlert,
    TwsTable,
    TwsValidatedDropdownGroup,
    TwsValidatedTextAreaGroup,
    ConfirmationDialog
  },
  mixins: [validationMixin],
  data () {
    return {
      showDialog: false,
      fromCompany: '',
      toCompany: '',
      validationMessages: {
        fromCompany: {
          required: this.$t('mass-user-transfer.validations.from-company.required'),
          notSameAsToCompany: this.$t('mass-user-transfer.validations.from-company.not-same-as-to')
        },
        toCompany: {
          required: this.$t('mass-user-transfer.validations.to-company.required'),
          notSameAsFromCompany: this.$t('mass-user-transfer.validations.to-company.not-same-as-from')
        },
        usernamesProxy: {
          required: this.$t('mass-user-transfer.validations.usernames-proxy.required')
        }
      },
      columns: [
        {
          key: 'username',
          value: this.$t('mass-user-transfer.table-headers.username')
        },
        {
          key: 'status',
          value: this.$t('mass-user-transfer.table-headers.status')
        },
        {
          key: 'transferedUsername',
          value: this.$t('mass-user-transfer.table-headers.transferred-username')
        },
        {
          key: 'message',
          value: this.$t('mass-user-transfer.table-headers.information')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('massUserTransfer', [
      'getUsernamesAsArray',
      'getUsers'
    ]),
    ...mapState('massUserTransfer', [
      'usernames',
      'transferring',
      'transferred',
      'exception',
      'transferredCount'
    ]),
    ...mapState('lookups/companies', ['companies']),
    usernamesProxy: {
      get () {
        return this.usernames
      },
      set (value) {
        this.usernamesEntered(value)
      }
    },
    successMessage () {
      return this.$t('mass-user-transfer.messages.success', [this.transferredCount])
    },
    dialogMessage () {
      return this.$t('mass-user-transfer.messages.dialog', [this.getUsernamesAsArray.length])
    }
  },
  mounted () {
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('massUserTransfer', [
      'transferUsers',
      'usernamesEntered'
    ]),
    ...mapActions('lookups/companies', ['fetchCompanies']),
    toggleDialog () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.showDialog = !this.showDialog
      }
    },
    onConfirm () {
      this.transferUsers({
        fromCompany: this.fromCompany,
        toCompany: this.toCompany
      }).then(() => {
        this.$v.usernamesProxy.$reset()
      })

      this.showDialog = false
    }
  },
  validations: {
    fromCompany: {
      required,
      notSameAsToCompany: not(sameAs('toCompany'))
    },
    toCompany: {
      required,
      notSameAsFromCompany: not(sameAs('fromCompany'))
    },
    usernamesProxy: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
  margin-bottom: 130px;
}
</style>
