import CompanyService from '@/services/company.service.js'

const filterBySearch = (companies, search) => {
  if (!search) {
    return companies
  }

  return companies.filter(company => {
    const containsCode = company.code.toLowerCase().includes(search)
    const containsName = company.name.toLowerCase().includes(search)
    const containsOrgNumber = company.orgNr ? company.orgNr.toLowerCase().includes(search) : false
    const containsSpId = company.spId ? company.spId.toLowerCase().includes(search) : false
    const containsTSCID = company.tscid ? company.tscid.toLowerCase().includes(search) : false

    return containsCode || containsName || containsOrgNumber || containsSpId || containsTSCID
  })
}

export const CompanyAdministrationModule = {
  namespaced: true,
  state: {
    loading: false,
    exception: false,
    companies: [],
    filteredCompanies: [],
    currentPage: 1,
    filter: ''
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setException (state, exception) {
      state.exception = exception
    },
    setCompanies (state, companies) {
      state.companies = companies
    },
    setFilteredCompanies (state, companies) {
      state.filteredCompanies = companies
    },
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    setFilter (state, filter) {
      state.filter = filter
    }
  },
  actions: {
    fetchCompanies ({ commit }) {
      commit('setLoading', true)
      commit('setException', false)
      commit('setCompanies', [])
      commit('setFilteredCompanies', [])

      return CompanyService
        .getCompanies()
        .then(companies => {
          commit('setCompanies', companies)
          commit('setFilteredCompanies', companies)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    filterChanged ({ commit, state }, filter) {
      commit('setFilter', filter)
      commit('setFilteredCompanies', filterBySearch(state.companies, state.filter.toLowerCase()))
      commit('setCurrentPage', 1)
    },
    currentPageChanged ({ commit }, page) {
      commit('setCurrentPage', page)
    }
  }
}
