import PageView from '@/apps/pages/views/PageView'
import IndividualRightsView from '@/views/individual-rights/IndividualRightsView'
import DevelopmentActView from '@/views/development-act/DevelopmentActView'

export default [
  {
    path: 'public',
    component: {
      template: '<router-view />'
    },
    children: [
      {
        name: 'development-act',
        path: 'utbyggnadslagen',
        component: DevelopmentActView
      },
      {
        name: 'public',
        path: ':slug',
        component: PageView
      },
      {
        name: 'individual-rights',
        path: 'var-integritetspolicy/individuella-rattigheter',
        component: IndividualRightsView
      },
      {
        name: 'public-sub',
        path: ':slug/:subslug',
        component: PageView
      }
    ]
  },
  {
    path: 'private',
    component: {
      template: '<router-view />'
    },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'private',
        path: ':slug',
        component: PageView
      },
      {
        name: 'private-sub',
        path: ':slug/:subslug',
        component: PageView
      }
    ]
  },
  {
    name: 'privat-double-sub',
    path: 'privat/:slug/:subslug/:subslug2',
    component: PageView
  }
]
