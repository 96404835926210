import ContentfulWrapper from '@telia/contentful-wrapper'
import i18n from '@/i18n'
import { getHost } from '@/utils/domain.utils'

const HELP_MODAL_ENTRY_ID = 'dQuY7Qf4pAR31ZuropcrN'

const client = new ContentfulWrapper({
  environment: process.env.VUE_APP_CONTENTFUL_WRAPPER_ENVIRONMENT,
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: '_',
  host: getHost(),
  basePath: process.env.VUE_APP_CONTENTFUL_WRAPPER_BASE_PATH
})

const getContent = async () => {
  return client.getEntry(HELP_MODAL_ENTRY_ID, {
    locale: i18n.locale
  })
}

export default {
  getContent
}
