import i18n from '@/i18n'
import secureHeaderProductsService
  from '@/services/secure-header-products.service'

export const BREADCRUMB_DASHBOARD = [{
  title: i18n.t('common.telia-wholesale'),
  path: '/dashboard'
}]

export const BREADCRUMBS_INDIVIDUAL_RIGHTS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('footer.our-privacy-policy'),
    path: '/public/var-integritetspolicy'
  },
  {
    title: i18n.t('individual-rights.heading'),
    path: '/public/var-integritetspolicy/individuella-rattigheter'
  }
]

export const BREADCRUMBS_DEVELOPMENT_ACT = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: 'Utbyggnadslagen',
    path: '/public/utbyggnadslagen'
  }
]

export const BREADCRUMBS_ADDRESS_SEARCH = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('search-tools.title')
  },
  { title: i18n.t('search-tools.address-search.title') }
]

export const BREADCRUMBS_MAP_SEARCH = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('search-tools.title')
  },
  { title: i18n.t('search-tools.map-search.title') }
]

export const BREADCRUMBS_DASHBOARD_SETTINGS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('user-dashboard.heading'),
    path: '/dashboard/installningar'
  }
]

export const BREADCRUMBS_USER_DELETION = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('mass-user-deletion.banner-header'),
    path: '/dashboard/user-aadministration/user-deletion'
  }
]

export const BREADCRUMBS_USER_TRANSFER = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('mass-user-transfer.banner-header'),
    path: '/dashboard/user-aadministration/user-transfer'
  }
]

export const BREADCRUMBS_INVOICES = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('header.menu-items.my-invoices'),
    path: '/dashboard/my-invoices/invoices'
  }
]

export const BREADCRUMBS_REPORTS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('header.menu-items.my-reports'),
    path: '/dashboard/my-reports'
  }
]

export const BREADCRUMBS_REPORTS_UPLOAD = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('header.administration-categories.reports-upload'),
    path: '/dashboard/reports-upload'
  }
]

export const BREADCRUMBS_REPORTS_ADMINISTRATION = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('header.administration-categories.reports-administration'),
    path: '/dashboard/reports-administration'
  }
]

export const BREADCRUMBS_USER_ADMINISTRATION = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('user-administration.heading'),
    path: '/dashboard/user-administration'
  }
]

export const BREADCRUMBS_COMPANY_ADMINISTRATION = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('company-administration.heading'),
    path: '/dashboard/company-administration'
  }
]

export const BREADCRUMBS_COMPANY_CREATION = [
  ...BREADCRUMBS_COMPANY_ADMINISTRATION,
  {
    title: i18n.t('company-creation.banner-header'),
    path: '/dashboard/company-administration/company-creation'
  }
]

export const BREADCRUMBS_COMPANY_DETAILS = [
  ...BREADCRUMBS_COMPANY_ADMINISTRATION,
  {
    title: i18n.t('company-administration.breadcrumbs.company-details'),
    path: '/dashboard/company-administration/company-details'
  }
]

export const BREADCRUMBS_CREATE_USER = [
  ...BREADCRUMBS_USER_ADMINISTRATION,
  {
    title: i18n.t('user-administration.create-user'),
    path: '/dashboard/company-administration/user-creation'
  }
]
export const BREADCRUMBS_USER_DETAILS = [
  ...BREADCRUMBS_USER_ADMINISTRATION,
  {
    title: i18n.t('personal-information.heading'),
    path: '/dashboard/company-administration/user-details'
  }
]

export const BREADCRUMBS_COPPER_SERVICE_STATUS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.other-news')
  },
  {
    title: i18n.t('network-information.tabs.copper-status')
  }
]

export const BREADCRUMBS_COPPER_SERVICE_FAULTS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.other-news')
  },
  {
    title: i18n.t('network-information.tabs.copper-faults')
  }
]

export const BREADCRUMBS_DELIVERY_TIME = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.other-news')
  },
  {
    title: i18n.t('network-information.tabs.delivery-time')
  }
]

export const BREADCRUMBS_OTHER_NEWS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.other-news')
  }
]

export const BREADCRUMBS_NEWS_ARCHIVE = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.news-archive'),
    path: '/news-archive',
    routerLink: true
  }
]

export const BREADCRUMBS_MARKET_CONSULTATIONS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('market-consultations.title'),
    path: '/samrad',
    routerLink: true
  }
]

export const BREADCRUMBS_OPERATIONAL_NEWS = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('news.breadcrumbs.operational-news')
  }
]

export const BREADCRUMBS_AREA_SEARCH = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('search-tools.title')
  },
  { title: i18n.t('area-search.title') }
]

export const BREADCRUMBS_PHONE_SEARCH = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('search-tools.title')
  },
  { title: i18n.t('search-categories.phone') }
]

export const BREADCRUMBS_ASSET_SEARCH = [
  ...BREADCRUMB_DASHBOARD,
  {
    title: i18n.t('search-tools.title')
  },
  { title: i18n.t('search-categories.connection-number') }
]

export const BREADCRUMBS_CUSTOMER_TICKETS = {
  LIST: [
    {
      title: i18n.t('ticket-list.breadcrumbs.my-tickets'),
      path: '/tickets'
    },
    { title: i18n.t('ticket-list.breadcrumbs.list') }
  ],
  CREATE: [
    {
      title: i18n.t('ticket-create.breadcrumbs.my-tickets'),
      path: '/tickets'
    },
    { title: i18n.t('ticket-create.breadcrumbs.title') }
  ],
  DETAILS: [{
    title: i18n.t('ticket-list.breadcrumbs.my-tickets'),
    path: '/tickets'
  }]
}

export const BREADCRUMBS_CONTACT_US = [{
  title: i18n.t('header.menu-items.contact')
}]

const BREADCRUMB_QUOTE_ORDER = [{ title: i18n.t('header.menu-items.orders') }]

export const BREADCRUMBS_QUOTE_ORDER_IFRAMES = {
  MY_ORDERS: {
    SKANOVA: [
      ...BREADCRUMB_DASHBOARD,
      { title: i18n.t('header.menu-items.my-orders') }
    ]
  },
  TJANSTER: {
    ADDRESS_CONNECTION: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.SERVICES_PRODUCT_NAMES.ADDRESS_CONNECTION) }
    ],
    FIBER_PERFORMANCE: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.SERVICES_PRODUCT_NAMES.FIBER_PERFORMANCE) }
    ]
  },
  FIBER: {
    WHOLESALE_FIBER: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.TELIA_WHOLESALE_FIBER) }
    ],
    SKANOVA_FIBER_2009: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.SKANOVA_FIBER_2009) }
    ],
    SKANOVA_FIBER_SINGEL: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.SKANOVA_FIBER_SINGEL) }
    ],
    FIBER_LAGENHET: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.FIBER_LAGENHET) }
    ],
    FIBER_VILLA: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.FIBER_VILLA) }
    ],
    FIBER_SMAFORETAG: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.FIBER_PRODUCT_NAMES.FIBER_SMAFORETAG) }
    ]
  },
  KOPPAR: {
    KOPPARACCESS: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.COPPER_PRODUCT_NAMES.KOPPARACCESS) }
    ],
    KOPPAR_PUNKT_TILL_PUNKT: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.COPPER_PRODUCT_NAMES.KOPPAR_PUNKT_TILL_PUNKT) }
    ],
    IP_STREAM_ENHANCED: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.COPPER_PRODUCT_NAMES.IP_STREAM_ENHANCED) }
    ]
  },
  IP_WDM: {
    CAPACITY_WHOLESALE: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.ETHERNET_PRODUCT_NAMES.CAPACITY_WHOLESALE) }
    ],
    ETHERNET_SWEDEN: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.ETHERNET_PRODUCT_NAMES.ETHERNET_SWEDEN) }
    ],
    SDH_SWEDEN: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.ETHERNET_PRODUCT_NAMES.SDH_SWEDEN) }
    ],
    CAPACITY_BASIC: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.ETHERNET_PRODUCT_NAMES.CAPACITY_BASIC) }
    ],
    MOBILE_BACKHAULT: [
      ...BREADCRUMB_DASHBOARD,
      ...BREADCRUMB_QUOTE_ORDER,
      { title: i18n.t(secureHeaderProductsService.ETHERNET_PRODUCT_NAMES.MOBILE_BACKHAULT) }
    ]
  }
}

export const BREADCRUMBS_FIBER_NETWORK_CONNECTION = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('product-names.apartment-mains')
  }
]

export const BREADCRUMBS_ADDRESS_REQUEST = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('product-names.address-request')
  }
]

export const BREADCRUMBS_REQUEST_AND_ORDER_EXPRESS_DELIVERY = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.request-and-order-express-delivery')
  }
]

export const BREADCRUMBS_BLOCKING_REQUEST = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.blocking-request')
  }
]

export const BREADCRUMBS_ORDER_MAPS = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.order-maps-breadcrumb')
  }
]

export const BREADCRUMBS_MAP_FILES_SUBSCRIPTION = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.map-files-subscription')
  }
]

export const BREADCRUMBS_DECOMMISSION_REDUCTION_OF_ESTABLISHMENT_PRODUCT = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.decommission-reduction-of-establishment-product')
  }
]

export const BREADCRUMBS_NODE_INTERNAL = [
  ...BREADCRUMB_DASHBOARD,
  ...BREADCRUMB_QUOTE_ORDER,
  {
    title: i18n.t('dynamic-forms-titles.node-internal')
  }
]
