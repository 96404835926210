<template>
  <div class="row">
    <div class="col-12">
      <h1 class="display-200 heading-greeting">
        {{ $t('common.greeting') }} {{ getFirstName }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('eamCookies', ['getFirstName'])
  }
}
</script>

<style lang="scss" scoped>
.heading-greeting {
 font-size: 40px;
}
</style>
