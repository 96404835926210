<template>
  <div class="municipalities-container">
    <h2 class="heading-m">
      {{ countyName }}
    </h2>

    <tws-close2-icon @click="$emit('close')" />

    <tws-rich-text :html="text" />
  </div>
</template>

<script>

export default {
  props: {
    countyName: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/components/map/scss/map-table-municipalities';
</style>
