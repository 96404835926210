<template>
  <reset-password-container
    :heading="$t('reset-password.enter-password.header')"
    :description="$t('reset-password.enter-password.description')">
    <div class="col-12">
      <template
        v-if="!isLoading && isPasswordResetKeyValid && !passwordChanged">
        <InfraPageAlert
          v-if="showExpiredMessage"
          id="expired-alert"
          type="info">
          {{ $t("reset-password.enter-password.expired-alert") }}
        </InfraPageAlert>
        <form class="form col-md-8 col-sm-12 offset-md-1">
          <tws-validated-text-input-group
            id="password"
            v-model="changePasswordInformation.password"
            :validator="$v.changePasswordInformation.password"
            :validation-messages="validationMessages.password"
            input-type="password"
            :label="$t('reset-password.new-password')" />
          <tws-validated-text-input-group
            id="repeatedPassword"
            v-model="changePasswordInformation.repeatedPassword"
            :validator="$v.changePasswordInformation.repeatedPassword"
            :validation-messages="validationMessages.repeatedPassword"
            input-type="password"
            :label="$t('reset-password.repeat-new-password')"
            @keyup.native.enter="changePassword" />
          <div
            v-for="(message, index) in messageKeys"
            :key="index">
            <InfraPageAlert type="danger">
              {{ $t(message) }}
            </InfraPageAlert>
          </div>
          <div class="action-container">
            <button
              type="button"
              class="button button--primary"
              @click="changePassword">
              {{ $t("reset-password.update") }}
            </button>
            <LocalisedLink
              :to="loginPath"
              class="link">
              {{ $t("common.cancel") }}
            </LocalisedLink>
          </div>
        </form>
      </template>

      <template v-if="!isLoading && isPasswordResetKeyValid && passwordChanged">
        <div class="no-gutters">
          <InfraPageAlert type="success">
            {{ $t("reset-password.success") }}
          </InfraPageAlert>
        </div>
        <div class="action-container">
          <tws-link-button-primary :href="loginPath">
            {{ $t("login.submit") }}
          </tws-link-button-primary>
        </div>
      </template>

      <template
        v-if="!isLoading && !isPasswordResetKeyValid && !passwordChanged">
        <p>
          {{ $t("reset-password.link-expired") }}
        </p>
      </template>

      <template v-if="isLoading">
        {{ $t("common.loading") }}
      </template>
    </div>
  </reset-password-container>
</template>
<script>
import { mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  maxLength,
  minLength,
  required,
  sameAs
} from 'vuelidate/lib/validators'

import {
  invalidCharacter,
  lowercase,
  specialChar,
  twoDigits,
  uppercase
} from '@/common/validation-rules.js'

import {
  InfraPageAlert,
  TwsLinkButtonPrimary,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

import ResetPasswordContainer from '@/components/common/reset-password-container.vue'

export default {
  components: {
    InfraPageAlert,
    TwsValidatedTextInputGroup,
    TwsLinkButtonPrimary,
    ResetPasswordContainer
  },
  mixins: [validationMixin],
  data () {
    return {
      changePasswordInformation: {
        password: '',
        repeatedPassword: ''
      },
      validationMessages: {
        password: {
          required: this.$t('reset-password.validations.password.required'),
          invalidCharacter: this.$t(
            'reset-password.validations.password.invalid-character'
          ),
          twoDigits: this.$t('reset-password.validations.password.two-digits'),
          uppercase: this.$t('reset-password.validations.password.uppercase'),
          lowercase: this.$t('reset-password.validations.password.lowercase'),
          specialChar: this.$t(
            'reset-password.validations.password.special-character'
          ),
          minLength: this.$t('reset-password.validations.password.min-length'),
          maxLength: this.$t('reset-password.validations.password.max-length')
        },
        repeatedPassword: {
          required: this.$t(
            'reset-password.validations.repeat-password.required'
          ),
          sameAsPassword: this.$t(
            'reset-password.validations.repeat-password.same-as-password'
          )
        }
      },
      resetPasswordKey: this.$route.query.resetPasswordKey,
      showExpiredMessage:
        this.$route.query.showExpiredMessage === 'true' ? true : false
    }
  },
  computed: {
    ...mapGetters({
      isLoaded: 'resetPassword/isLoaded',
      isLoading: 'resetPassword/isLoading',
      messageKeys: 'resetPassword/getMessageKeys',
      isPasswordResetKeyValid: 'resetPassword/isPasswordResetKeyValid',
      passwordChanged: 'resetPassword/isPasswordChanged'
    }),
    loginPath () {
      return process.env.VUE_APP_LOGIN_PATH
    }
  },
  mounted () {
    this.$store.dispatch('resetPassword/resetStoreDefaults')
    this.$store.dispatch(
      'resetPassword/validateResetPasswordKey',
      this.resetPasswordKey
    )
  },
  validations: {
    changePasswordInformation: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(14),
        invalidCharacter,
        twoDigits,
        uppercase,
        lowercase,
        specialChar
      },
      repeatedPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    changePassword () {
      this.$v.changePasswordInformation.$touch()
      if (this.$v.changePasswordInformation.$invalid) {
        return
      }
      const params = {
        password: this.changePasswordInformation.password,
        resetPasswordKey: this.resetPasswordKey
      }
      this.$store.dispatch('resetPassword/changeUserPassword', params)
    }
  }
}
</script>

<style lang="scss" scoped>
.action-container {
  display: inline-block;
  padding-top: 15px;

  a {
    text-align: center;
    text-decoration: none;
    margin-left: 15px;
  }
}

#expired-alert {
  margin-bottom: 16px;
}
</style>
