<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('header.menu-items.my-invoices')" />
      <my-documents-company-filter />

      <breadcrumb-tabs-container
        :tabs="tabs"
        :current-uri="currentPath" />
    </InfraBreadcrumbContainer>

    <div id="telia-wholesale-invoices">
      <router-view />
    </div>
  </div>
</template>

<script>
import GenericBanner from '@/components/common/generic-banner.vue'
import MyDocumentsCompanyFilter from '@/components/my-documents-company-filter/my-documents-company-filter.vue'
import BreadcrumbTabsContainer from '@/components/common/breadcrumb-tabs-container/breadcrumb-tabs-container.vue'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_INVOICES } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    InfraBreadcrumbContainer,
    GenericBanner,
    MyDocumentsCompanyFilter,
    BreadcrumbTabsContainer
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_INVOICES,
      tabs: [
        {
          name: 'invoices',
          title: this.$t('invoices.title'),
          link: `${process.env.VUE_APP_MY_INVOICES_PATH}/invoices`,
          public: false
        },
        {
          name: 'invoice-specifications',
          title: this.$t('invoice-specifications.title'),
          link: `${process.env.VUE_APP_MY_INVOICES_PATH}/invoice-specifications`,
          public: false
        }
      ]
    }
  },
  computed: {
    currentPath () {
      return this.$route.path
    }
  }
}
</script>
