import axios from 'axios'

const getProperties = async ({ municipality, district, block, unit }) => {
  const { data } = await axios.get(
    `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/real/estate/search`, {
      params: {
        municipality,
        district,
        block,
        unit
      }
    })

  return data
}

export default { getProperties }
