import { createInstance } from './axios.api'
import { api as companiesApi } from '@/services/lookups.service'

export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE)

const CompanyService = {
  getCompanies: () => {
    return companiesApi.get('/lookups/companies')
  },
  getCompanyByCode: companyCode => {
    return api.post('/companies/view', {
      companyCode
    })
  },
  create: form => {
    return api.post('/companies/create', form)
  },
  update: form => {
    return api.post('/companies/update', form)
  }
}

export default CompanyService
