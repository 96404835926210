import { createInstance } from '@/services/axios.api'

export const api = createInstance(process.env.VUE_APP_NOTIFICATION_SERVICE)
const VARIABLE_EXPRESSION_REGEXP = /\$\{(\S*)\}/g

const CustomHtmlFormService = {
  postForm: data => {
    return api.post('/notification/custom/form', data)
  },

  /**
   * Replaces all variables described as ${fieldName} in the originalString
   * to their corresponding values from fieldValueMap.
   */
  parseExpression: (fieldValueMap, originalString) => {
    if (!originalString) {
      return originalString
    }

    let processedString = originalString
    let group
    while ((group = VARIABLE_EXPRESSION_REGEXP.exec(originalString)) !== null) {
      const fieldVariableExpression = group[0]
      const fieldValue = group[1] in fieldValueMap ? fieldValueMap[group[1]] : ''
      processedString = processedString.replace(fieldVariableExpression, fieldValue)
    }

    return processedString
  }
}

export default CustomHtmlFormService
