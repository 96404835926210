<template>
  <div class="sub-category-column col-lg-3 col-md-4 col-sm-6">
    <LocalisedLink
      class="sub-category"
      :to="path">
      <TeliaCoreIcon
        :name="icon"
        class="sub-category__icon"
        size="30px" />

      <span class="sub-category__title">
        {{ title }}
      </span>
    </LocalisedLink>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.sub-category {
  padding: 20px;
  background-color: $white;
  display: flex;
  align-items: center;

  &:hover,
  &.router-link-active {
    box-shadow: 0px -3px 0px $core-purple inset;
  }

  &-column {
    padding: 1px;
  }

  &__title {
    margin-left: 15px;
    font-size: 22px;
  }

  &__icon {
    width: 30px;
  }
}
</style>
