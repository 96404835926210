import i18n from '@/i18n'
import UserService from '@/services/user.service'

const filterHistory = (history, filters) => {
  if (filters.length === 0) {
    return history
  }

  return history.filter(item => {
    return filters.includes(item.action)
  })
}

export const SWEDISH_HISTORY_ACTION = {
  NEWUSER: i18n.t('account-history.filters.created-user'),
  EDIT: i18n.t('account-history.filters.modified'),
  UNLOCK: i18n.t('account-history.filters.unlock'),
  REMOVE: i18n.t('account-history.filters.deleted'),
  PASSWORD: i18n.t('account-history.filters.password-generated'),
  EMAIL: i18n.t('account-history.filters.email'),
  ERROR: i18n.t('account-history.filters.error')
}

export const AccountHistoryModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    exception: false,
    history: [],
    filteredHistory: [],
    filters: [],
    currentPage: 1
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setException (state, exception) {
      state.exception = exception
    },
    setHistory (state, history) {
      state.history = history
    },
    setFilters (state, filters) {
      state.filters = filters
    },
    setCurrentPage (state, currentPage) {
      state.currentPage = currentPage
    },
    updateFilteredHistory (state) {
      state.filteredHistory = filterHistory(state.history, state.filters)
    }
  },
  actions: {
    fetch ({ commit }, username) {
      commit('setLoading', true)

      return UserService
        .getUserHistory(username)
        .then(response => {
          response.forEach(event => {
            event.action = SWEDISH_HISTORY_ACTION[event.action]
          })

          commit('setHistory', response)
          commit('updateFilteredHistory')
          commit('setLoaded', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    changeFilters ({ commit }, filters) {
      commit('setFilters', filters)
      commit('updateFilteredHistory')
      commit('setCurrentPage', 1)
    },
    changeCurrentPage ({ commit }, page) {
      commit('setCurrentPage', page)
    }
  }
}
