<template>
  <tws-map-legend
    color-palette="orange"
    :items="mapLegend" />
</template>

<script>
import MapLegend from '@/components/map/map-legend'

export default {
  components: {
    'tws-map-legend': MapLegend
  },
  data () {
    return {
      mapLegend: [
        {
          status: 3,
          text: this.$t('map.legend.extreme')
        },
        {
          status: 2,
          text: this.$t('map.legend.escalated')
        },
        {
          status: 1,
          text: this.$t('map.legend.elevated')
        },
        {
          status: 0,
          text: this.$t('map.legend.normal')
        }
      ]
    }
  }
}
</script>
