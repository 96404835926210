<template>
  <div>
    <div class="container">
      <tws-map-service-state-counties-table-mobile
        v-show="!selectedCounty && !showMap"
        :items="serviceState"
        @select="loadCountyInfo" />

      <tws-map
        v-show="!selectedCounty && showMap"
        :items="mapItems"
        color-palette="orange"
        @select="loadCountyInfo" />

      <tws-map-service-state-legend v-show="!selectedCounty && showMap" />

      <div
        v-show="!selectedCounty"
        class="button-container">
        <tws-button-primary
          @click="showMap = !showMap">
          {{ showMap ? $t('common.close') : $t('map.show-map') }}
        </tws-button-primary>
      </div>
    </div>

    <tws-map-service-state-municipalities-table-mobile
      v-if="selectedCounty"
      :county="selectedCounty"
      @close="selectedCounty = null" />
  </div>
</template>

<script>
import Map from '@/components/map/map'
import MapServiceStateCountiesTableMobile from '@/components/map/map-service-state/mobile/map-service-state-counties-table-mobile'
import MapServiceStateMunicipalitiesTableMobile from '@/components/map/map-service-state/mobile/map-service-state-municipalities-table-mobile'
import MapServiceStateLegend from '@/components/map/map-service-state/map-service-state-legend'
import { MapServiceStateContainerMixin } from '@/mixins/map-service-state.mixin'
import { TwsButtonPrimary } from 'tws-vue-components'

export default {
  components: {
    'tws-map': Map,
    'tws-map-service-state-counties-table-mobile': MapServiceStateCountiesTableMobile,
    'tws-map-service-state-municipalities-table-mobile': MapServiceStateMunicipalitiesTableMobile,
    'tws-map-service-state-legend': MapServiceStateLegend,
    TwsButtonPrimary
  },
  mixins: [MapServiceStateContainerMixin],
  data () {
    return {
      showMap: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 40px;
  text-align: center;
}
</style>
