import { getHost } from '@/utils/domain.utils'
import ContentfulWrapper from '@telia/contentful-wrapper'

const client = new ContentfulWrapper({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: '_',
  host: getHost(),
  basePath: process.env.VUE_APP_CONTENTFUL_WRAPPER_BASE_PATH,
  assetsHost:
    getHost() +
    '/.api/infra/contentful-gateway',
  environment: process.env.VUE_APP_CONTENTFUL_WRAPPER_ENVIRONMENT
})

export default {
  async fetchEntries (query) {
    try {
      return await client.getEntries(query)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data from Contentful:', error)
      throw error
    }
  },
  async fetchEntry (id, query) {
    try {
      return await client.getEntry(id, query)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data from Contentful:', error)
      throw error
    }
  }
}
