const resolveServerErrors = serverErrorKey => {
  switch (serverErrorKey) {
    case 'service.unavailable':
      return 'common.errors.service-unavailable'
    case 'field.pattern.password':
      return 'common.errors.password-complexity'
    case 'field.pattern.password.firstname':
      return 'common.errors.password-first-name'
    case 'field.pattern.password.lastname':
      return 'common.errors.password-last-name'
    case 'field.pattern.password.username':
      return 'common.errors.password-username'
    case 'field.pattern.password.used':
      return 'common.errors.password-used'
    case 'username.or.email.not.correct':
      return 'common.errors.username-invalid'
    case 'natfraga.max.response.limit':
      return 'common.errors.natfraga-limit'
    case 'field.system.user.password':
      return 'common.errors.field-password'
    default:
      return ''
  }
}

const MessageKeysService = {
  getAllMessageKeys: response => {
    let serverErrorMessages = []

    if (response) {
      serverErrorMessages = response.data.errors.map(err => {
        return resolveServerErrors(err.messageKey)
      })

      if (response.status === 500 && serverErrorMessages.indexOf(resolveServerErrors('service.unavailable')) === -1) {
        serverErrorMessages.push(resolveServerErrors('service.unavailable'))
      }
    }

    return serverErrorMessages
  },
  getResolvedServerErrors: serverError => {
    return resolveServerErrors(serverError)
  }

}

export default MessageKeysService
