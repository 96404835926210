import UserService from '@/services/user.service.js'

const SWEDISH_STATUS = {
  'SUCCESS': 'Flyttat',
  'FAILURE': 'Fel'
}

export const MassUserTransferModule = {
  namespaced: true,
  state: {
    users: '',
    usernames: '',
    transferring: false,
    transferred: false,
    exception: false,
    transferredCount: 0
  },
  mutations: {
    setUsers (state, users) {
      users.forEach(user => {
        user.status = SWEDISH_STATUS[user.status]
      })
      state.users = users
    },
    setUsernames (state, usernames) {
      state.usernames = usernames
    },
    setTransferring (state, transferring) {
      state.transferring = transferring
    },
    setTransferred (state, transferred) {
      state.transferred = transferred
    },
    setException (state, exception) {
      state.exception = exception
    },
    setTransferredCount (state, transferredCount) {
      state.transferredCount = transferredCount
    }
  },
  getters: {
    getUsernamesAsArray (state) {
      return state.usernames.trim().length > 0 ? state.usernames.trim().split(/[,;\r?\n\s]/) : []
    },
    getUsers (state) {
      return state.users
    }
  },
  actions: {
    transferUsers ({ commit, getters }, { fromCompany, toCompany }) {
      commit('setTransferring', true)
      commit('setTransferred', false)
      commit('setException', false)
      commit('setTransferredCount', 0)

      return UserService
        .transferUsers({
          fromCompanyCode: fromCompany,
          toCompanyCode: toCompany,
          usernames: getters['getUsernamesAsArray']
        })
        .then(users => {
          commit('setUsers', users)
          commit('setUsernames', users.map(user => user.username).join())
          commit('setTransferredCount', users.filter(user => user.status === SWEDISH_STATUS.SUCCESS).length)
          commit('setTransferred', true)
        })
        .catch(() => {
          commit('setException', true)
        })
        .finally(() => {
          commit('setTransferring', false)
        })
    },
    usernamesEntered ({ commit }, usernames) {
      commit('setUsernames', usernames)
    }
  }
}
