<template>
  <div class="account-information-container">
    <tws-user-edit-container
      :editing="editing"
      :validator="$v.editableInformation"
      :updating="updating"
      :title="$t('account-information.heading')"
      @toggleEdit="onEditToggle"
      @confirmEdit="onEditConfirm">
      <tws-account-information-display
        v-if="!editing"
        :information="displayedInformation" />

      <tws-account-information-edit
        v-else
        :content="content"
        :information="editableInformation"
        :validator="$v.editableInformation"
        :creation-mode="false"
        :username-contains-sp-id="usernameContainsSpId"
        @informationChanged="onInformationChange" />
    </tws-user-edit-container>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapGetters
} from 'vuex'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import UserEditContainer from '../common/user-edit-container.vue'
import AccountInformationEdit from '../account-information/account-information-edit.vue'

import AccountInformationDisplay from '../account-information/account-information-display.vue'

export default {
  components: {
    'tws-user-edit-container': UserEditContainer,
    'tws-account-information-edit': AccountInformationEdit,
    'tws-account-information-display': AccountInformationDisplay
  },
  mixins: [validationMixin],
  data () {
    return {
      editing: false,
      updating: false,
      content: {
        systemUserToolTip: this.$t('user-creation.tooltip.system-user'),
        responsibleAgreementToolTip: this.$t('user-creation.tooltip.responsible-agreement'),
        reportsToolTip: this.$t('user-creation.tooltip.reports'),
        searchToolsToolTip: this.$t('user-creation.tooltip.search-tools'),
        invoicesToolTip: this.$t('user-creation.tooltip.invoices'),
        specificationsToolTip: this.$t('user-creation.tooltip.specifications'),
        roleComplaintTooltip: this.$t('user-creation.tooltip.role-complaint'),
        roleComplaintPlusTooltip: this.$t('user-creation.tooltip.role-complaint-plus'),
        roleComplaintWithoutAccessTooltip: this.$t('user-creation.tooltip.role-complaint-without-access'),
        readPermissionToolTip: this.$t('user-creation.tooltip.read-permission'),
        requestPrermissionToolTip: this.$t('user-creation.tooltip.request-permission'),
        purchaserEligibilityToolTip: this.$t('user-creation.tooltip.purchaser-eligibility'),
        noDiffrenceToolTip: this.$t('user-creation.tooltip.no-difference'),
        userCreatePermissionTooltip: this.$t('user-creation.tooltip.user-create-permission'),
        usermanagementTooltip: this.$t('user-creation.tooltip.user-management'),
        reportManagementTooltip: this.$t('user-creation.tooltip.report-management'),
        tabAdminTooltip: this.$t('user-creation.tooltip.tab-admin'),
        operatorCustomerServiceTooltip: this.$t('user-creation.tooltip.operator-customer-service'),
        networkDefectAdministratorTooltip: this.$t('user-creation.tooltip.network-defect-administrator'),
        superuserTooltip: this.$t('user-creation.tooltip.superuser'),
        maintenanceTooltip: this.$t('user-creation.tooltip.maintenance'),
        tabPortingInternalTooltip: this.$t('user-creation.tooltip.tab-porting-internal'),
        tabReadOnlyInternalTooltip: this.$t('user-creation.tooltip.tab-read-only-internal'),
        adminTooltip: this.$t('user-creation.tooltip.admin'),
        delegatedAdminTooltip: this.$t('user-creation.tooltip.delegated-admin'),
        masterAdminTooltip: this.$t('user-creation.tooltip.master-admin'),
        companyAdminTooltip: this.$t('user-creation.tooltip.company-admin'),
        companyTransferTooltip: this.$t('user-creation.tooltip.company-transfer'),
        billingAdminTooltip: this.$t('user-creation.tooltip.billing-admin')
      }
    }
  },
  computed: {
    ...mapState('userDetails/accountInformation', [
      'displayedInformation',
      'editableInformation'
    ]),
    ...mapGetters('userDetails', ['usernameContainsSpId'])
  },
  methods: {
    ...mapActions('userDetails/accountInformation', [
      'updateInformation',
      'resetEditableInformation',
      'editableInformationChanged'
    ]),
    onEditToggle () {
      this.editing = !this.editing

      if (!this.editing) {
        this.resetEditableInformation()
      }
    },
    onEditConfirm () {
      this.updating = true

      this.updateInformation().then(() => {
        this.editing = false
      }).finally(() => {
        this.updating = false
      })
    },
    onInformationChange (information) {
      this.editableInformationChanged(information)
    }
  },
  validations: {
    editableInformation: {
      username: {
        required
      },
      loginMethodPassword: {
        required,
        delegatedAdmin: function (value) {
          return !value || !this.editableInformation.roles.DELEGATED_ADMINISTRATOR
        }
      }
    }
  }
}
</script>
./account-information-edit.vue
