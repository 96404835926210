import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_EMAIL_NOTIFICATION_PREFERENCES_API)

const EmailNotificationPreferencesService = {
  get: () => {
    return api.get('/')
  },
  save: preferences => {
    return api.post('/', preferences)
  }
}

export default EmailNotificationPreferencesService
