import { createInstance } from './axios.api'

export const serviceStateApi = createInstance(process.env.VUE_APP_SERVICE_STATE_API)
export const networkInformationApi = createInstance(process.env.VUE_APP_NETWORK_INFORMATION_API)
export const cableFaultsApi = createInstance(process.env.VUE_APP_CABLE_FAULTS_API)

const NetworkInformationService = {
  getServiceState: serviceStateApi.get,
  getDeliveryTime: () => {
    return networkInformationApi.get('/delivery-time')
  },
  getCableFaults: cableFaultsApi.get
}

export default NetworkInformationService
