import axios from 'axios'
import { FORM_DESCRIPTION_PROPERTIES } from '@/apps/fault-reporting/constants/fault-report-form.constants'

const BASE_CONFIG = {
  baseURL: process.env.VUE_APP_TELIA_NOW_INTEGRATION,
  headers: {
    'Accept': 'application/json'
  },
  responseType: 'json'
}

const FORM_DATA_CONFIG = {
  ...BASE_CONFIG,
  headers: {
    ...BASE_CONFIG.headers,
    ...{ 'Content-Type': 'multipart/form-data' }
  }
}

const JSON_CONFIG = {
  ...BASE_CONFIG,
  headers: {
    ...BASE_CONFIG.headers,
    ...{ 'Content-Type': 'application/json' }
  }
}

export const sendForm = async (request) => {
  const formData = new FormData()
  Object.keys(request).forEach(key => {
    if (key !== FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS) {
      formData.append(key, request[key])
    }
  })

  const attachments = request.attachments

  attachments && attachments.forEach(attachment => {
    formData.append(FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS, attachment)
  })

  const { data } = await axios.post('/case/create', formData, FORM_DATA_CONFIG)
  return data
}

export const fetchCaseList = async (payload) => {
  const { data } = await axios.post('/case/list', payload, JSON_CONFIG)
  return data
}

export const fetchCaseDetails = async (caseId) => {
  const { data } = await axios.get(`/case/${caseId}`, JSON_CONFIG)
  return data
}

export const postComment = async (caseId, request) => {
  const { data } = await axios.patch(`/case/${caseId}/note`, request, JSON_CONFIG)
  return data
}

export const putAttachments = async (caseId, attachmentsReq) => {
  const formData = new FormData()

  attachmentsReq.forEach(attachment => {
    formData.append(FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS, attachment)
  })

  const { data } = await axios.patch(`/case/${caseId}/attachments`, formData, FORM_DATA_CONFIG)
  return data
}

export const removeAttachment = async (caseId, attachmentIndex) => {

  const { data } = await axios.delete(`/case/${caseId}/attachments/${attachmentIndex}`, FORM_DATA_CONFIG)
  return data
}
