<template>
  <div class="container reset-password-container">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-12">
            <h1
              v-once
              class="heading-l--black">
              {{ heading }}
            </h1>
          </div>
        </div>
        <div class="row description">
          <div
            v-once
            class="col-12">
            <p>{{ description }}</p>
          </div>
        </div>
        <div class="row">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password-container {
  padding-top: 6rem;
}

.description p {
  text-align: justify;
  margin-top: 0px;
}
</style>
