import axios from 'axios'

const fetchPublicPublished = async product => {
  const url = `${process.env.VUE_APP_DOCUMENT_SERVICE_CTF}/document/public/published`

  const { data } = await axios.get(url, {
    params: { product }
  })
  return data
}

const fetchPublicArchived = async product => {
  const url = `${process.env.VUE_APP_DOCUMENT_SERVICE_CTF}/document/public/archived`

  const { data } = await axios.get(url, {
    params: { product }
  })
  return data
}

const fetchLoggedInPublished = async product => {
  const url = `${process.env.VUE_APP_DOCUMENT_SERVICE_CTF}/document/logged-in/published`

  const { data } = await axios.get(url, {
    params: { product }
  })
  return data
}

const fetchLoggedInArchived = async product => {
  const url = `${process.env.VUE_APP_DOCUMENT_SERVICE_CTF}/document/logged-in/archived`

  const { data } = await axios.get(url, {
    params: { product }
  })
  return data
}

export default {
  fetchPublicPublished,
  fetchPublicArchived,
  fetchLoggedInPublished,
  fetchLoggedInArchived
}
