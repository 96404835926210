<template>
  <table>
    <thead>
      <tr>
        <th class="county-title">
          {{ $t('map.table-headers.area') }}
        </th>
        <th>{{ $t('map.table-headers.harvest') }}</th>
        <th>{{ $t('map.table-headers.inflow') }}</th>
        <th>{{ $t('map.table-headers.backlog') }}</th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="index">
        <td class="county">
          <span
            class="clickable"
            @click="$emit('select', item.countyCode)">
            {{ item.name }}
          </span>
        </td>
        <td>
          <div
            class="box"
            :class="{'box--green' : item.fixRate > 100}" />
        </td>
        <td>
          <div
            class="box"
            :class="[{'box--1' : item.inflow > 100}, {'box--2' : item.inflow > 200}]" />
        </td>
        <td>
          <div
            class="box"
            :class="{'box--2' : item.backlog > 200}" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table';
@import '@/components/map/scss/map-colors';

.clickable {
  cursor: pointer;

  &:hover {
    color: $core-purple;
  }
}

.county-title {
  text-align: left;
}

.county {
  width: 40%;
  text-align: right;
}

.box {
  height: 28px;
  width: 78px;
  background-color: $medium-grey;

  @each $status, $color in $map-colors-orange-alt {
    &--#{$status} {
      background-color: $color;
    }
  }

  &--green {
    background-color: #00CC66;
  }
}

table {
  border-collapse: collapse;

  tr, td {
    border: none;
  }

  td {
    padding: 3px 12px;
  }

  th {
    padding: 6px 0;
  }
}
</style>
