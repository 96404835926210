import i18n from '@/i18n'

export const networkInfoTabs = [
  {
    path: '/natinformation/natforandringar',
    title: i18n.t('network-information.tabs.network-changes')
  },
  {
    path: '/natinformation/Leveranstider-Fiber',
    title: i18n.t('network-information.tabs.delivery-time')
  },
  {
    path: '/natinformation/servicelaget-koppar',
    title: i18n.t('network-information.tabs.copper-status')
  },
  {
    path: '/natinformation/kopparnatsfel',
    title: i18n.t('network-information.tabs.copper-faults')
  }
]
