<template>
  <div
    v-if="link || path"
    class="category-column col-xl-2 col-lg-3 col-md-3 col-sm-4">
    <a
      v-if="link"
      :href="link"
      :target="isExternalLink() ? '_blank' : undefined"
      class="category">

      <TeliaCoreIcon
        :name="icon"
        class="category__icon"
        size="52px" />

      <div class="category__title preamble">
        {{ title }}
      </div>
    </a>

    <LocalisedLink
      v-else
      :to="path"
      class="category">
      <TeliaCoreIcon
        :name="icon"
        class="category__icon"
        size="52px" />

      <div class="category__title preamble">
        {{ title }}
      </div>
    </LocalisedLink>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    }
  },
  methods: {
    isExternalLink () {
      return !!this.link && this.link.startsWith('http')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.category {
  display: block;

  @media (max-width: $breakpoint-sm) {
    display: flex;
    align-items: center;
  }

  &-column {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__title {
    @media (max-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }

  &__icon {
    margin-bottom: 20px;

    @media (max-width: $breakpoint-sm) {
      max-height: 24px;
      max-width: 24px;
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
}
</style>
