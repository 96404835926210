<template>
  <div class="login-sms">
    <tws-validated-text-input-group
      id="oneTimeCodeUsername"
      v-model="oneTimeCodeUsernameProxy"
      :validator="$v.oneTimeCodeUsername"
      :validation-messages="validationMessages.oneTimeCodeUsername"
      :label="$t('login.username')"
      @keyup.native.enter="onOneTimeCodeSend" />

    <tws-button-primary
      :disabled="exception || !loaded"
      @click="onOneTimeCodeSend">
      {{ $t('login.send-code') }}
    </tws-button-primary>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  required,
  maxLength
} from 'vuelidate/lib/validators'

import {
  TwsButtonPrimary,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    TwsButtonPrimary,
    TwsValidatedTextInputGroup
  },
  mixins: [validationMixin],
  data () {
    return {
      validationMessages: {
        oneTimeCodeUsername: {
          required: this.$t('login.validations.username.required'),
          maxLength: this.$t('login.validations.username.length')
        }
      }
    }
  },
  computed: {
    ...mapState('login', [
      'oneTimeCodeUsername',
      'loaded',
      'exception'
    ]),
    oneTimeCodeUsernameProxy: {
      get () {
        return this.oneTimeCodeUsername
      },
      set (value) {
        this.oneTimeCodeUsernameEntered(value)
      }
    }
  },
  mounted () {
    this.fetchEamAuthenticationUrl()
  },
  methods: {
    ...mapActions('login', [
      'oneTimeCodeUsernameEntered',
      'sendOneTimeCode',
      'fetchEamAuthenticationUrl'
    ]),
    onOneTimeCodeSend () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.sendOneTimeCode()
      }
    }
  },
  validations () {
    return {
      oneTimeCodeUsername: {
        required,
        maxLength: maxLength(50)
      }
    }
  }
}
</script>
