import Cookies from 'js-cookie'

const firstName = () => Cookies.get('fname')
const lastName = () => Cookies.get('ename')
const email = () => Cookies.get('email')
const username = () => Cookies.get('username')
const companyCode = () => Cookies.get('epinfo').split('-')[0]

export const isLoggedIn = () => {
  const firstName = Cookies.get('fname')
  const lastName = Cookies.get('ename')
  const company = Cookies.get('companyname')
  const username = Cookies.get('username')
  const roles = Cookies.get('epinfo')

  return !!firstName && !!lastName && !!company && !!username && !!roles
}

export const getUsername = () => Cookies.get('username')

export default {
  companyCode,
  firstName,
  lastName,
  email,
  username
}
