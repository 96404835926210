import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_PRODUCT_SEARCH_URL)

const AreaSearchService = {
  getAreaSearchChoices: () => {
    return api.get('/area/search/populate')
  },
  postAreaSerchOrder: request => {
    return api.post('/area/search/order', request)
  }
}

export default AreaSearchService
