<template>
  <button
    type="button"
    class="table-tab"
    :class="{'table-tab--active' : active}"
    @click="$emit('click')">
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.table-tab {
  padding: 15px 20px;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  border: 2px solid;

  background-color: transparent;
  border-color: $medium-grey;
  color: $black;

  &--active {
    background-color: $core-purple;
    border-color: $core-purple;
    color: $white;
  }
}

</style>
