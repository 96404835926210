<template>
  <div class="sub-menu-item-2">
    <LocalisedLink
      :to="item.link"
      class="sub-menu-item-2__link">
      {{ title }}
      <tws-new-window-icon v-if="item.external" />
    </LocalisedLink>
  </div>
</template>

<script>
import { getTranslationValue } from '@/utils/translation.utils'
import TwsNewWindowIcon from '@/img/svg/new-window.svg'

export default {
  components: {
    TwsNewWindowIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return getTranslationValue(this.item.title)
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-menu-item-2 {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  &__link {
    display: block;
  }
}
</style>
