<template>
  <table class="map-table">
    <thead>
      <tr>
        <th class="county">
          {{ $t('map.table-headers.county') }}
        </th>
        <th>{{ $t('map.telia-service-mode') }}</th>
        <th class="faults">
          {{ $t('map.customer-reported-errors') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="index">
        <td class="county">
          <span
            class="clickable"
            @click="$emit('select', getCountyCodeByName(item.county))">
            {{ item.county }}
          </span>
        </td>
        <td :class="[ getStatusCodeByName(item.slaText) === 0 ? 'sla-normal' : 'sla-higher' ]">
          <tws-map-status-icon
            color-palette="orange"
            :status="getStatusCodeByName(item.slaText)" />
          {{ item.slaText }}
        </td>
        <td class="faults">
          {{ item.faults }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import MapStatusIcon from '@/components/map/map-status-icon'
import { MapServiceStateCountiesTableMixin } from '@/mixins/map-service-state.mixin'

export default {
  components: {
    'tws-map-status-icon': MapStatusIcon
  },
  mixins: [MapServiceStateCountiesTableMixin]
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table';

.faults {
  width: 25%;
  text-align: right;
}

.county {
  width: 40%;
}

.sla-normal {
  color: $dark-grey;
}

.sla-higher {
  font-weight: bold;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: $core-purple;
  }
}

</style>
