<template>
  <div class="specification-authorization">
    <div class="specification-authorization__text">
      {{ $t('specification.unauthorized') }}
    </div>

    <div class="specification-authorization__actions">
      <TwsLinkButtonPrimary href="/login">
        {{ $t('specification.log-in') }}
      </TwsLinkButtonPrimary>

      <TwsLinkButtonSecondary href="/kontakt">
        {{ $t('specification.contact-us') }}
      </TwsLinkButtonSecondary>
    </div>
  </div>
</template>

<script>
import {
  TwsLinkButtonPrimary, TwsLinkButtonSecondary
} from 'tws-vue-components'

export default {
  components: {
    TwsLinkButtonPrimary,
    TwsLinkButtonSecondary
  }
}
</script>

<style lang="scss" scoped>
.specification-authorization {
  &__text {
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    justify-content: center;

    a:first-of-type {
      margin-right: 20px;
    }
  }
}
</style>
