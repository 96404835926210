<template>
  <div class="container">
    <tws-map-generic-page-alerts
      :loading="loading"
      :exception="exception" />

    <div
      v-if="!loading && !exception"
      class="row">
      <div class="col-lg-6 col-md-12">
        <h1 class="heading-l--black">
          {{ $t('map.service-mode') }}
        </h1>
        <tws-map-service-state-counties-table
          :items="serviceState"
          @select="loadCountyInfo" />
      </div>

      <div class="col-lg-6 col-md-12">
        <div
          v-if="!selectedCounty"
          class="row">
          <div class="offset-lg-2 col-md-10 col-sm-12">
            <tws-map
              :items="mapItems"
              color-palette="orange"
              @select="loadCountyInfo" />

            <h4>{{ $t('map.legend-label') }}</h4>

            <tws-map-service-state-legend />
          </div>
        </div>

        <div
          v-else
          class="row">
          <div class="col">
            <tws-map-service-state-municipalities-table
              :county="selectedCounty"
              @close="selectedCounty = null" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '@/components/map/map'
import MapGenericPageAlerts from '@/components/map/map-generic-page-alerts'
import MapServiceStateCountiesTable from '@/components/map/map-service-state/desktop/map-service-state-counties-table-desktop'
import MapServiceStateMunicipalitiesTable from '@/components/map/map-service-state/desktop/map-service-state-municipalities-table-desktop'
import MapServiceStateLegend from '@/components/map/map-service-state/map-service-state-legend'
import { MapServiceStateContainerMixin } from '@/mixins/map-service-state.mixin'

export default {
  components: {
    'tws-map': Map,
    'tws-map-service-state-counties-table': MapServiceStateCountiesTable,
    'tws-map-service-state-municipalities-table': MapServiceStateMunicipalitiesTable,
    'tws-map-service-state-legend': MapServiceStateLegend,
    'tws-map-generic-page-alerts': MapGenericPageAlerts
  },
  mixins: [MapServiceStateContainerMixin]
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
}

.heading-l {
  margin-bottom: 40px;
}

h4 {
  margin-bottom: 10px;
}
</style>
