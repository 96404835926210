<template>
  <div class="personal-information-display">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.first-name') }}
        </label>

        <label>
          {{ information.firstName }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.last-name') }}
        </label>

        <label>
          {{ information.lastName }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.company') }}
        </label>

        <label class="word-break">
          {{ information.company ? information.company.name : '' }}
        </label>
      </div>

      <div
        v-if="information.position"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.position') }}
        </label>

        <label>
          {{ information.position }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.phone') }}
        </label>

        <label>
          {{ information.phone }}
        </label>
      </div>

      <div
        v-if="information.mobilePhone"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.mobile-phone') }}
        </label>

        <label>
          {{ information.mobilePhone }}
        </label>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.email') }}
        </label>

        <label class="word-break">
          {{ information.email }}
        </label>
      </div>

      <div
        v-if="information.country"
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.country') }}
        </label>

        <label>
          {{ getCountryName(information.country) }}
        </label>
      </div>

      <div
        v-if="information.comments"
        class="col-12">
        <label class="label font-weight-bold">
          {{ $t('personal-information.comment') }}
        </label>

        <label>
          {{ information.comments }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: {
      type: Object,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  },
  methods: {
    getCountryName (code) {
      if (!code) {
        return ''
      }

      const country = this.countries.find(country => country.code === code)

      return country ? country.name : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

[class*="col-"]:nth-child(n+5) {
  margin-top: 45px;
}

@media (max-width: $breakpoint-lg) {
  [class*="col-"]:nth-child(n+4) {
    margin-top: 45px;
  }
}

@media (max-width: $breakpoint-md) {
  [class*="col-"]:nth-child(n+3) {
    margin-top: 45px;
  }
}

@media (max-width: $breakpoint-sm) {
  [class*="col-"]:not(:first-child) {
    margin-top: 20px;
  }
}
</style>
