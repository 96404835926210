<template>
  <div class="user-details-banner">
    <div
      v-if="informationLoading || informationException"
      class="row">
      <div class="col-12">
        <InfraPageAlert
          v-if="informationLoading"
          type="info">
          {{ $t('user-details.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="informationException"
          type="danger">
          {{ $t('user-details.loading-failed') }}
        </InfraPageAlert>
      </div>
    </div>

    <template v-if="informationLoaded">
      <div class="row">
        <div class="col-12">
          <h1 class="heading-l--black title-500">
            {{ getFullName }}

            <tws-lock-icon
              v-show="isLocked"
              :view-box.camel="'0 0 24 24'"
              height="30"
              width="30" />
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="user-details-banner__username">
            {{ information.username }}
          </div>
        </div>
      </div>

      <div class="row user-details-banner__actions">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <tws-action-button
            :disabled="reseting"
            @click="toggleResetDialog">
            <TeliaCoreIcon
              class="search-icon"
              name="secured_thin"
              size="3rem" />
            <span>{{ $t('user-details.reset') }}</span>
          </tws-action-button>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <tws-action-button
            :disabled="deleting"
            @click="toggleDeletionDialog">
            <TeliaCoreIcon
              name="delete_close_thin"
              size="3rem" />
            <span>{{ $t('user-details.delete') }}</span>
          </tws-action-button>
        </div>

        <div
          v-show="isLocked"
          class="col-lg-4 col-md-6">
          <tws-action-button
            :disabled="unlocking"
            class="user-details-banner__actions--unlock"
            @click="onUserUnlock">
            <tws-unlock-icon />
            <span>{{ $t('user-details.unlock') }}</span>
          </tws-action-button>
        </div>
      </div>

      <div
        v-show="reset || resetException || deletionException || unlockException"
        class="row user-details-banner__action-errors">
        <div class="col-12">
          <InfraPageAlert
            v-show="reset"
            type="success">
            {{ $t('user-details.success') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-show="resetException"
            type="danger">
            {{ $t('user-details.errors.reset') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-show="deletionException"
            type="danger">
            {{ $t('user-details.errors.delete') }}
          </InfraPageAlert>

          <InfraPageAlert
            v-show="unlockException"
            type="danger">
            {{ $t('user-details.errors.unlock') }}
          </InfraPageAlert>
        </div>
      </div>

      <confirmation-dialog
        v-show="showResetDialog"
        :heading="$t('user-details.reset-dialog-header')"
        :confirmation-button-text="$t('user-details.reset-dialog-confirmation-button')"
        :cancel-button-text="$t('user-details.reset-dialog-cancel-button')"
        @confirm="onPasswordResetConfirm"
        @cancel="toggleResetDialog">
        <template #body>
          {{ resetDialogMessage }}
        </template>
      </confirmation-dialog>

      <confirmation-dialog
        v-show="showDeletionDialog"
        :heading="$t('user-details.deletion-dialog-header')"
        :confirmation-button-text="$t('user-details.deletion-dialog-confirmation-button')"
        :cancel-button-text="$t('user-details.deletion-dialog-cancel-button')"
        @confirm="onDeletionConfirm"
        @cancel="toggleDeletionDialog">
        <template #body>
          {{ deletionDialogMessage }}
        </template>
      </confirmation-dialog>
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import {
  TwsActionButton,
  InfraPageAlert
} from 'tws-vue-components'

import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'

export default {
  components: {
    TwsActionButton,
    InfraPageAlert,
    ConfirmationDialog
  },
  data () {
    return {
      showDeletionDialog: false,
      showResetDialog: false
    }
  },
  computed: {
    ...mapState('userDetails', [
      'informationLoading',
      'informationLoaded',
      'informationException',
      'information'
    ]),
    ...mapState('userDetails/passwordReset', [
      'reseting',
      'reset',
      'resetException'
    ]),
    ...mapState('userDetails/deletion', [
      'deleting',
      'deletionException'
    ]),
    ...mapState('userDetails/unlock', [
      'unlocking',
      'unlocked',
      'unlockException'
    ]),
    ...mapGetters('userDetails', [
      'getFullName',
      'isLocked'
    ]),
    deletionDialogMessage () {
      return this.$t('user-details.deletion-dialog-message').replace('{username}', this.information.username)
    },
    resetDialogMessage () {
      return this.$t('user-details.reset-dialog-message').replace('{username}', this.information.username)
    }
  },
  methods: {
    ...mapActions({
      unlockUser: 'userDetails/unlock/unlockUser',
      deleteUser: 'userDetails/deletion/deleteUser',
      resetPassword: 'userDetails/passwordReset/resetPassword'
    }),
    toggleDeletionDialog () {
      this.showDeletionDialog = !this.showDeletionDialog
    },
    toggleResetDialog () {
      this.showResetDialog = !this.showResetDialog
    },
    onDeletionConfirm () {
      this.deleteUser(this.information.username)
      this.toggleDeletionDialog()
    },
    onPasswordResetConfirm () {
      this.resetPassword({
        username: this.information.username,
        email: this.information.email
      })

      this.toggleResetDialog()
    },
    onUserUnlock () {
      this.unlockUser(this.information.username)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.user-details-banner {
  &__username {
    font-size: 22px;
    color: #757575;
    margin-bottom: 25px;
  }

  &__actions {
    margin-left: -0.5px;
    margin-right: -0.5px;

    [class*="col-"] {
      padding-left: 0.5px;
      padding-right: 0.5px;
    }

    &--unlock {
      background-color: $red;
      color: $white;

      svg path {
        fill: $white;
      }
    }
  }

  &__action-errors {
    margin-top: 10px;
  }
}

@media (max-width: 64em) {
  .user-details-banner {
    &__actions {
      [class*="col-"]:nth-child(n+3) {
        margin-top: 1px;
      }
    }
  }
}

@media (max-width: 56em) {
  .user-details-banner {
    &__actions {
      [class*="col-"]:not(:first-child) {
        margin-top: 1px;
      }
    }
  }
}
</style>
