import CookieUtils from '@/utils/cookie.utils'

const LoginMenuMixin = {
  methods: {
    onUserDashboardClick () {
      this.$router.push({
        name: 'settings',
        params: {
          locale: this.$route.params.locale
        }
      })
    },
    onLogoutClick () {
      CookieUtils.removeCookies()

      this.$router.push({
        name: 'login',
        query: {
          action: 'logout'
        },
        params: {
          locale: this.$route.params.locale
        }
      })

      window.location.reload()
    }
  }
}

export default LoginMenuMixin
