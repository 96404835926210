<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="true"
    :loading="false"
    :use-infra-generic-banner="false"
    :breadcrumb-items="breadcrumbItems"
    :banner="banner">
    <template #breadcrumb>
      <InfraTabs :items="tabs" />
    </template>

    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-lg-10 px-0">
            <div class="row">
              <div class="col">
                <h1 class="heading-m other-news-heading">
                  {{ banner.heading }}
                </h1>
              </div>
            </div>
            <div class="row date-fields-container">
              <div class="col-sm-3">
                <tws-date-picker
                  v-model="dateFrom"
                  :label="$t('common.date-from')"
                  @input="loadNews" />
              </div>
              <div class="col-sm-3">
                <tws-date-picker
                  v-model="dateTo"
                  :label="$t('common.date-to')"
                  @input="loadNews" />
              </div>
            </div>
            <tws-loading-information
              :loading="loading"
              :exception="exception"
              :loading-text="$t('network-information.other-news.loading')"
              :exception-text="$t('network-information.other-news.loading-failed')" />
            <tws-dashboard-informational-news
              :news="news"
              :link="newsViewPage" />
            <tws-pagination
              :current-page="currentPage"
              :extra-page-count="extraPageCount"
              :data-count="totalCount"
              :item-count-on-page="itemCountOnPage"
              @onPageClick="onPageClick" />
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import InformationalNews from '@/views/login-landing/dashboard/dashboard-informational-news'
import CommonPageView from '@/views/CommonPageView'
import {
  TwsDatePicker,
  TwsPagination,
  TwsLoadingInformation,
  InfraTabs
} from 'tws-vue-components'
import i18n from '@/i18n'
import { networkInfoTabs } from '@/utils/network-info.utils'
import { BREADCRUMBS_OTHER_NEWS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    'tws-dashboard-informational-news': InformationalNews,
    CommonPageView,
    TwsDatePicker,
    TwsPagination,
    TwsLoadingInformation,
    InfraTabs
  },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      currentPage: 1,
      extraPageCount: 3,
      itemCountOnPage: 10,
      breadcrumbItems: BREADCRUMBS_OTHER_NEWS,
      banner: {
        heading: i18n.t('news.breadcrumbs.other-news'),
        description: i18n.t('network-information.other-news.description')
      },
      newsViewPage: '/news-archive/news-page',
      newsType: 'NETWORK_CHANGES',
      tabs: networkInfoTabs
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'eamCookies/isLoggedIn'
    }),
    ...mapState('news', [
      'loading',
      'exception',
      'news',
      'totalCount'
    ])
  },
  mounted () {
    this.loadNews()
  },
  methods: {
    ...mapActions('news', [
      'loadAllNews',
      'loadPublicNews'
    ]),
    loadNews () {
      const form = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        size: this.itemCountOnPage,
        newsType: this.newsType
      }

      this.loggedIn ? this.loadAllNews(form) : this.loadPublicNews(form)
    },
    onPageClick (page) {
      this.currentPage = page
      this.loadNews()
    }
  }
}
</script>
<style scoped lang="scss">
@import "tws-core-atoms/variables.scss";

.other-news-heading {
  font-family: $teliaSans;
  font-weight: bold;
  margin: 70px 0 35px;
}

.other-news-lead-paragraph {
  margin-bottom: 35px;
}

</style>
