<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="true"
    :loading="false"
    :breadcrumb-items="breadcrumbItems"
    :banner="banner">
    <template #breadcrumb>
      <InfraTabs :items="tabs" />
    </template>
    <template #content>
      <MapServiceStateContainerMobile class="display-mobile" />

      <MapServiceStateContainerDesktop
        class="display-desktop"
        map-text-information="" />
    </template>
  </CommonPageView>
</template>

<script>
import { InfraTabs } from 'tws-vue-components'
import CommonPageView from '@/views/CommonPageView'
import i18n from '@/i18n'
import { networkInfoTabs } from '@/utils/network-info.utils'
import MapServiceStateContainerDesktop
  from '@/components/map/map-service-state/desktop/map-service-state-container-desktop'
import MapServiceStateContainerMobile
  from '@/components/map/map-service-state/mobile/map-service-state-container-mobile'
import { BREADCRUMBS_COPPER_SERVICE_STATUS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    InfraTabs,
    CommonPageView,
    MapServiceStateContainerDesktop,
    MapServiceStateContainerMobile
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_COPPER_SERVICE_STATUS,
      banner: {
        heading: i18n.t('network-information.tabs.copper-status'),
        description: i18n.t('network-information.copper-status.description')
      },
      tabs: networkInfoTabs
    }
  }
}
</script>
