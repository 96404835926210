<template>
  <tws-login-form
    :validator="$v"
    @submitted="isLoading = true">
    <input
      type="hidden"
      name="type"
      value="BASIC">

    <tws-validated-text-input-group
      id="username"
      v-model="usernameProxy"
      :validator="$v.username"
      :validation-messages="validationMessages.username"
      :label="$t('login.username')" />

    <tws-validated-text-input-group
      id="password"
      v-model="passwordProxy"
      :validator="$v.password"
      :validation-messages="validationMessages.password"
      input-type="password"
      :label="$t('login.password')" />

    <tws-button-primary
      type="submit"
      :disabled="isLoading"
      :class="{ 'button-with-loader': isLoading }">
      {{ $t("login.submit") }}
      <tws-loading-spinner v-show="isLoading" />
    </tws-button-primary>

    <div class="forgot-password-link">
      <LocalisedLink :to="forgotPasswordUrl">
        {{ $t("login.forgot-password") }}
      </LocalisedLink>
    </div>
  </tws-login-form>
</template>

<script>
import {
  mapState, mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  required, maxLength
} from 'vuelidate/lib/validators'

import Form from './login-form'

import {
  TwsButtonPrimary,
  TwsValidatedTextInputGroup,
  TwsLoadingSpinner
} from 'tws-vue-components'

export default {
  components: {
    'tws-login-form': Form,
    TwsButtonPrimary,
    TwsValidatedTextInputGroup,
    TwsLoadingSpinner
  },
  mixins: [validationMixin],
  data () {
    return {
      forgotPasswordUrl: process.env.VUE_APP_REQUEST_RESET_PASSWORD_PATH,
      validationMessages: {
        username: {
          required: this.$t('login.validations.username.required'),
          maxLength: this.$t('login.validations.username.length')
        },
        password: {
          required: this.$t('login.validations.password.required')
        }
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState('login', [
      'username',
      'password'
    ]),
    usernameProxy: {
      get () {
        return this.username
      },
      set (value) {
        this.usernameEntered(value)
      }
    },
    passwordProxy: {
      get () {
        return this.password
      },
      set (value) {
        this.passwordEntered(value)
      }
    }
  },
  methods: {
    ...mapActions('login', [
      'usernameEntered',
      'passwordEntered'
    ])
  },
  validations () {
    return {
      username: {
        required,
        maxLength: maxLength(50)
      },
      password: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
.button-with-loader {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  & div {
    padding-left: 1rem;
  }
}

.forgot-password-link {
  margin-top: 30px;

  a {
    color: $black;
    text-decoration: underline;

    &:hover {
      color: $core-purple;
      text-decoration: none;
    }
  }
}
</style>
