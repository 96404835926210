import axios from 'axios'
import { readFile } from '@/utils/file.utils'

const CONFIG = {
  baseURL: process.env.VUE_APP_SUPPORT_TICKETS_SERVICE,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  },
  responseType: 'json'
}

const buildFileUploadConfig = (contentType) => {
  return {
    baseURL: process.env.VUE_APP_SUPPORT_TICKETS_SERVICE,
    headers: {
      'Content-Type': contentType
    }
  }
}

const getTicketList = async () => {
  const { data } = await axios.get('/tickets', CONFIG)
  return data.contents
}

const findTicketsBySupportId = async (supportId) => {
  const { data } = await axios.get(`/tickets?supportId=${supportId}`, CONFIG)
  return data.contents
}

const findTicketsByCustomerRef = async (customerRef) => {
  const { data } = await axios.get(`/tickets?customerReference=${customerRef}`, CONFIG)
  return data.contents
}

export const findTicketsByQuery = async (query) => {
  const queryProxy = query.trim().replace(/[^\w-]/g, '')
  if (query && query.length > 2) {
    if (query.startsWith('2-')) {
      return findTicketsBySupportId(queryProxy)
    }
    return findTicketsByCustomerRef(queryProxy)
  }

  return []
}

const filterTickets = async (tickets, query) => {
  const queryProxy = query.trim().toLowerCase()
  const subjectPredicate = t => t.subject.toLowerCase().includes(queryProxy)
      || t.customerReference.toLowerCase().includes(queryProxy)
  return tickets.filter(subjectPredicate)
}

const getTicket = async (supportId) => {
  const { data } = await axios.get(`/tickets/${supportId}`, CONFIG)
  return data
}

const createTicket = async (request) => {
  const { data } = await axios.post('/tickets', request, CONFIG)
  return data
}

const checkAuthorization = async () => {
  try {
    await axios.get('/check', CONFIG)
    return true
  } catch {
    return false
  }
}

const activateTicket = async (supportId) => {
  const { data } = await axios.patch(`/tickets/${supportId}`, {}, CONFIG)
  return data
}

const putAttachment = async (supportId, attachment) => {
  try {
    const content = await readFile(attachment)
    const { data } = await axios.put(
      `/tickets/${supportId}/attachments/${attachment.name}`,
      content,
      buildFileUploadConfig(attachment.type)
    )
    return data
  } catch {
    return null
  }
}

const removeAttachment = async (supportId, fileKey) => {
  const { data } = await axios.delete(
    `/tickets/${supportId}/attachments/${fileKey}`,
    CONFIG
  )
  return data
}

const postNote = async (supportId, request) => {
  const { data } = await axios.post(`/tickets/${supportId}/notes`, request, CONFIG)
  return data
}

export default {
  getTicketList,
  getTicket,
  createTicket,
  putAttachment,
  removeAttachment,
  checkAuthorization,
  postNote,
  activateTicket,
  findTicketsByQuery,
  filterTickets
}
