import ResetPasswordService from '@/services/reset-password.service'
import MessageKeysService from '@/services/common/messageKeys.service'

export const ResetPasswordModule = {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    messageKeys: [],
    username: '',
    resetEmailSent: null,
    passwordResetKeyValid: null,
    passwordChanged: null,
    isUserNotFoundErr: false
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setLoaded (state, loaded) {
      state.loaded = loaded
    },
    setMessageKeys (state, keys) {
      state.messageKeys = keys
    },
    setUsername (state, name) {
      state.username = name
    },
    setResetEmailStatus (state, emailSent) {
      state.resetEmailSent = emailSent
    },
    setResetPasswordKeyValidation (state, isKeyValid) {
      state.passwordResetKeyValid = isKeyValid
    },
    setPasswordChangeResponse (state, isPasswordChanged) {
      state.passwordChanged = isPasswordChanged
    },
    setUserNotFoundErr (state, isUserNotFoundErr) {
      state.isUserNotFoundErr = isUserNotFoundErr
    }
  },
  getters: {
    isLoading (state) {
      return state.loading
    },
    isLoaded (state) {
      return state.loaded
    },
    getMessageKeys (state) {
      return state.messageKeys
    },
    getUsername (state) {
      return state.username
    },
    isResetEmailSent (state) {
      return state.resetEmailSent
    },
    isPasswordResetKeyValid (state) {
      return state.passwordResetKeyValid
    },
    isPasswordChanged (state) {
      return state.passwordChanged
    },
    isUserNotFoundErr (state) {
      return state.isUserNotFoundErr
    }
  },
  actions: {
    validateResetPasswordKey ({ commit }, key) {
      commit('setLoading', true)
      return ResetPasswordService.validateResetPasswordKey(key)
        .then(() => {
          commit('setLoaded', true)
          commit('setResetPasswordKeyValidation', true)
        })
        .catch((error) => {
          commit('setLoaded', false)
          commit('setResetPasswordKeyValidation', false)
          commit('setMessageKeys', MessageKeysService.getAllMessageKeys(error.response))
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    changeUserPassword ({ commit }, params) {
      commit('setLoading', true)
      return ResetPasswordService.changePassword(params.resetPasswordKey, params.password)
        .then(() => {
          commit('setLoaded', true)
          commit('setPasswordChangeResponse', true)
        })
        .catch((error) => {
          commit('setLoaded', false)
          commit('setPasswordChangeResponse', false)
          commit('setMessageKeys', MessageKeysService.getAllMessageKeys(error.response))
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    resetUserPassword ({ commit }, params) {
      commit('setLoading', true)
      commit('setUserNotFoundErr', false)

      return ResetPasswordService.resetPassword(params.username, params.email)
        .then(() => {
          commit('setLoaded', true)
          commit('setResetEmailStatus', true)
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            commit('setUserNotFoundErr', true)
          }

          commit('setLoaded', false)
          commit('setResetEmailStatus', false)
          commit('setMessageKeys', MessageKeysService.getAllMessageKeys(error.response))
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    resetStoreDefaults ({ commit }) {
      commit('setLoading', false)
      commit('setLoaded', false)
      commit('setMessageKeys', [])
      commit('setResetEmailStatus', null)
      commit('setResetPasswordKeyValidation', null)
      commit('setPasswordChangeResponse', null)
      commit('setUserNotFoundErr', false)
    }
  }
}
