<template>
  <ProductViewPlaceholder v-if="loading" />

  <div
    v-else
    class="product-view">
    <InfraPageAlert
      v-if="exception"
      type="danger">
      {{ $t('product.loading-failed') }}
    </InfraPageAlert>

    <template v-else>
      <InfraBreadcrumbContainer
        :items="breadcrumbItems"
        :grey-background="true"
        :internal="true">
        <InfraGenericBannerAlt
          v-if="banner"
          :heading="banner.heading"
          :description="banner.description" />

        <InfraTabs :items="tabs" />
      </InfraBreadcrumbContainer>

      <div class="product-content">
        <div
          v-if="showPageOnlySwedishMessage"
          class="container">
          <div class="row">
            <div class="col-md-7 col-sm-12">
              <div class="language-toggle-message">
                <InfraPageAlert
                  type="info">
                  <div>
                    {{ $t('language-toggle.only-swedish') }}
                  </div>
                </InfraPageAlert>
              </div>
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'

import {
  InfraPageAlert,
  InfraBreadcrumbContainer,
  InfraGenericBannerAlt,
  InfraTabs
} from 'tws-vue-components'

import { routes } from '@/apps/products/router/routes'
import { InfraLocaleUtils } from 'tws-vue-components'
import { isLoggedIn } from '@/utils/user.utils'

import { ProductViewPlaceholder } from '@/apps/products/placeholders'
import i18n from '@/i18n'

export default {
  components: {
    InfraPageAlert,
    InfraBreadcrumbContainer,
    InfraGenericBannerAlt,
    InfraTabs,
    ProductViewPlaceholder
  },
  beforeRouteUpdate (to, from, next) {
    if (from.params.product !== to.params.product) {
      this.clearDocuments()
    }

    next()
  },
  computed: {
    ...mapState('Products/ContentModule', [
      'loading',
      'exception',
      'product',
      'category'
    ]),
    banner () {
      return this.product.banner
    },
    isEnglishAvailable () {
      return this.product.isEnglishAvailable
    },
    showPageOnlySwedishMessage () {
      return !this.isEnglishAvailable && this.isGlobalLanguageEN
    },
    isGlobalLanguageEN () {
      return InfraLocaleUtils.getSelectedLocale() === InfraLocaleUtils.LOCALES.EN
    },
    breadcrumbItems () {
      return [
        {
          title: isLoggedIn() ? this.$t('breadcrumbs.dashboard-logged-in.title') : this.$t('breadcrumbs.dashboard.title'),
          path: isLoggedIn() ? '/dashboard' : '/',
          routerLink: true
        },
        {
          title: this.$t('breadcrumbs.products.title')
        },
        {
          title: this.category.title,
          path: `/products/${this.category.path}`,
          routerLink: true
        },
        {
          title: this.product.title
        }
      ]
    },
    tabs () {
      const tabs = this.product.tabs

      if (!tabs) {
        return null
      }

      const params = this.$route.params
      const path = `/products/${params.category}/${params.product}/`

      const items = []

      if (tabs.description) {
        items.push({
          title: tabs.description.title,
          path: path + 'description'
        })
      }

      if (tabs.specifications) {
        items.push({
          title: tabs.specifications.title,
          path: path + 'specifications'
        })
      }

      if (tabs.documents) {
        items.push({
          title: i18n.t('product-documents.heading'),
          path: path + 'documents'
        })
      }

      return items
    }
  },
  watch: {
    async '$route.path' () {
      await this.loadRouteProduct()
    },
    product (product) {
      if (product.isEnglishAvailable) {
        i18n.locale = InfraLocaleUtils.getSelectedLocale()
      } else {
        i18n.locale = InfraLocaleUtils.LOCALES.SV
      }
    }
  },
  beforeDestroy () {
    this.clearDocuments()
  },
  async created () {
    await this.loadRouteProduct()
  },
  methods: {
    ...mapActions('Products/ContentModule', [
      'loadProduct',
      'loadCategory'
    ]),
    ...mapActions('Products/ProductDocumentModule', ['clearDocuments']),
    handleRouting () {
      if (this.product) {
        const tabs = this.product.tabs
        const specifications = tabs.specifications && tabs.specifications.list

        switch (this.$route.name) {
          case routes.DESCRIPTION: {
            if (tabs.description) {
              break
            }

            if (specifications) {
              this.$router.replace({
                name: 'specification',
                params: {
                  specification: specifications[0].path
                }
              })
            } else {
              this.$router.replace({
                name: 'documents'
              })
            }

            break
          }

          case routes.SPECIFICATIONS: {
            if (specifications) {
              this.$router.replace({
                name: 'specification',
                params: {
                  specification: specifications[0].path
                }
              })
            } else if (tabs.description) {
              this.$router.replace({
                name: 'description'
              })
            } else {
              this.$router.replace({
                name: 'documents'
              })
            }

            break
          }

          case routes.SPECIFICATION: {
            if (specifications) {
              const index = specifications.findIndex(item => item.path === this.$route.params.specification)

              if (index === -1) {
                this.$router.replace({
                  name: 'specification',
                  params: {
                    specification: specifications[0].path
                  }
                })
              }
            } else if (tabs.description) {
              this.$router.replace({
                name: 'description'
              })
            } else {
              this.$router.replace({
                name: 'documents'
              })
            }

            break
          }

          case routes.DOCUMENTS: {
            if (tabs.documents) {
              break
            }

            if (tabs.description) {
              this.$router.replace({
                name: 'description'
              })
            } else {
              this.$router.replace({
                name: 'specification',
                params: {
                  specification: specifications[0].path
                }
              })
            }

            break
          }
        }

        if (this.exception) {
          document.title = this.$t('page.error.title')
        } else {
          document.title = this.product.title
        }
      } else {
        this.$router.replace({
          name: 'category'
        })
      }
    },
    async loadRouteProduct () {
      if (!this.category) {
        await this.loadCategory(false)
      }

      await this.loadProduct()

      this.handleRouting()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.product-content {
  padding-top: 50px;
  padding-bottom: 50px;
}

.language-toggle-message {
  margin-bottom: 50px;

  &__toggle {
    width: fit-content;
    color: $core-purple;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
