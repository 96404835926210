<template>
  <div class="municipalities-container">
    <h2 class="heading-m">
      {{ county.county }}
    </h2>

    <tws-close2-icon @click="$emit('close')" />

    <table>
      <thead>
        <tr>
          <th class="municipality">
            {{ $t('map.table-headers.municipality') }}
          </th>
          <th class="sla">
            {{ $t('map.extended-service-time') }}
          </th>
          <th class="faults">
            {{ $t('map.customer-reported-errors') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(municipality, index) in county.municipalities">
          <tr :key="index">
            <td :class="{ bold: municipality === selectedMunicipality}">
              <span
                class="clickable"
                @click="handleMunicipalityClick(municipality)">
                {{ municipality.municipality }}
              </span>
            </td>
            <td class="sla">
              <tws-status-icon
                color-palette="orange"
                :status="municipality.sla" />
              <span class="sla-text">{{ municipality.sla === 0 ? '-' : '+' + municipality.sla }}</span>
            </td>
            <td class="faults">
              {{ municipality.faults === 0 ? '-' : municipality.faults }}
            </td>
          </tr>

          <template v-if="municipality === selectedMunicipality">
            <tr
              :key="index + 'top-spacer'">
              <td class="top-spacer" />
            </tr>

            <tr
              v-for="(site, index2) in municipality.sites"
              :key="index + '-' + index2">
              <td class="site">
                {{ site.site }}
              </td>
              <td class="sla">
                {{ site.sla === 0 ? '-' : '+' + site.sla }}
              </td>
              <td class="faults">
                {{ site.faults === 0 ? '-' : site.faults }}
              </td>
            </tr>

            <tr
              :key="index + 'bottom-spacer'">
              <td class="bottom-spacer" />
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import MapStatusIcon from '@/components/map/map-status-icon'
import { MapServiceStateMunicipalitiesTableMixin } from '@/mixins/map-service-state.mixin'

export default {
  components: {
    'tws-status-icon': MapStatusIcon
  },
  mixins: [MapServiceStateMunicipalitiesTableMixin]
}
</script>

<style lang="scss" scoped>
@import '@/components/map/scss/map-table-municipalities';

.site {
  padding-left: 35px;
}

.sla {
  width: 25%;
  text-align: right;

  &-text {
    display: inline-block;
    width: 30px;
  }
}

.faults {
  width: 25%;
  text-align: right;
}

.bold {
  font-weight: bold;
}

.top-spacer {
  padding-top: 15px;
}

.bottom-spacer {
  padding-bottom: 15px;
}
</style>
