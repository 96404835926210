<template>
  <tws-header-action
    class="header-action--login"
    :href="loginPath">
    <template #icon>
      <TeliaCoreIcon
        class="icon"
        name="end_user_thin"
        size="2.4rem" />
    </template>

    <template #text>
      {{ $t('header.login-button') }}
    </template>
  </tws-header-action>
</template>

<script>
import HeaderAction from '@/components/header/header-action/header-action'

export default {
  components: {
    'tws-header-action': HeaderAction
  },
  data () {
    return {
      loginPath: process.env.VUE_APP_LOGIN_PATH
    }
  }
}
</script>
