import axios from 'axios'

const filterObject = predicate => target => {
  return Object.keys(target).reduce((object, key) => {
    if (predicate('' + target[key])) {
      object[key] = target[key]
    }
    return object
  }, {})
}

export const getAddresses = async (addresses) => {
  const { data } = await axios.post(
    `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/address/search`,
    addresses.map(filterObject(s => s.length > 0))
  )

  return data
}

export const getCoordinates = async (address) => {
  const { data } = await axios.get(
    `${process.env.VUE_APP_PRODUCT_SEARCH_URL}/address/coordinates`, {
      params: {
        ...address
      }
    })

  return data
}
