<template>
  <reset-password-container
    :heading="$t('reset-password.change-password.header')"
    :description="$t('reset-password.change-password.description')">
    <div class="col-12">
      <div
        v-show="alertMessage"
        class="row alert-row">
        <div class="col-md-12">
          <InfraPageAlert
            :type="errorAlert ? 'danger' : 'success'">
            {{ alertMessage }}
          </InfraPageAlert>
        </div>
      </div>

      <form
        v-show="!alertMessage || errorAlert"
        class="form col-md-8 col-sm-12 offset-md-1">
        <tws-validated-text-input-group
          id="currentPassword"
          v-model="changePasswordInformation.currentPassword"
          :validator="$v.changePasswordInformation.currentPassword"
          :validation-messages="validationMessages.currentPassword"
          input-type="password"
          :label="$t('change-password.current-password')" />
        <tws-validated-text-input-group
          id="newPassword"
          v-model="changePasswordInformation.newPassword"
          :validator="$v.changePasswordInformation.newPassword"
          :validation-messages="validationMessages.newPassword"
          input-type="password"
          :label="$t('change-password.new-password')" />
        <tws-validated-text-input-group
          id="newPasswordRepeat"
          v-model="changePasswordInformation.newPasswordRepeat"
          :validator="$v.changePasswordInformation.newPasswordRepeat"
          :validation-messages="validationMessages.newPasswordRepeat"
          input-type="password"
          :label="$t('change-password.new-password-repeat')"
          @keyup.native.enter="changePassword" />
        <div class="row">
          <div class="col">
            <button
              type="button"
              class="button button--primary"
              @click="changePassword">
              {{ $t('change-password.submit') }}
            </button>
          </div>
          <div class="col d-flex align-items-center change-password-cancel-button">
            <tws-nav-link
              v-once
              :href="dashboardLink()">
              {{ $t('common.cancel') }}
            </tws-nav-link>
          </div>
        </div>
      </form>
    </div>
  </reset-password-container>
</template>

<script>
import { validationMixin } from 'vuelidate'

import {
  maxLength,
  minLength,
  required,
  sameAs
} from 'vuelidate/lib/validators'

import {
  invalidCharacter,
  lowercase,
  specialChar,
  twoDigits,
  uppercase
} from '@/common/validation-rules.js'

import ResetPasswordContainer from '@/components/common/reset-password-container.vue'
import ResetPasswordService from '@/services/reset-password.service'

import {
  InfraPageAlert,
  TwsNavLink,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsValidatedTextInputGroup,
    TwsNavLink,
    ResetPasswordContainer
  },
  mixins: [validationMixin],
  data () {
    return {
      errors: Object,
      alertMessage: '',
      errorAlert: false,
      changePasswordInformation: {
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      validationMessages: {
        currentPassword: {
          required: this.$t('change-password.validations.current-password.required')
        },
        newPassword: {
          required: this.$t('change-password.validations.new-password.required'),
          invalidCharacter: this.$t('change-password.validations.new-password.invalid-character'),
          twoDigits: this.$t('change-password.validations.new-password.two-digits'),
          uppercase: this.$t('change-password.validations.new-password.uppercase'),
          lowercase: this.$t('change-password.validations.new-password.lowercase'),
          specialChar: this.$t('change-password.validations.new-password.special-character'),
          minLength: this.$t('change-password.validations.new-password.min-length'),
          maxLength: this.$t('change-password.validations.new-password.max-length')
        },
        newPasswordRepeat: {
          required: this.$t('change-password.validations.new-password-repeat.required'),
          sameAsNewPassword: this.$t('change-password.validations.new-password-repeat.same')
        }
      }
    }
  },
  validations: {
    changePasswordInformation: {
      currentPassword: {
        required
      },
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(14),
        invalidCharacter,
        twoDigits,
        uppercase,
        lowercase,
        specialChar
      },
      newPasswordRepeat: {
        required,
        sameAsNewPassword: sameAs('newPassword')
      }
    }
  },
  methods: {
    resetErrors () {
      this.errorAlert = false
      this.alertMessage = ''
    },
    dashboardLink () {
      return process.env.VUE_APP_USER_DASHBOARD_PATH
    },
    changePassword () {
      this.$v.changePasswordInformation.$touch()
      if (this.$v.changePasswordInformation.$invalid) {
        return
      }
      this.resetErrors()
      this.changePasswordSecure(this.changePasswordInformation.currentPassword, this.changePasswordInformation.newPassword)
    },
    changePasswordSecure (currentPassword, newPassword) {
      ResetPasswordService.changePasswordSecure(currentPassword, newPassword)
        .then(() => {
          this.alertMessage = this.$t('change-password.messages.success')
        })
        .catch((error) => {
          this.alertMessage = error.response ? error.response.data.message : this.$t('common.errors.default')
          this.errorAlert = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "tws-core-atoms/variables.scss";

  .alert-row {
    margin-bottom: 5rem;
  }

  .margin-top-double {
    margin-top: $spacing-double;
  }

  .change-password-cancel-button a {
    text-decoration: none;
  }

  .bg-image-container {
    align-self: flex-start;
    margin-top: 10rem;
  }

  .bg-image {
    width: 50%;
  }
</style>
@/constants/validation-rules
