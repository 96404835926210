<template>
  <div>
    <InfraBreadcrumbContainer
      :grey-background="true"
      :items="breadcrumbItems">
      <generic-banner
        header="Begäran om tillträde till Skanovas fysiska infrastruktur"
        description="" />
    </InfraBreadcrumbContainer>

    <div class="form-container">
      <component
        :is="form"
        v-if="html" />
    </div>
  </div>
</template>

<script>
import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_DEVELOPMENT_ACT } from '@/constants/breadcrumbs-constants'
import GenericBanner from '@/components/common/generic-banner.vue'

export default {
  components: {
    InfraBreadcrumbContainer,
    GenericBanner
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_DEVELOPMENT_ACT,
      html: ''
    }
  },
  computed: {
    form () {
      return {
        template: this.html
      }
    }
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    loadContent () {
      const htmlContent = require('./html/development-act.html').default

      this.html = htmlContent
    }
  }
}
</script>
