import Cookies from 'js-cookie'

const hasWEBTABadministrativeRole = (roles) => {
  return roles.indexOf('tabusr') !== -1 ||
  roles.indexOf('tabadm') !== -1 ||
  roles.indexOf('tabmaintainance') !== -1 ||
  roles.indexOf('tabkto') !== -1 ||
  roles.indexOf('tabnat') !== -1 ||
  roles.indexOf('tabport') !== -1 ||
  roles.indexOf('tabread') !== -1
}

export const EAMCookiesModule = {
  namespaced: true,
  state: {
    firstName: null,
    lastName: null,
    company: null,
    username: null,
    roles: []
  },
  mutations: {
    setFirstName (state, firstName) {
      state.firstName = firstName
    },
    setLastName (state, lastName) {
      state.lastName = lastName
    },
    setCompany (state, company) {
      state.company = company
    },
    setRoles (state, roles) {
      state.roles = roles
    },
    setUsername (state, username) {
      state.username = username
    }
  },
  getters: {
    getUsername (state) {
      return state.username
    },
    getRoles (state) {
      return state.roles
    },
    isSuperUser (state) {
      return state.roles.indexOf('skaadmincaamaster') !== -1 ||
            state.roles.indexOf('tsicadmincaamaster') !== -1
    },
    isDelegatedAdmin (state) {
      return state.roles.indexOf('tsicadmincaadel1') !== -1 ||
            state.roles.indexOf('tsicadmincaadel2') !== -1 ||
            state.roles.indexOf('skaadmincaadel1') !== -1
    },
    isAdmin (state) {
      return state.roles.indexOf('tsicadmincaa1') !== -1 ||
        state.roles.indexOf('skaadmincaa1') !== -1
    },
    getCompanyCode (state) {
      return state.roles[0]
    },
    canTransferUsers (state) {
      return state.roles.indexOf('skacompover') !== -1 ||
            state.roles.indexOf('tsicadmincaamaster') !== -1 ||
            state.roles.indexOf('skaadmincaamaster') !== -1
    },
    canCreateCompanies (state) {
      return state.roles.indexOf('tobcompany') !== -1 ||
            state.roles.indexOf('skacompany') !== -1
    },
    canAdministrateUsers (state) {
      return state.roles.indexOf('tsicadmincaadel1') !== -1 ||
            state.roles.indexOf('tsicadmincaadel2') !== -1 ||
            state.roles.indexOf('skaadmincaadel1') !== -1 ||
            state.roles.indexOf('tsicadmincaa1') !== -1 ||
            state.roles.indexOf('skaadmincaa1') !== -1 ||
            state.roles.indexOf('tsicadmincaamaster') !== -1 ||
            state.roles.indexOf('skaadmincaamaster') !== -1
    },
    canAdministrateReports (state) {
      return state.roles.indexOf('tsicadmincaa1') !== -1 ||
      state.roles.indexOf('skaadmincaa1') !== -1 ||
      state.roles.indexOf('tsicadmincaamaster') !== -1 ||
      state.roles.indexOf('skaadmincaamaster') !== -1
    },
    canAccessReports (state) {
      return state.roles.indexOf('skakartf') !== -1
    },
    canUseSearchTools (state) {
      return state.roles.indexOf('icadrsok') !== -1 ||
            state.roles.indexOf('icfbnummer') !== -1 ||
            state.roles.indexOf('ictfnsok') !== -1 ||
            state.roles.indexOf('skasok') !== -1
    },
    canAccessInvoices (state) {
      return state.roles.indexOf('twsinvoices') !== -1
    },
    canAccessInvoiceSpecifications (state) {
      return state.roles.indexOf('twsspecifications') !== -1
    },
    isBillingAdmin (state) {
      return state.roles.indexOf('twsbillingadministrator') !== -1
    },
    hasUserOrderRole (state) {
      return state.roles.indexOf('nbbmlevel1') !== -1 ||
            state.roles.indexOf('nbbmlevel2') !== -1 ||
            state.roles.indexOf('tabcc') !== -1 ||
            state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('tabkt') !== -1
    },
    hasUserMyOrdersRole (state) {
      return state.roles.indexOf('nbbmlevel0') !== -1 ||
            state.roles.indexOf('nbbmlevel1') !== -1 ||
            state.roles.indexOf('nbbmlevel2') !== -1 ||
            state.roles.indexOf('tabcc') !== -1 ||
            state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('tabkt') !== -1 ||
            hasWEBTABadministrativeRole(state.roles)
    },
    hasUserTicketsRole (state) {
      return state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('sr1') !== -1 ||
            state.roles.indexOf('srplus') !== -1 ||
            state.roles.indexOf('srblock') !== -1
    },
    hasUserOperatorsbyteSkanovaTobRoles (state) {
      return state.roles.indexOf('nbbmlevel2') !== -1 ||
            state.roles.indexOf('nbbmlevel1') !== -1
    },
    hasOrdersNetbusinessRole (state) {
      return state.roles.indexOf('nbbmlevel1') !== -1 ||
            state.roles.indexOf('nbbmlevel2') !== -1
    },
    hasMyOrdersNetbusinessRole (state) {
      return state.roles.indexOf('nbbmlevel0') !== -1 ||
            state.roles.indexOf('nbbmlevel1') !== -1 ||
            state.roles.indexOf('nbbmlevel2') !== -1
    },
    hasUserOperatorsbyteTelefoniamagRole (state) {
      return state.roles.indexOf('tabcc') !== -1 ||
            state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('tabkt') !== -1
    },
    hasOrdersTelefoniabonnemangRole (state) {
      return state.roles.indexOf('tabcc') !== -1 ||
            state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('tabkt') !== -1
    },
    hasMyOrdersTelefoniabonnemangRole (state) {
      return state.roles.indexOf('tabktf') !== -1 ||
            state.roles.indexOf('tabkt') !== -1 ||
            hasWEBTABadministrativeRole(state.roles)

    },
    hasTicketsTelefoniabonnemangRole (state) {
      return state.roles.indexOf('tabktf') !== -1
    },
    hasTicketsSkanovaProductsRole (state) {
      return state.roles.indexOf('srplus') !== -1 ||
            state.roles.indexOf('sr1') !== -1
    },
    hasAgreementsResponsibleRole (state) {
      return state.roles.indexOf('tsicavtal') !== -1 ||
            state.roles.indexOf('skaavtal') !== -1
    },
    hasInvoiceSpecificationsRole (state) {
      return state.roles.indexOf('twsspecifications') !== -1
    },
    hasInvoicesRole (state) {
      return state.roles.indexOf('twsinvoices') !== -1
    },
    getFirstName (state) {
      return !state.firstName ? null : state.firstName.replace(/\+/g, ' ')
    },
    getLastName (state) {
      return !state.lastName ? null : state.lastName.replace(/\+/g, ' ')
    },
    getFullName (state, getters) {
      return getters.getFirstName + ' ' + getters.getLastName
    },
    getCompany (state) {
      let formattedCompany = state.company

      if (formattedCompany) {
        formattedCompany = formattedCompany.replace(/NB__/, '')
        formattedCompany = formattedCompany.replace(/\+/g, ' ')
        formattedCompany = formattedCompany.replace(/(,)?(\s+)?\d+-\d+/, '')
      }

      return formattedCompany
    },
    isLoggedIn (state) {
      return !!state.firstName && !!state.lastName && !!state.company && state.roles.length > 0
    }
  },
  actions: {
    loadUser ({ commit }) {
      const roles = Cookies.get('epinfo')
      commit('setFirstName', Cookies.get('fname'))
      commit('setLastName', Cookies.get('ename'))
      commit('setCompany', Cookies.get('companyname'))
      commit('setUsername', Cookies.get('username'))
      commit('setRoles', roles ? roles.split('-') : [])
    }
  }
}
