<template>
  <div
    class="container dynamic-form">
    <slot />

    <tws-button-primary
      v-if="labels.submit_button_text"
      class="button"
      @click="postForm">
      {{ labels.submit_button_text }}
    </tws-button-primary>

    <InfraPageAlert v-show="formSent">
      {{ labels.success_message }}
    </InfraPageAlert>

    <InfraPageAlert
      v-show="!valid"
      type="info">
      {{ labels.validation_message }}
    </InfraPageAlert>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'

import {
  TwsButtonPrimary, InfraPageAlert
} from 'tws-vue-components'

import DynamicFormService from '../services/dynamic-form.service'

import { isLoggedIn } from '@/utils/user.utils'

export default {
  name: 'TwsForm',
  components: {
    TwsButtonPrimary,
    InfraPageAlert
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validForm: {
        type: Boolean,
        required: true
      },
      validationMessages: {
        message: this.labels.validation_message
      },
      valid: true,
      mounted: false,
      formSent: false
    }
  },
  computed: {
    ...mapGetters('DynamicForm',
      [
        'getValidator',
        'getEmailAddresses',
        'getFormFieldsHtml',
        'getFieldValue',
        'getFieldValueMap'
      ]
    )
  },
  mounted () {
    if (isLoggedIn()) {
      this.fetchUser()
    }

    this.addFormInformation(
      {
        emailAddresses: this.labels.email_addresses,
        signature: this.labels.email_signature,
        fieldDependencyMessage: this.labels.dependent_fields_message
      }
    )

    this.formMounted()
    this.mounted = true
  },
  destroyed () {
    this.resetState()
    this.mounted = false
  },
  methods: {
    ...mapActions('DynamicForm/UserDetails', ['fetchUser']),
    generateTemplateText () {
      let updated = this.labels.template_text

      if (updated) {
        Object.keys(this.getFieldValueMap).forEach(key => {
          updated = updated.replace('${' + key + '}', this.getFieldValueMap[key])
        })

        return updated
      }

      return ''
    },
    postForm () {
      this.formSent = false
      this.getValidator.formObject.$touch()
      this.valid = !this.getValidator.formObject.$invalid && this.getEmailAddresses.length > 0

      if (!this.valid) {
        return
      }

      const data = {
        from: DynamicFormService.parseExpression(this.getFieldValueMap, this.labels.from_email),
        emails: this.getEmailAddresses,
        subject: DynamicFormService.parseExpression(this.getFieldValueMap, this.labels.email_subject),
        html: this.generateTemplateText() + this.getFormFieldsHtml
      }

      DynamicFormService.postForm(JSON.stringify(data))
      this.resetForm()
      this.formSent = true
    },

    ...mapActions('DynamicForm',
      [
        'addFormInformation',
        'formMounted',
        'resetForm',
        'resetState'
      ]
    )
  }
}
</script>

<style  lang="scss" scoped>
.dynamic-form {
  margin-top: 48px;

  .button {
    margin-bottom: 25px;
  }
}
</style>
