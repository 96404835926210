<template>
  <div class="municipalities-container">
    <div class="heading">
      <h2 class="heading-m">
        {{ county.county }}
      </h2>

      <tws-close2-icon @click="$emit('close')" />
    </div>

    <h2
      v-if="selectedMunicipality"
      class="heading-s mt-3">
      {{ selectedMunicipality.municipality }}
    </h2>

    <div class="row table-tabs no-gutters">
      <div class="col">
        <tws-map-table-tab
          class="mr-3"
          :text="$t('map.service-mode')"
          :active="showServiceStateField"
          @click="showServiceStateField = true" />
        <tws-map-table-tab
          :text="$t('map.customer-reports')"
          :active="!showServiceStateField"
          @click="showServiceStateField = false" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>{{ showServiceStateField ? $t('map.extended-service-time') : $t('map.customer-reported-errors') }}</b>

        <table
          v-if="!selectedMunicipality"
          class="map-table map-table--mobile">
          <tbody>
            <template v-for="(municipality, index) in county.municipalities">
              <tr :key="index">
                <td :class="{ bold: municipality === selectedMunicipality}">
                  <span
                    class="clickable"
                    @click="handleMunicipalityClick(municipality)">
                    {{ municipality.municipality }}
                  </span>
                </td>
                <td v-show="showServiceStateField">
                  <tws-status-icon
                    color-palette="orange"
                    :status="municipality.sla" />
                  <span class="sla-text">{{ municipality.sla === 0 ? '-' : '+' + municipality.sla }}</span>
                </td>
                <td v-show="!showServiceStateField">
                  {{ municipality.faults === 0 ? '-' : municipality.faults }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <template v-if="selectedMunicipality">
          <table
            class="map-table map-table--mobile">
            <tr
              v-for="(site, index) in selectedMunicipality.sites"
              :key="index + '-' + index">
              <td class="site">
                {{ site.site }}
              </td>
              <td v-show="showServiceStateField">
                {{ site.sla === 0 ? '-' : '+' + site.sla }}
              </td>
              <td v-show="!showServiceStateField">
                {{ site.faults === 0 ? '-' : site.faults }}
              </td>
            </tr>
          </table>

          <div class="button-container">
            <tws-button-primary
              v-show="selectedMunicipality"
              @click="selectedMunicipality = null">
              {{ $t('common.close') }}
            </tws-button-primary>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MapStatusIcon from '@/components/map/map-status-icon'
import MapTableTab from '@/components/map/map-table-tab'
import { MapServiceStateMunicipalitiesTableMixin } from '@/mixins/map-service-state.mixin'
import { TwsButtonPrimary } from 'tws-vue-components'

export default {
  components: {
    'tws-status-icon': MapStatusIcon,
    'tws-map-table-tab': MapTableTab,
    TwsButtonPrimary
  },
  mixins: [MapServiceStateMunicipalitiesTableMixin],
  data () {
    return {
      showServiceStateField: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table';

.table-tabs {
  margin: 25px 0;
}

.municipalities-container {
  background-color: $light-grey;
  padding: 30px 20px 50px;
  margin-bottom: -40px;

  .heading {
    display: flex;
    justify-content: space-between;
  }

  .map-table--mobile {
    td:not(:first-of-type) {
      text-align: right;
    }

    .sla-text {
      display: inline-block;
      width: 16px;
    }
  }

  .button-container {
    margin-top: 40px;
    text-align: center;
  }
}

.bold {
  font-weight: bold;
}
</style>
