import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_COMPANY_SERVICE)

const LookupsService = {
  getAllCountries: () => {
    return api.get('/lookups/countries')
  },
  getAllCountriesEn: () => {
    return api.get('/lookups/countries/en')
  },
  getAllCompanies: () => {
    return api.get('/lookups/companies')
  }
}

export default LookupsService
