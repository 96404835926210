<template>
  <tws-login-form
    :validator="$v"
    @submitted="isLoading = true">
    <input
      :value="oneTimeCodeUsername"
      type="hidden"
      name="username">

    <input
      type="hidden"
      name="type"
      value="SMS">

    <tws-validated-text-input-group
      id="password"
      v-model="oneTimeCodeProxy"
      :validator="$v.oneTimeCode"
      :validation-messages="validationMessages.oneTimeCode"
      :label="$t('login.one-time-code')" />

    <p class="information">
      {{ $t('login.one-time-code-information') }}
    </p>

    <tws-button-primary
      type="submit"
      :disabled="isLoading"
      :class="{ 'button-with-loader': isLoading }">
      {{ $t('login.submit') }}
      <tws-loading-spinner v-show="isLoading" />
    </tws-button-primary>

    <div class="cancel-link">
      <a
        role="button"
        @click="oneTimeCodeCanceled">
        {{ $t('login.go-back') }}
      </a>
    </div>
  </tws-login-form>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import { required } from 'vuelidate/lib/validators'
import Form from './login-form'

import {
  TwsButtonPrimary,
  TwsValidatedTextInputGroup,
  TwsLoadingSpinner
} from 'tws-vue-components'

export default {
  components: {
    'tws-login-form': Form,
    TwsButtonPrimary,
    TwsValidatedTextInputGroup,
    TwsLoadingSpinner
  },
  mixins: [validationMixin],
  data () {
    return {
      validationMessages: {
        oneTimeCode: {
          required: this.$t('login.validations.one-time-code.required')
        }
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState('login', [
      'oneTimeCodeUsername',
      'oneTimeCode'
    ]),
    oneTimeCodeProxy: {
      get () {
        return this.oneTimeCode
      },
      set (value) {
        this.oneTimeCodeEntered(value)
      }
    }
  },
  methods: {
    ...mapActions('login', [
      'oneTimeCodeEntered',
      'oneTimeCodeCanceled'
    ])
  },
  validations () {
    return {
      oneTimeCode: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.button-with-loader {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  & div {
    padding-left: 1rem;
  }
}

.information {
  margin-top: 0;
}

.cancel-link {
  margin-top: 30px;

  a {
    color: $black;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $core-purple;
      text-decoration: none;
    }
  }
}
</style>
