<template>
  <div class="account-information-edit">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-validated-text-input-group
          id="username"
          v-model="editableInformation.username"
          :validator="validator.username"
          :validation-messages="validationMessages.username"
          :disabled="!creationMode"
          :label="$t('account-information.username')" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label">
          {{ $t('account-information.login-method') }}
        </label>

        <div class="form-control">
          <input
            id="login-method-1"
            v-model="editableInformation.loginMethodPassword"
            :value="true"
            :disabled="loginMethodDisabled"
            class="input-radio"
            type="radio"
            name="password-options">

          <label
            class="label"
            for="login-method-1">
            {{ $t('account-information.password') }}
          </label>

          <input
            id="login-method-2"
            v-model="editableInformation.loginMethodPassword"
            :value="false"
            :disabled="loginMethodDisabled"
            class="input-radio"
            type="radio"
            name="password-options">

          <label
            class="label"
            for="login-method-2">
            {{ $t('account-information.one-time-code') }}
          </label>
        </div>

        <tws-field-validation-errors
          :validator="validator.loginMethodPassword"
          :messages="validationMessages.loginMethodPassword"
          field-name="Login method" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-dropdown-group
          id="account-status"
          v-model="editableInformation.accountStatus"
          :disabled="creationMode"
          :label="$t('account-information.account-status')">
          <option
            v-for="status in accountStatuses"
            :key="status.key"
            :value="status.key">
            {{ $t(status.value) }}
          </option>
        </tws-dropdown-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <tws-date-picker
          v-model="editableInformation.accountExpirationDate"
          :label="$t('account-information.expiration-date')"
          :disabled="creationMode && editableInformation.roles.SYSTEM_USER" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3 class="heading-s roles-heading">
          {{ $t('account-information.roles') }}
        </h3>
      </div>
    </div>

    <div
      v-if="!isDelegatedAdmin"
      class="row">
      <div class="col-12">
        <tws-checkbox
          id="system-user-role"
          v-model="editableInformation.roles.SYSTEM_USER"
          :tooltip="content.systemUserToolTip"
          :label="$t(getRole('SYSTEM_USER'))"
          tooltip-width="500px" />
      </div>
    </div>

    <div class="row common-roles-row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.roles-and-tools') }}
        </label>

        <tws-checkbox
          id="agreement-responsible-role"
          v-model="editableInformation.roles.AGREEMENT_RESPONSIBLE"
          :tooltip="content.responsibleAgreementToolTip"
          :label="$t(getRole('AGREEMENT_RESPONSIBLE'))"
          :disabled="editableInformation.roles.SYSTEM_USER || (!isSuperUser && !isAdmin)"
          tooltip-width="500px" />

        <tws-checkbox
          id="reports-role"
          v-model="editableInformation.roles.REPORTS"
          :tooltip="content.reportsToolTip"
          :label="$t(getRole('REPORTS'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="search-role"
          v-model="editableInformation.roles.SEARCH"
          :tooltip="content.searchToolsToolTip"
          :label="$t(getRole('SEARCH'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          v-if="isAdmin || isSuperUser"
          id="invoices-role"
          v-model="editableInformation.roles.INVOICES"
          :tooltip="content.invoicesToolTip"
          :label="$t(getRole('INVOICES'))"
          :disabled="(!isSuperUser && !isDelegatedAdmin)"
          tooltip-width="500px" />

        <tws-checkbox
          v-if="isAdmin || isSuperUser"
          id="specifications-role"
          v-model="editableInformation.roles.SPECIFICATIONS"
          :tooltip="content.specificationsToolTip"
          :label="$t(getRole('SPECIFICATIONS'))"
          :disabled="(!isSuperUser && !isDelegatedAdmin)"
          tooltip-width="500px" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.complaints') }}
        </label>

        <tws-radio-input
          id="error-reporting-role-1"
          v-model="editableInformation.errorReporting"
          :tooltip="content.roleComplaintTooltip"
          :label="$t(getRole('TROUBLE_TICKET'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="TROUBLE_TICKET"
          tooltip-width="500px" />

        <tws-radio-input
          id="error-reporting-role-2"
          v-model="editableInformation.errorReporting"
          :tooltip="content.roleComplaintPlusTooltip"
          :label="$t(getRole('TROUBLE_TICKET_PLUS'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="TROUBLE_TICKET_PLUS"
          tooltip-width="500px" />

        <tws-radio-input
          id="error-reporting-role-3"
          v-model="editableInformation.errorReporting"
          :tooltip="content.roleComplaintWithoutAccessTooltip"
          :label="$t(getRole('TROUBLE_TICKET_NO_ACCESS'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="TROUBLE_TICKET_NO_ACCESS"
          tooltip-width="500px" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.business-role') }}
        </label>

        <tws-radio-input
          id="net-business-role-1"
          v-model="editableInformation.manageNBM"
          :tooltip="content.readPermissionToolTip"
          :label="$t(getRole('NETBUSINESS_READ'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="NETBUSINESS_READ"
          tooltip-width="500px" />

        <tws-radio-input
          id="net-business-role-2"
          v-model="editableInformation.manageNBM"
          :tooltip="content.requestPrermissionToolTip"
          :label="$t(getRole('NETBUSINESS_QOUTE_CREATION'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="NETBUSINESS_QOUTE_CREATION"
          tooltip-width="500px" />

        <tws-radio-input
          id="net-business-role-3"
          v-model="editableInformation.manageNBM"
          :tooltip="content.purchaserEligibilityToolTip"
          :label="$t(getRole('NETBUSINESS_ORDER_CREATION'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="NETBUSINESS_ORDER_CREATION"
          tooltip-width="500px" />

        <tws-radio-input
          id="net-business-role-4"
          v-model="editableInformation.manageNBM"
          :tooltip="content.noDiffrenceToolTip"
          :label="$t(getRole('NETBUSINESS_MANAGE_LEVEL_NO_ACCESS'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          radio-value="NETBUSINESS_MANAGE_LEVEL_NO_ACCESS"
          tooltip-width="500px" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.subscription-role') }}
        </label>

        <tws-checkbox
          id="phone-subscription-role-1"
          v-model="editableInformation.roles.PHONE_SUBCRIPTION_ORDER_CREATION"
          :tooltip="content.userCreatePermissionTooltip"
          :label="$t(getRole('PHONE_SUBCRIPTION_ORDER_CREATION'))"
          :disabled="phoneSubscriptionRolesDisabled"
          tooltip-width="500px" />

        <tws-checkbox
          id="phone-subscription-role-2"
          v-model="editableInformation.roles.PHONE_SUBCRIPTION_ORDER_MANAGMENT"
          :tooltip="content.usermanagementTooltip"
          :label="$t(getRole('PHONE_SUBCRIPTION_ORDER_MANAGMENT'))"
          :disabled="phoneSubscriptionRolesDisabled"
          tooltip-width="500px" />

        <tws-checkbox
          id="phone-subscription-role-3"
          v-model="editableInformation.roles.PHONE_SUBCRIPTION_FAULT_HANDLING"
          :tooltip="content.reportManagementTooltip"
          :label="$t(getRole('PHONE_SUBCRIPTION_FAULT_HANDLING'))"
          :disabled="phoneSubscriptionRolesDisabled"
          tooltip-width="500px" />
      </div>
    </div>

    <div
      v-if="!isDelegatedAdmin"
      class="row admin-roles-row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.web-tab-role') }}
        </label>

        <tws-checkbox
          id="web-tab-admin-tab-admin-role"
          v-model="editableInformation.roles.WEBTAB_ADMIN_TAB_ADMIN"
          :tooltip="content.tabAdminTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_TAB_ADMIN'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-operator-customer-service-role"
          v-model="editableInformation.roles.WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE"
          :tooltip="content.operatorCustomerServiceTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-network-defect-admin"
          v-model="editableInformation.roles.WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR"
          :tooltip="content.networkDefectAdministratorTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-super-user"
          v-model="editableInformation.roles.WEBTAB_ADMIN_SUPERUSER"
          :tooltip="content.superuserTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_SUPERUSER'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-maintenance"
          v-model="editableInformation.roles.WEBTAB_ADMIN_MAINTENANCE"
          :tooltip="content.maintenanceTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_MAINTENANCE'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-tab-porting-internal"
          v-model="editableInformation.roles.WEBTAB_ADMIN_TAB_PORTING_INTERNAL"
          :tooltip="content.tabPortingInternalTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_TAB_PORTING_INTERNAL'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="web-tab-admin-tab-read-only-internal"
          v-model="editableInformation.roles.WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL"
          :tooltip="content.tabReadOnlyInternalTooltip"
          :label="$t(getRole('WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label class="label font-weight-bold">
          {{ $t('account-information.administrator-role') }}
        </label>

        <tws-checkbox
          id="admin-role"
          v-model="editableInformation.roles.ADMINISTRATOR"
          :tooltip="content.adminTooltip"
          :label="$t(getRole('ADMINISTRATOR'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="delegated-admin-role"
          v-model="editableInformation.roles.DELEGATED_ADMINISTRATOR"
          :tooltip="content.delegatedAdminTooltip"
          :label="$t(getRole('DELEGATED_ADMINISTRATOR'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="master-admin-role"
          v-model="editableInformation.roles.MASTER_ADMINISTRATOR"
          :tooltip="content.masterAdminTooltip"
          :label="$t(getRole('MASTER_ADMINISTRATOR'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="company-admin-role"
          v-model="editableInformation.roles.COMPANY_ADMIN"
          :tooltip="content.companyAdminTooltip"
          :label="$t(getRole('COMPANY_ADMIN'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          id="company-transfer-role"
          v-model="editableInformation.roles.COMPANY_TRANSFER"
          :tooltip="content.companyTransferTooltip"
          :label="$t(getRole('COMPANY_TRANSFER'))"
          :disabled="editableInformation.roles.SYSTEM_USER"
          tooltip-width="500px" />

        <tws-checkbox
          v-if="isSuperUser"
          id="billing-admin-role"
          v-model="editableInformation.roles.BILLING_ADMINISTRATOR"
          :tooltip="content.billingAdminTooltip"
          :label="$t(getRole('BILLING_ADMINISTRATOR'))"
          :disabled="(!isSuperUser)"
          tooltip-width="500px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { ACCOUNT_STATUS_TEXT_LIST } from '@/constants/account-status.js'
import { USER_ROLES } from '@/constants/user-roles.js'

import {
  TwsCheckbox,
  TwsDatePicker,
  TwsDropdownGroup,
  TwsFieldValidationErrors,
  TwsRadioInput,
  TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    TwsCheckbox,
    TwsDatePicker,
    TwsDropdownGroup,
    TwsFieldValidationErrors,
    TwsRadioInput,
    TwsValidatedTextInputGroup
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    information: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    },
    creationMode: {
      type: Boolean,
      default: true
    },
    usernameContainsSpId: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editableInformation: Object.assign({}, this.information),
      validationMessages: {
        username: {
          required: this.$t('account-information.validations.username.required'),
          maxLength: this.$t('account-information.validations.username.max-length'),
          usernameValidChars: this.$t('account-information.validations.username.valid-characters'),
          username: this.$t('account-information.validations.username.username')
        },
        loginMethodPassword: {
          required: this.$t('account-information.validations.login-method-password.required'),
          delegatedAdmin: this.$t('account-information.validations.login-method-password.delegated-admin')
        }
      },
      accountStatuses: ACCOUNT_STATUS_TEXT_LIST
    }
  },
  computed: {
    ...mapGetters('eamCookies', [
      'isDelegatedAdmin',
      'isSuperUser',
      'isAdmin'
    ]),
    phoneSubscriptionRolesDisabled () {
      return !this.creationMode && !this.usernameContainsSpId || this.editableInformation.roles.SYSTEM_USER
    },
    loginMethodDisabled () {
      return this.editableInformation.roles.SYSTEM_USER || this.editableInformation.roles.DELEGATED_ADMINISTRATOR
    }
  },
  watch: {
    editableInformation: {
      handler (information) {
        if (information.roles.SYSTEM_USER) {
          Object
            .keys(information.roles)
            .filter(key => key !== 'SYSTEM_USER')
            .forEach(key => {
              information.roles[key] = false
            })

          information.errorReporting = null
          information.manageNBM = null
          information.accountExpirationDate = null
        }

        if (information.roles.DELEGATED_ADMINISTRATOR) {
          information.loginMethodPassword = false
        }

        this.$emit('informationChanged', information)
      },
      deep: true
    }
  },
  methods: {
    getRole (role) {
      return USER_ROLES[role]
    }
  }
}
</script>

<style lang="scss" scoped>
.roles-heading {
  margin-top: 50px;
  margin-bottom: 25px;
}

.common-roles-row,
.admin-roles-row {
  .form-control:not(:last-of-type) {
    margin-bottom: 0;
  }
}

.admin-roles-row  {
  margin-top: 45px;
}
</style>
