<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('user-administration.create-user')" />
    </InfraBreadcrumbContainer>

    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="heading-m personal-info-heading">
            {{ $t('user-creation.heading') }}
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <tws-personal-information-edit
            :countries="countries"
            :companies="companies"
            :information="information"
            :validator="$v.information"
            :is-super-user="isSuperUser"
            @informationChanged="personalInformationChanged" />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h1 class="heading-m account-info-heading">
            {{ $t('user-creation.information-heading') }}
          </h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <tws-account-information-edit
            :content="content"
            :information="information"
            :validator="$v.information"
            @informationChanged="accountInformationChanged" />
        </div>
      </div>

      <div class="row">
        <div class="col creation-actions">
          <tws-button-primary
            :disabled="creating"
            @click="onUserCreate">
            {{ $t('user-creation.create') }}
          </tws-button-primary>

          <tws-borderless-button
            :disabled="creating"
            @click="onCancelUserCreation">
            {{ $t('common.cancel') }}
          </tws-borderless-button>
        </div>
      </div>

      <div
        v-show="exception"
        class="row">
        <div class="col">
          <InfraPageAlert
            type="danger"
            class="exception">
            {{ $t('user-creation.loading-failed') }}
          </InfraPageAlert>
        </div>
      </div>

      <confirmation-dialog
        v-show="showCancelDialog"
        :heading="$t('user-creation.cancel-dialog.heading')"
        :confirmation-button-text="$t('user-creation.cancel-dialog.confirm')"
        :cancel-button-text="$t('user-creation.cancel-dialog.cancel')"
        @confirm="goToUserAdministration"
        @cancel="toggleCancelDialog">
        <template #body>
          {{ $t('user-creation.cancel-dialog.body') }}
        </template>
      </confirmation-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from 'vuex'

import { validationMixin } from 'vuelidate'

import {
  required,
  maxLength
} from 'vuelidate/lib/validators'

import {
  phone,
  email,
  mobilePhone,
  usernameValidChars
} from '@/common/validation-rules.js'

import UserService from '@/services/user.service.js'

import PersonalInformationEdit from '@/components/personal-information/personal-information-edit'
import AccountInformationEdit from '@/components/account-information/account-information-edit'

import { InfraBreadcrumbContainer } from 'tws-vue-components'
import GenericBanner from '@/components/common/generic-banner.vue'
import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'

import { BREADCRUMBS_CREATE_USER } from '@/constants/breadcrumbs-constants'

import {
  TwsBorderlessButton,
  TwsButtonPrimary,
  InfraPageAlert
} from 'tws-vue-components'

export default {
  components: {
    'tws-personal-information-edit': PersonalInformationEdit,
    'tws-account-information-edit': AccountInformationEdit,
    TwsBorderlessButton,
    TwsButtonPrimary,
    InfraPageAlert,
    GenericBanner,
    ConfirmationDialog,
    InfraBreadcrumbContainer
  },
  mixins: [validationMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_CREATE_USER,
      showCancelDialog: false,
      content: {
        systemUserToolTip: this.$t('user-creation.tooltip.system-user'),
        responsibleAgreementToolTip: this.$t('user-creation.tooltip.responsible-agreement'),
        reportsToolTip: this.$t('user-creation.tooltip.reports'),
        searchToolsToolTip: this.$t('user-creation.tooltip.search-tools'),
        invoicesToolTip: this.$t('user-creation.tooltip.invoices'),
        specificationsToolTip: this.$t('user-creation.tooltip.specifications'),
        roleComplaintTooltip: this.$t('user-creation.tooltip.role-complaint'),
        roleComplaintPlusTooltip: this.$t('user-creation.tooltip.role-complaint-plus'),
        roleComplaintWithoutAccessTooltip: this.$t('user-creation.tooltip.role-complaint-without-access'),
        readPermissionToolTip: this.$t('user-creation.tooltip.read-permission'),
        requestPrermissionToolTip: this.$t('user-creation.tooltip.request-permission'),
        purchaserEligibilityToolTip: this.$t('user-creation.tooltip.purchaser-eligibility'),
        noDiffrenceToolTip: this.$t('user-creation.tooltip.no-difference'),
        userCreatePermissionTooltip: this.$t('user-creation.tooltip.user-create-permission'),
        usermanagementTooltip: this.$t('user-creation.tooltip.user-management'),
        reportManagementTooltip: this.$t('user-creation.tooltip.report-management'),
        tabAdminTooltip: this.$t('user-creation.tooltip.tab-admin'),
        operatorCustomerServiceTooltip: this.$t('user-creation.tooltip.operator-customer-service'),
        networkDefectAdministratorTooltip: this.$t('user-creation.tooltip.network-defect-administrator'),
        superuserTooltip: this.$t('user-creation.tooltip.superuser'),
        maintenanceTooltip: this.$t('user-creation.tooltip.maintenance'),
        tabPortingInternalTooltip: this.$t('user-creation.tooltip.tab-porting-internal'),
        tabReadOnlyInternalTooltip: this.$t('user-creation.tooltip.tab-read-only-internal'),
        adminTooltip: this.$t('user-creation.tooltip.admin'),
        delegatedAdminTooltip: this.$t('user-creation.tooltip.delegated-admin'),
        masterAdminTooltip: this.$t('user-creation.tooltip.master-admin'),
        companyAdminTooltip: this.$t('user-creation.tooltip.company-admin'),
        companyTransferTooltip: this.$t('user-creation.tooltip.company-transfer'),
        billingAdminTooltip: this.$t('user-creation.tooltip.billing-admin')
      }
    }
  },
  computed: {
    ...mapState('userCreation', [
      'information',
      'creating',
      'exception'
    ]),
    ...mapGetters({
      isSuperUser: 'eamCookies/isSuperUser',

      countries: 'lookups/countries/getCountries',
      companies: 'lookups/companies/getCompanies'
    })
  },
  validations () {
    return {
      information: {
        firstName: {
          required,
          maxLength: maxLength(20)
        },
        lastName: {
          required,
          maxLength: maxLength(30)
        },
        ...this.isSuperUser ? {
          company: {
            required
          }
        } : {},
        email: {
          required,
          email
        },
        phone: {
          required,
          phone
        },
        mobilePhone: {
          mobilePhone,
          ...!this.information.loginMethodPassword ? {
            required
          } : {}
        },
        username: {
          required,
          maxLength: maxLength(50),
          usernameValidChars,
          username: value => {
            if (!value) {
              return false
            }

            const companyCode = this.information.company ? this.information.company.code : null

            return UserService.validateUsername(value, companyCode, this.information.roles)
          }
        },
        loginMethodPassword: {
          required
        },
        position: {
          maxLength: maxLength(30)
        },
        comments: {
          maxLength: maxLength(400)
        }
      }
    }
  },
  mounted () {
    this.fetchCountries()
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('userCreation', [
      'personalInformationChanged',
      'accountInformationChanged',
      'createUser'
    ]),
    ...mapActions({
      fetchCountries: 'lookups/countries/fetchCountries',
      fetchCompanies: 'lookups/companies/fetchCompanies'
    }),
    toggleCancelDialog () {
      this.showCancelDialog = !this.showCancelDialog
    },
    onUserCreate () {
      this.$v.information.$touch()

      if (this.$v.information.$invalid) {
        return
      }

      this.createUser()
    },
    onCancelUserCreation () {
      if (this.$v.information.$anyDirty) {
        this.toggleCancelDialog()
      } else {
        this.goToUserAdministration()
      }
    },
    goToUserAdministration () {
      this.$router.push({
        name: 'user-administration',
        params: this.$route.params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 45px;
  margin-bottom: 115px;
}

.personal-info-heading,
.account-info-heading {
  margin-bottom: 30px;
}

.account-info-heading {
  margin-top: 65px;
}

.creation-actions {
  padding-top: 35px;

  .tws-borderless-button {
    margin-left: 25px;
  }
}

.exception {
  margin-top: 10px;
}
</style>
