<template>
  <div class="menu-items-secured">
    <tws-mobile-menu-item-tree
      :item="menuItems.dashboard" />
    <tws-mobile-menu-item-tree
      :item="menuItems.searchTools"
      :sub-menu-items="searchToolsMenu" />
    <tws-mobile-menu-item-tree
      :item="menuItems.orders"
      :sub-menu-items="orders" />
    <tws-mobile-menu-item-tree
      :item="menuItems.myOrders"
      :sub-menu-items="myOrders" />
    <tws-mobile-menu-item-tree
      :item="menuItems.tickets"
      :sub-menu-items="tickets" />
    <tws-mobile-menu-item-tree
      v-if="isDelegatedAdmin"
      :item="menuItems.userAdministration" />

    <tws-mobile-menu-item-tree
      v-if="customerTicketsEnabled"
      :item="menuItems.customerTickets" />

    <tws-mobile-menu-item-tree
      v-if="canAccessInvoicesOrSpecifications"
      :item="menuItems.myInvoices" />

    <tws-mobile-menu-item-tree
      v-if="canAccessReportsInMenu"
      :item="menuItems.myReports" />

    <tws-mobile-menu-item-tree
      v-if="canAccessOnlyCompanyAdministrationSubMenu"
      :item="menuItems.companyAdministration" />
    <tws-mobile-menu-item-tree
      v-else
      :item="menuItems.administration"
      :sub-menu-items="administrationMenu" />

    <div class="spacer" />
  </div>
</template>

<script>
import Feature from '@telia/infra-feature-manager'
import SecureMenuMixin from '@/mixins/secure-menu.mixin'
import MobileMenuItemTree from './mobile-menu-item-tree/mobile-menu-item-tree'

export default {
  components: {
    'tws-mobile-menu-item-tree': MobileMenuItemTree
  },
  mixins: [SecureMenuMixin],
  data () {
    return {
      customerTicketsEnabled: Feature.CUSTOMER_TICKETS_LINK,
      menuItems: {
        dashboard: {
          title: this.$t('header.menu-items.dashboard'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_DASHBOARD_PATH
        },
        searchTools: {
          title: this.$t('header.menu-items.search-tools'),
          expandable: true,
          expanded: false
        },
        orders: {
          title: this.$t('header.menu-items.orders'),
          expandable: true,
          expanded: false
        },
        myOrders: {
          title: this.$t('header.menu-items.my-orders'),
          expandable: true,
          expanded: false
        },
        tickets: {
          title: this.$t('header.menu-items.tickets'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_FELANMALAN_PATH
        },
        userAdministration: {
          title: this.$t('header.menu-items.user-administration'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_USER_ADMINISTRATION_PATH
        },
        myDocuments: {
          title: this.$t('header.menu-items.my-documents'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_MY_DOCUMENTS_PATH
        },
        myReports: {
          title: this.$t('header.menu-items.my-reports'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_MY_REPORTS_PATH
        },
        myInvoices: {
          title: this.$t('header.menu-items.my-invoices'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_MY_INVOICES_PATH
        },
        customerTickets: {
          title: 'Mina Ärenden',
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_CUSTOMER_TICKETS_PATH
        },
        companyAdministration: {
          title: this.$t('header.menu-items.company-administration'),
          expandable: false,
          expanded: false,
          link: process.env.VUE_APP_COMPANY_ADMINISTRATION_PATH
        },
        administration: {
          title: this.$t('header.menu-items.administration'),
          expandable: true,
          expanded: false
        }
      },
      searchToolsMenu: [],
      administrationMenu: []
    }
  },
  created () {
    this.searchToolsMenu = this.constructSearchToolCategories()
    this.administrationMenu = this.constructAdministrationCategories()
  },
  methods: {
    constructAdministrationCategories () {
      return [
        {
          title: this.$t('header.administration-categories.user-administration'),
          link: this.pages.userAdministration
        },
        ...this.isSuperUser ? [{
          title: this.$t('header.administration-categories.user-deletion'),
          link: this.pages.userDeletion
        }] : [],
        ...this.canTransferUsers ? [{
          title: this.$t('header.administration-categories.user-transfer'),
          link: this.pages.userTransfer
        }] : [],
        ...this.canCreateCompanies || this.isSuperUser ? [{
          title: this.$t('header.administration-categories.company-administration'),
          link: this.pages.companyAdministration
        }] : [],
        ...this.canAdministrateReports ? [{
          title: this.$t('header.administration-categories.reports-administration'),
          link: this.pages.reportsAdministration
        }] : [],
        ...this.canAdministrateReports ? [{
          title: this.$t('header.administration-categories.reports-manual-upload'),
          link: this.pages.reportsManualUpload
        }] : []
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.spacer {
  height: 18px;
  background-color: #EAEAEA;
  border-bottom: 1px solid $medium-grey;
}
</style>
