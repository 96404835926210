import CookieUtils from '@/utils/cookie.utils'
import LoginView from '@/views/login/LoginView'
import RequestResetPasswordView from '@/views/reset-password/RequestResetPasswordView.vue'
import ChangePasswordView from '@/views/change-password/ChangePasswordView.vue'
import ResetPasswordView from '@/views/reset-password/ResetPasswordView'

export default [
  {
    path: 'login',
    name: 'login',
    component: LoginView,
    beforeEnter: (to, _from, next) => {
      if (to.name === 'login' && to.query.reason === 'unauthorized') {
        CookieUtils.removeCookies()
      }

      next()
    }
  },
  {
    path: 'request-reset-password',
    name: 'request-reset-password',
    component: RequestResetPasswordView
  },
  {
    path: 'request-reset-password/reset-password',
    name: 'reset-password',
    component: ResetPasswordView
  },
  {
    path: 'change-password',
    name: 'change-password',
    component: ChangePasswordView
  }
]
