<template>
  <main>
    <template v-if="showGlobalComponents">
      <SessionExpirationDialog />
      <TheHeader />
    </template>

    <div id="telia-wholesale">
      <router-view />
    </div>

    <template v-if="showGlobalComponents">
      <TheFooter />
    </template>
  </main>
</template>
<script>
import TheHeader from '@/components/header/the-header.vue'
import TheFooter from '@/components/footer/the-footer.vue'
import SessionExpirationDialog from '@/components/session-expiration-dialog/SessionExpirationDialog.vue'

export default {
  components: {
    TheHeader,
    TheFooter,
    SessionExpirationDialog
  },
  provide () {
    return {
      iconsBasePath: window.location.origin + '/img/svg/icons'
    }
  },
  computed: {
    showGlobalComponents () {
      return !this.$route.meta.hideGlobalComponents
    }
  },
  beforeCreate () {
    document.documentElement.lang = this.$i18n.locale
  },
  created () {
    window.addEventListener('tws-chat-container-open', this.initAndShowAceChat)
    window.addEventListener('tws-chat-container-close', this.completelyHideAceChat)
  },
  destroyed () {
    window.removeEventListener('tws-chat-container-open', this.initAndShowAceChat)
    window.removeEventListener('tws-chat-container-close', this.completelyHideAceChat)
  },
  methods: {
    initAndShowAceChat () {
      const allowedPathSv = '/kontakt/error-report/chat'
      const allowedPathEn = '/en/kontakt/error-report/chat'

      const hrefSv = 'https://telia-infra-wholesale.humany.net/chatt---felanmalan'
      const hrefEn = 'https://telia-infra-wholesale.humany.net/chat---reporting'

      const currentPath = this.$route.path
      const allowedPaths = [
        allowedPathSv,
        allowedPathEn
      ]
      const aceSdkElement = document.getElementById('ace-sdk-script-tws')

      if (allowedPaths.includes(currentPath) && !aceSdkElement) {
        // Create ACEWebSDK script element
        const aceSdkScript = document.createElement('script')
        aceSdkScript.src = 'https://wds.ace.teliacompany.com/wds/instances/Teliasv_2/ACEWebSDK.min.js'
        aceSdkScript.setAttribute('id', 'ace-sdk-script-tws')
        document.head.appendChild(aceSdkScript)

        const isEnglishVersion = currentPath.includes(allowedPathEn)
        const anchorTagHref = isEnglishVersion ? hrefEn : hrefSv

        const anchorTag = document.createElement('a')
        anchorTag.href = anchorTagHref
        document.body.appendChild(anchorTag)

        this.triggerAceChatWindowOpen()
      } else {
        this.toggleAceChatVisibility('block')
      }
    },
    completelyHideAceChat () {
      this.toggleAceChatVisibility('none')
      this.triggerAceChatWindowOpen()
    },
    toggleAceChatVisibility (display) {
      const widgets = document.getElementsByClassName(
        'humany-widget'
      )
      const buttons = document.getElementsByClassName(
        'humany-trigger'
      )

      if (buttons[0]) {
        buttons[0].style.display = display
      }

      if (widgets[0]) {
        widgets[0].style.display = display
      }
    },
    triggerAceChatWindowOpen () {
      const buttons = document.getElementsByClassName(
        'humany-trigger'
      )

      if (buttons[0] && !buttons[0].classList.contains('humany-active')) {
        buttons[0].click()
      }
    }
  }
}
</script>
