import {
  mapActions, mapState
} from 'vuex'
import {
  COUNTY_CODE_BY_NAME, STATUS_CODE_BY_NAME
} from '@/utils/map-utils'

export const MapServiceStateContainerMixin = {
  data () {
    return {
      selectedCounty: null
    }
  },
  computed: {
    ...mapState('map/serviceState', [
      'loading',
      'exception',
      'serviceState',
      'mapItems'
    ])
  },
  mounted () {
    this.fetchServiceState()
  },
  methods: {
    loadCountyInfo (selectedCountyCode) {
      this.selectedCounty = this.serviceState.find(c => {
        return COUNTY_CODE_BY_NAME[c.county] === selectedCountyCode
      })
    },
    ...mapActions('map/serviceState', ['fetchServiceState'])
  }
}

export const MapServiceStateCountiesTableMixin = {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getCountyCodeByName (name) {
      return COUNTY_CODE_BY_NAME[name]
    },
    getStatusCodeByName (name) {
      return STATUS_CODE_BY_NAME[name]
    }
  }
}

export const MapServiceStateMunicipalitiesTableMixin = {
  props: {
    county: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedMunicipality: null
    }
  },
  methods: {
    handleMunicipalityClick (municipality) {
      if (this.selectedMunicipality === municipality) {
        this.selectedMunicipality = null
        return
      }

      this.selectedMunicipality = municipality
    }
  }
}
