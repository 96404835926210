import Vue from 'vue'
import Vuex from 'vuex'

import { AddressSearchModule } from '@/apps/address-search/store/address-search-module.store'
import { PagesModule } from '@/apps/pages/store/page-module.store'
import { ContactUsModule } from '@/apps/contact-us/store/contact-us.store'
import { ContactUsNewsModule } from '@/apps/contact-us/store/news.store'
import { CustomerTicketsModule } from '@/apps/customer-tickets/store/tickets.store'
import { DynamicFormModule } from '@/apps/dynamic-form/store/dynamic-form.store'
import { AreaSearchModule } from './modules/area-search.store'
import { AssetSearchModule } from './modules/asset-search.store'
import { BasicInfoModule } from './modules/basic-info.store.js'
import { EAMCookiesModule } from './modules/eam-cookies.store.js'
import { LoginModule } from './modules/login.store.js'
import { LookupsModule } from './modules/lookups.store'
import { MyDocumentsModule } from './modules/my-documents.store.js'
import { MyReportsModule } from './modules/my-reports.store'
import { NewsModule } from './modules/news.store.js'
import { ActualOperationalNewsModule } from './modules/operational-news/actual.store'
import { ArchivedOperationalNewsModule } from './modules/operational-news/archived.store'
import { BannerOperationalNewsModule } from './modules/operational-news/banner.store'
import { FrontpageOperationalNewsModule } from './modules/operational-news/frontpage.store'
import { PlannedOperationalNewsModule } from './modules/operational-news/planned.store'
import { PhoneSearchModule } from './modules/phone-search.store'
import { PublicHeaderModule } from './modules/public-header.store.js'
import { ReportsAdministrationModule } from './modules/reports-administration.store'
import { ResetPasswordModule } from './modules/reset-password.store.js'
import { SecureHeaderModule } from './modules/secure-header.store.js'
import { UserCreationModule } from './modules/user/user-creation.store'
import UserDashboardModule from './modules/user/user-dashboard.store'
import { UserDetailsModule } from './modules/user/user-details.store'
import { InvoiceSpecificationsModule } from './modules/invoice-specifications.store'
import { InvoicesModule } from './modules/invoices.store'
import { FaultReportingModule } from '@/apps/fault-reporting/store/fault-reporting.store'
import { NewsDetailsModule } from '@/store/modules/news-details.store'
import { ProductsModule } from '@/apps/products/store'
import { UserAdministrationModule } from './modules/user-administration.store'
import { CompanyAdministrationModule } from './modules/company-administration.store'
import { CompanyDetailsModule } from './modules/company-details.store'
import { CompanyCreationModule } from './modules/company-creation.store'
import { MapModule } from '@/store/modules/map.store'
import { PublicLandingModule } from '@/apps/public-landing/store/public-landing.store'
import { PublicLandingNewsModule } from '@/apps/public-landing/store/news.store'
import { AccountHistoryModule } from './modules/user/account-history.store'
import { MassUserDeletionModule } from './modules/mass-user-deletion.store'
import { MassUserTransferModule } from './modules/mass-user-transfer.store'
import { SiteSearchModule } from './modules/site-search.store'

Vue.use(Vuex)

export const siteSearchModule = SiteSearchModule

export default new Vuex.Store({
  modules: {
    assetSearch: AssetSearchModule,
    areaSearch: AreaSearchModule,
    publicHeader: PublicHeaderModule,
    phoneSearch: PhoneSearchModule,
    basicInfo: BasicInfoModule,
    news: NewsModule,
    login: LoginModule,
    resetPassword: ResetPasswordModule,
    eamCookies: EAMCookiesModule,
    secureHeader: SecureHeaderModule,
    ContactUs: ContactUsModule,
    ContactUsNews: ContactUsNewsModule,
    NewsDetails: NewsDetailsModule,
    DynamicForm: DynamicFormModule,
    CustomerTickets: CustomerTicketsModule,
    MyDocuments: MyDocumentsModule,
    actualOperationalNews: ActualOperationalNewsModule,
    archivedOperationalNews: ArchivedOperationalNewsModule,
    plannedOperationalNews: PlannedOperationalNewsModule,
    frontpageOperationalNews: FrontpageOperationalNewsModule,
    bannerOperationalNews: BannerOperationalNewsModule,
    map: MapModule,
    userDetails: UserDetailsModule,
    userCreation: UserCreationModule,
    userDashboard: UserDashboardModule,
    lookups: LookupsModule,
    AddressSearch: AddressSearchModule,
    myReports: MyReportsModule,
    reportsAdministration: ReportsAdministrationModule,
    invoiceSpecifications: InvoiceSpecificationsModule,
    invoices: InvoicesModule,
    FaultReporting: FaultReportingModule,
    Products: ProductsModule,
    publicLanding: PublicLandingModule,
    publicLandingNews: PublicLandingNewsModule,
    userAdministration: UserAdministrationModule,
    companyDetails: CompanyDetailsModule,
    companyAdministration: CompanyAdministrationModule,
    companyCreation: CompanyCreationModule,
    accountHistory: AccountHistoryModule,
    massUserDeletion: MassUserDeletionModule,
    massUserTransfer: MassUserTransferModule,
    siteSearch: SiteSearchModule,
    Pages: PagesModule
  }
})
