<template>
  <div class="portal-column col-lg-4 col-md-6 col-sm-6">
    <div class="portal">
      <h2 class="portal__title heading-m">
        {{ title }}
      </h2>

      <a
        v-if="isExternalLink()"
        class="button button--primary portal__button"
        :href="buttonLink"
        target="_blank">
        {{ buttonText }}
      </a>
      <LocalisedLink
        v-else
        class="button button--primary portal__button"
        :to="buttonLink">
        {{ buttonText }}
      </LocalisedLink>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: true
    }
  },
  methods: {
    isExternalLink () {
      return !!this.buttonLink && this.buttonLink.startsWith('http')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.portal {
  &__title {
    margin-bottom: 25px;
  }

  &-column {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__button {
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}
</style>
