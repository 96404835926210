import { createInstance } from './axios.api'

export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE)

const UserService = {
  findUsers: data => {
    return api.post('/users/search', data)
  },
  getCurrentUserDetails: () => {
    return api.get('/public/user/me')
  },
  getUserDetailsByUsername: username => {
    return api.post('/users', username)
  },
  getUserHistory: username => {
    return api.post('/users/history', username)
  },
  deleteUserByUsername: username => {
    return api.post('/users/delete', username)
  },
  deleteUsersByUsername: usernames => {
    return api.post('/users/mass-delete', {
      usernames
    })
  },
  transferUsers: form => {
    return api.post('/users/transfer', form)
  },
  createUser: (information, systemUser) => {
    if (systemUser) {
      return api.post('/system-user/create', information)
    }
    return api.post('/users/create', information)
  },
  updatePersonalInformation: information => {
    return api.put('/users', information)
  },
  updateAccountInformation: information => {
    return api.put('/users/account', information)
  },
  unlockUser: username => {
    return api.post('/users/account-unlock', {
      username: username
    })
  },
  validateUsername: (username, companyCode, roles) => {
    return api
      .post('/users/validate-username', {
        username: username,
        companyCode: companyCode,
        roles: roles
      })
      .then(() => true)
      .catch(() => false)
  },
  getCompanyAdmins: () => {
    return api.get('/public/user/company-admins')
  }
}

export default UserService
