<template>
  <div class="municipalities-container">
    <h2 class="heading-m">
      {{ county.county }}
    </h2>

    <tws-close2-icon @click="$emit('close')" />

    <table>
      <thead>
        <tr>
          <th class="municipality">
            {{ $t('map.table-headers.municipality') }}
          </th>
          <th class="delivery-time">
            {{ $t('map.table-headers.delivery-time') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(municipality, index) in county.municipalities">
          <tr :key="index">
            <td>
              {{ municipality.municipality }}
            </td>
            <td class="delivery-time">
              <span class="delivery-time-text">{{ getMutatedDeliveryTime(municipality) }}</span>
              <tws-map-status-icon
                  class="status-icon"
                v-if="getIsDeliveryPossible(municipality)"
                color-palette="cyan"
                :status="statusCode(municipality.deliveryTime)" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import MapUtils from '@/utils/map-utils'
import MapStatusIcon from '@/components/map/map-status-icon'

export default {
  components: {
    'tws-map-status-icon': MapStatusIcon
  },
  props: {
    county: {
      type: Object,
      required: true
    }
  },
  methods: {
    statusCode (deliveryTime) {
      return MapUtils.statusCodeByDeliveryTime(deliveryTime)
    },
    getMutatedDeliveryTime (municipality) {
      return !this.getIsDeliveryPossible(municipality) ?
        this.$t('map.not-possible') : municipality.deliveryTime
    },
    getIsDeliveryPossible (municipality) {
      return municipality.municipality !== 'Solna' && municipality.municipality !== 'Ekerö'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";
@import '@/components/map/scss/map-table-municipalities';

.delivery-time {
  width: 25%;
  text-align: right;
  text-wrap: nowrap;

  &-text {
    display: inline-block;
    width: 30px;
    inline-size: fit-content;
  }
}

.status-icon {
  margin-left: 10px;
}
</style>
