<template>
  <div class="login-container">
    <div class="login-form-background">
      <div class="container login-form-container">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 px-sm-5 login-form-column">
            <h1 class="title-200">
              {{ $t('login.heading') }}
            </h1>

            <tws-radio-group
              v-show="!oneTimeCodeSent"
              id="loginMethod"
              v-model="loginMethod"
              :options="loginMethods" />

            <tws-login-password v-show="loginMethod === 'password' && !oneTimeCodeSent" />

            <tws-login-sms v-show="loginMethod === 'one-time-code' && !oneTimeCodeSent" />

            <tws-login-sms-confirm v-show="oneTimeCodeSent" />

            <InfraPageAlert
              v-if="errorMessage"
              type="danger">
              {{ errorMessage }}
            </InfraPageAlert>
          </div>

          <div class="col col-sm-6 col-md-8 d-none d-sm-block">
            <div class="welcome-text">
              <p>
                {{ $t('login.welcome1') }}<br>
                {{ $t('login.welcome2') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container access-container">
      <div
        v-if="redirectAction"
        class="row redirect-message-row">
        <div class="col-12 px-sm-0">
          <InfraPageAlert
            type="success">
            {{ redirectMessage }}
          </InfraPageAlert>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 px-sm-0">
          <h1 class="title-200">
            {{ $t('login.info.heading') }}
          </h1>

          <p>
            {{ $t('login.info.paragraph-1') }}
          </p>

          <p>
            {{ $t('login.info.paragraph-2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Password from '@/components/login/login-password.vue'
import SMS from '@/components/login/login-sms'
import SMSConfirm from '@/components/login/login-sms-confirm'

import {
  InfraPageAlert,
  TwsRadioGroup
} from 'tws-vue-components'

export default {
  components: {
    'tws-login-password': Password,
    'tws-login-sms': SMS,
    'tws-login-sms-confirm': SMSConfirm,
    InfraPageAlert,
    TwsRadioGroup
  },
  data () {
    return {
      loginMethod: 'password',
      loginMethods: [
        {
          text: this.$t('login.methods.password'),
          value: 'password'
        },
        {
          text: this.$t('login.methods.one-time-code'),
          value: 'one-time-code'
        }
      ],
      returnUri: this.$route.query.ct_orig_uri,
      returnUriSessionStorage: sessionStorage.getItem('returnUri'),
      errorCode: this.$route.query.dMessage,
      redirectAction: this.$route.query.action
    }
  },
  computed: {
    ...mapState('login', ['oneTimeCodeSent']),
    redirectMessage () {
      if (this.redirectAction === 'logout') {
        return this.$t('login.messages.logout')
      } else if (this.redirectAction === 'requestResetPassword') {
        return this.$t('login.messages.reset-password')
      }
      return null
    },
    errorMessage () {
      if (!this.errorCode || this.loginMethod === 'one-time-code') {
        return null
      }

      switch (this.errorCode) {
        case '3':
          return this.$t('login.errors.invalid-credentials')
        case '31':
          return this.$t('login.errors.account-locked')
        case '32':
          return this.$t('login.errors.password-expired')
        case '33':
          return this.$t('login.errors.unable-to-login')
        case 'GA3141':
          return this.$t('login.errors.password-changed')
        case 'sysUserLoginError':
          return this.$t('login.errors.system-account')
        default:
          return this.$t('login.errors.default')
      }
    }
  },
  created () {
    if (this.redirectAction === 'logout' || !this.returnUriSessionStorage && !this.returnUri && !this.errorCode) {
      sessionStorage.setItem('returnUri', process.env.VUE_APP_DASHBOARD_PATH)
    } else if (this.returnUri) {
      sessionStorage.setItem('returnUri', this.returnUri)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.login-form-background {
  background-color: #29003e;
  background-size: cover;
  background-position: center;
}

.login-form-container {
  .heading-l {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.login-form-column {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $white;
    border-top: 2px solid $core-purple;
  }

  .welcome-text {
    font-size: 50px;
    font-family: $teliaSans;
    font-weight: bold;
    color: $white;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 560px;
  }

.access-container {
  margin-top: 40px;
}

.redirect-message-row {
  margin-bottom: 35px;
}

.infra-page-alert--danger {
  margin-top: 25px;
}
</style>
