<template>
  <reset-password-container
    :heading="$t('reset-password.request-reset-password.header')"
    :description="$t('reset-password.request-reset-password.description')">
    <div class="col-12">
      <form
        v-if="!isLoading"
        class="form col-md-8 col-sm-12 offset-md-1">
        <tws-validated-text-input-group
          id="username"
          v-model="userInformation.username"
          :validator="$v.userInformation.username"
          :validation-messages="validationMessages.username"
          :label="$t('reset-password.username')" />
        <tws-validated-text-input-group
          id="email"
          v-model="userInformation.email"
          :validator="$v.userInformation.email"
          :validation-messages="validationMessages.email"
          :label="$t('reset-password.email')"
          @keyup.native.enter="resetPassword" />
        <div
          v-if="!isResetEmailSent"
          class="info-container">
          <InfraPageAlert
            v-for="(message, index) in messageKeys"
            :key="index"
            type="danger">
            {{ $t(message) }}
          </InfraPageAlert>
        </div>
        <div class="action-container">
          <button
            type="button"
            class="button button--primary"
            @click="resetPassword">
            {{ $t("reset-password.next") }}
          </button>
          <LocalisedLink
            v-once
            :to="loginPath"
            class="link">
            {{ $t("common.cancel") }}
          </LocalisedLink>
        </div>

        <InfraPageAlert
          v-if="isUserNotFoundErr"
          class="mt-5"
          type="danger">
          {{ $t("reset-password.not-found-error") }}
        </InfraPageAlert>
      </form>

      <div v-if="isLoading">
        {{ $t("common.loading") }}
      </div>
    </div>
  </reset-password-container>
</template>
<script>
import { email } from '@/common/validation-rules'
import ResetPasswordContainer from '@/components/common/reset-password-container.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

import {
  InfraPageAlert, TwsValidatedTextInputGroup
} from 'tws-vue-components'

export default {
  components: {
    InfraPageAlert,
    TwsValidatedTextInputGroup,
    ResetPasswordContainer
  },
  mixins: [validationMixin],
  data () {
    return {
      userInformation: {
        username: '',
        email: ''
      },
      validationMessages: {
        username: {
          required: this.$t('reset-password.validations.username.required')
        },
        email: {
          required: this.$t('reset-password.validations.email.required'),
          email: this.$t('reset-password.validations.email.email')
        }
      }
    }
  },
  validations: {
    userInformation: {
      username: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'resetPassword/isLoading',
      messageKeys: 'resetPassword/getMessageKeys',
      isResetEmailSent: 'resetPassword/isResetEmailSent',
      isUserNotFoundErr: 'resetPassword/isUserNotFoundErr'
    }),
    loginPath () {
      return process.env.VUE_APP_LOGIN_PATH
    }
  },
  watch: {
    isResetEmailSent (value) {
      if (value) {
        this.$router.replace({
          path: process.env.VUE_APP_LOGIN_PATH + '?action=requestResetPassword',
          params: {
            locale: this.$route.params.locale
          }
        })
      }
    }
  },
  mounted () {
    this.$store.dispatch('resetPassword/resetStoreDefaults')
  },
  methods: {
    resetPassword () {
      this.$v.userInformation.$touch()
      if (this.$v.userInformation.$invalid) {
        return
      }

      const params = {
        username: this.userInformation.username,
        email: this.userInformation.email
      }
      this.$store.dispatch('resetPassword/resetUserPassword', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.action-container {
  display: inline-block;
  padding-top: 20px;
  a {
    text-align: center;
    text-decoration: none;
    margin-left: 15px;
  }
}
.input-invalid-text::before {
  width: 2.6rem;
}
.info-container {
  margin-top: 2.4rem;
}
</style>
