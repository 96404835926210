<template>
  <div class="ticket-list">
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <InfraGenericBannerAlt :heading="$t('ticket-list.heading')" />

      <div class="row action-buttons">
        <div class="col-xl-3 col-sm-6 col-xs-12">
          <tws-action-button @click="goToCreateNewTicket">
            <TeliaCoreIcon
              name="add_thin"
              size="3rem" />
            <span>{{ $t('ticket-list.create-new-case') }}</span>
          </tws-action-button>
        </div>

        <div
          class="col-xl-3 col-sm-6 col-xs-12">
          <tws-action-button @click="showHelpDialog = !showHelpDialog">
            <TeliaCoreIcon
              name="guiding_light_thin"
              size="3rem" />
            <span>{{ $t('ticket-list.help') }}</span>
          </tws-action-button>

          <InfraModalAssembly
            :display="showHelpDialog"
            class="ticket-list-modal"
            :title="$t('ticket-list.help')"
            @close="showHelpDialog = false">
            <InfraContentfulRichTextContainer
              v-if="content"
              :content="content.fields.content" />
          </InfraModalAssembly>
        </div>
      </div>
    </InfraBreadcrumbContainer>

    <div class="container mt-5">
      <div
        v-if="exception"
        class="row justify-content-center">
        <div class="col-9">
          <infra-page-alert
            type="danger">
            {{ $t('info-alert.error') }}
          </infra-page-alert>
        </div>
      </div>

      <div v-else>
        <template>
          <div class="row d-flex align-items-center">
            <div class="col-lg-12 col-md-12">
              <InfraTileButtons
                v-model="userFilterValue"
                :options="userFilters" />
            </div>
          </div>

          <div class="row d-flex align-items-center">
            <div class="col-lg-12 col-md-12">
              <InfraTileButtons
                v-model="statusFilterValue"
                :options="statusFilters" />
            </div>
          </div>
        </template>

        <div class="row d-flex align-items-center">
          <div class="col-lg-4 col-md-12">
            <div class="search-field">
              <TeliaCoreIcon
                class="search-field__icon"
                name="search_thin"
                size="2.7rem" />
              <input
                v-model="searchTermValue"
                type="text"
                class="input-text"
                :placeholder="$t('ticket-list.search')">
            </div>
          </div>

          <div class="col-lg-8 col-md-12">
            <div class="result-actions">
              <tws-borderless-dropdown
                :items="sortingItems"
                :default-key="sortBy"
                :displayed-value-wrapper="$t('ticket-list.sort') + ' ({value})'"
                class="result-actions__dropdown"
                @onChange="onSortChange" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div
              v-if="loading"
              class="loading-column">
              <infra-page-alert
                type="info">
                {{ $t('info-alert.loading') }}
              </infra-page-alert>
            </div>

            <tws-paginated-table
              v-else-if="!loading && filteredTickets.length > 0"
              :items="filteredTickets"
              :current-page="currentPage"
              :extra-page-count="3"
              :item-count-on-page="10"
              @onPageChange="changeCurrentPage">
              <template #thead>
                <tr>
                  <th scope="col">
                    {{ $t('ticket-list.table.case-number') }}
                  </th>
                  <th
                    scope="col"
                    colspan="3">
                    {{ $t('ticket-list.table.heading') }}
                  </th>
                  <th scope="col">
                    {{ $t('ticket-list.table.customer-reference') }}
                  </th>
                  <th scope="col">
                    {{ $t('ticket-list.table.user') }}
                  </th>
                  <th
                    v-if="false"
                    scope="col">
                    {{ $t('ticket-list.table.created') }}
                  </th>
                  <th scope="col">
                    {{ $t('ticket-list.table.updated') }}
                  </th>
                  <th scope="col">
                    {{ $t('ticket-list.table.status') }}
                  </th>
                </tr>
              </template>

              <template #tr="{ item }">
                <td class="fit-width">
                  <span
                    @click="viewTicket(item.supportId)">
                    {{ item.supportId }}
                  </span>
                </td>
                <td
                  colspan="3"
                  @click="viewTicket(item.supportId)">
                  {{ item.subject }}
                </td>
                <td>
                  {{ item.customerReference }}
                </td>
                <td>
                  {{ getFullName(item.createdByUserInfo) }}
                </td>
                <td
                  v-if="false"
                  class="fit-width">
                  {{ item.created | date }}
                </td>
                <td>
                  {{ item.lastUpdated | date }}
                </td>
                <td class="fit-width">
                  {{ statusLabels[item.status] }}
                </td>
              </template>
            </tws-paginated-table>

            <div
              v-else
              class="no-results-column">
              <p class="no-results-column__message preamble">
                {{ $t('ticket-list.no-cases') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import { SORTING_KEY } from '@/apps/customer-tickets/store/ticket-list.store'

import {
  InfraBreadcrumbContainer,
  InfraContentfulRichTextContainer,
  InfraGenericBannerAlt,
  InfraModalAssembly,
  InfraPageAlert,
  InfraTileButtons,
  TwsActionButton,
  TwsBorderlessDropdown,
  TwsPaginatedTable
} from 'tws-vue-components'

import UserInfoMixin from '@/apps/customer-tickets/mixins/user-info.mixin'
import constants, {
  STATUS_FILTERS,
  USER_FILTERS
} from '@/apps/customer-tickets/utils/constants'
import TeliaCoreIcon from '@telia/core-icons'
import debounce from 'lodash.debounce'

import { parseTranslationsFromEnum } from '@/utils/translation.utils'
import { BREADCRUMBS_CUSTOMER_TICKETS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    TeliaCoreIcon,
    TwsActionButton,
    InfraBreadcrumbContainer,
    TwsBorderlessDropdown,
    InfraGenericBannerAlt,
    InfraPageAlert,
    TwsPaginatedTable,
    InfraTileButtons,
    InfraModalAssembly,
    InfraContentfulRichTextContainer
  },
  filters: {
    date: value => value.replace(/:[^:]*$/, '')
  },
  mixins: [UserInfoMixin],
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_CUSTOMER_TICKETS.LIST,
      sortingItems: [
        {
          key: SORTING_KEY.LAST_UPDATED_DESC,
          value: this.$t('ticket-list.table-sorting.updated-descending')
        },
        {
          key: SORTING_KEY.LAST_UPDATED_ASC,
          value: this.$t('ticket-list.table-sorting.updated-ascending')
        }
      ],
      showHelpDialog: false
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketList', [
      'loading',
      'exception',
      'currentPage',
      'sortBy',
      'userFilter',
      'statusFilter',
      'searchTerm'
    ]),
    ...mapState('CustomerTickets', ['content']),
    ...mapGetters('CustomerTickets/TicketList', ['filteredTickets']),
    statusLabels () {
      return parseTranslationsFromEnum(constants.STATUS_TO_LABEL)
    },
    userFilters () {
      // eslint-disable-next-line
      return Object.entries(USER_FILTERS).map(([value, label]) => ({ value, label: this.$t(label) }))
    },
    statusFilters () {
      // eslint-disable-next-line
      return Object.entries(STATUS_FILTERS).map(([value, label]) => ({ value, label: this.$t(label) }))
    },
    userFilterValue: {
      get: function () {
        return this.userFilter
      },
      set: function (value) {
        this.changeUserFilter(value)
      }
    },
    statusFilterValue: {
      get: function () {
        return this.statusFilter
      },
      set: function (value) {
        this.changeStatusFilter(value)
      }
    },
    searchTermValue: {
      get: function () {
        return this.searchTerm
      },
      set: function (value) {
        this.changeSearchTerm(value)
        this.findTicketsDebounced(this.searchTerm)
      }
    },
    findTicketsDebounced () {
      return debounce(function (value) {
        this.findTickets(value)
      }, 1000)
    }
  },
  mounted () {
    this.findTickets(this.searchTerm)
  },
  methods: {
    ...mapActions('CustomerTickets/TicketList', [
      'changeCurrentPage',
      'getTicketList',
      'changeSort',
      'findTickets',
      'changeUserFilter',
      'changeStatusFilter',
      'changeSearchTerm'
    ]),
    goToCreateNewTicket () {
      this.$router.push({
        name: 'tickets-create',
        params: this.$route.params
      })
    },
    onSortChange (value) {
      if (this.sortBy !== value) {
        this.changeSort(value)
      }
    },
    viewTicket (supportId) {
      this.$router.push({
        name: 'tickets-details',
        params: {
          supportId: supportId,
          locale: this.$route.params.locale
        }
      })
    }
  }
}
</script>

<style lang="scss">
table {
  width: auto;
}
</style>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.ticket-list {
  &-modal::v-deep .modal-container {
    min-height: 0;
    padding: 2rem;
  }

  ::v-deep table {
    width: 100% !important;
  }

  th, td {
  overflow: hidden;

    @media(max-width: $breakpoint-md) {
      white-space: nowrap;
    }
  }

  .fit-width {
    width: 1%;
    white-space: nowrap;
  }

  .bold {
    font-weight: bold;
  }

  .action-buttons {
    margin-top: 1.2rem;
    margin-bottom: 2.8rem;

    [class*="col-"] {
      padding-left: 0.5px;
      padding-right: 0.5px;
    }
  }

  .search-field {
    input {
      background-color: $light-grey;
      border-radius: 50px;
      border-color: $light-grey;
      padding-left: 46px;
    }

    &__icon {
      position: absolute;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }

  .loading-column {
    margin-top: 30px;
  }

  .no-results-column {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    &__message {
      border: 1px solid $medium-grey;
      padding: 20px 30px;
    }
  }

  .result-actions {
    display: flex;
    justify-content: flex-end;

    &__dropdown {
      color: $core-purple;
      margin: 10px;
      padding: 0;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
